import React, { useState } from 'react';
import {
  Form,
  FormGroup,
} from 'reactstrap';

import { Link, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  PasswordIcon,
} from 'assets/images/svg-icons';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import FormGeneralAlert from 'components/shared/form-fields/form-general-alert';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import { resetPassword } from 'services/auth';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  password: Yup.string().trim()
    .required('Password is required')
    .min(8, 'Must contain at least 8 letters'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors, setError } = methods;
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');
  const onSubmit = (formData) => {
    if (token && email) {
      const payload = {
        token,
        email,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      };
      setIsLoading(true);
      resetPassword(payload)
        .then((res) => {
          setSuccessMessage(res.data.message);
          setIsLoading(false);
        })
        .catch((error) => {
          setSuccessMessage('');
          setIsLoading(false);
          catchHandler(error, setError);
        });
    }
  };

  return (
    <div className="auth-card--content">
      <h5>Reset Password</h5>
      <h4>
        <strong>Lula Customer</strong>
        {' '}
        Account
      </h4>
      {
       (errors || successMessage)
       && <FormGeneralAlert errors={errors} successMessage={successMessage} />
      }
      <FormProvider {...methods}>
        <Form className="auth-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <FormGroup>
            <i>
              <PasswordIcon />
            </i>
            <CustomInputField className="form-control" name="password" type="password" placeholder="Password" clearError />
          </FormGroup>
          <FormGroup>
            <i>
              <PasswordIcon />
            </i>
            <CustomInputField className="form-control" name="password_confirmation" type="password" placeholder="Confirm Password" clearError />
          </FormGroup>
          <div className="auth-btn-outer auth-btn-outer--forgot-password d-flex justify-content-center">
            <ButtonWithLoader
              isLoading={isLoading}
              color="primary"
              type="submit"
            >
              Reset Password
            </ButtonWithLoader>
            <Link className="auth-wrap__text-link" to="/auth/login">Back to Login Page</Link>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ResetPassword;
