import { request } from 'helpers/api';

export const updateProfile = (params) => (
  request({
    method: 'PUT',
    url: '/api/profile',
    params,
  })
);

export const getCompanyProfile = () => (
  request({
    method: 'GET',
    url: '/api/company/profile',
  })
);
export const updateCompanyProfile = (params) => (
  request({
    method: 'PUT',
    url: '/api/company/profile',
    params,
  })
);
