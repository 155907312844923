import {
  Star1Icon, StarHalfIcon, Star1HalfIcon, Star2Icon, Star2HalfIcon, Star3Icon, Star3HalfIcon,
  Star4Icon, Star4HalfIcon, Star5Icon, StarDisabledIcon,
} from 'assets/images/svg-icons';

import React from 'react';

const renderRatings = (rating, width, height) => {
  const formattedRating = Math.round(rating * 2) / 2;
  switch (formattedRating) {
    case 0.5: return (
      <React.Fragment>
        <StarHalfIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 1: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 1.5: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star1HalfIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 2: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 2.5: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <Star2HalfIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 3: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <Star3Icon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 3.5: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <Star3Icon width={width} height={height} />
        <Star3HalfIcon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 4: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <Star3Icon width={width} height={height} />
        <Star4Icon width={width} height={height} />
        <StarDisabledIcon width={width} height={height} />
      </React.Fragment>
    );
    case 4.5: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <Star3Icon width={width} height={height} />
        <Star4Icon width={width} height={height} />
        <Star4HalfIcon width={width} height={height} />
      </React.Fragment>
    );
    case 5: return (
      <React.Fragment>
        <Star1Icon width={width} height={height} />
        <Star2Icon width={width} height={height} />
        <Star3Icon width={width} height={height} />
        <Star4Icon width={width} height={height} />
        <Star5Icon width={width} height={height} />
      </React.Fragment>
    );
    default: return '';
  }
};

const JobRating = ({ rating, width, height }) => (
  <React.Fragment>
    {renderRatings(rating, width, height)}
  </React.Fragment>
);

export default JobRating;
