import React, { useState, useEffect } from 'react';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { generateRandomString } from 'helpers/utils';

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
];

const Placeholder = (props) => <components.Placeholder {...props} />;

const PaginationComponent = (props) => {
  const {
    meta,
    onPageChange,
  } = props;

  const [perPage, setPerPage] = useState({});
  const delta = 1;

  useEffect(() => {
    setPerPage({ value: meta.per_page, label: meta.per_page });
  }, [meta?.per_page]);

  const handlePerPageChange = (option) => {
    // setPerPage(option);
    onPageChange(1, (option?.value || 10));
  };

  const pagination = (currentPage, lastPage) => {
    const range = [];
    for (let i = Math.max(2, (currentPage - delta)); i <= Math.min((lastPage - 1),
      (currentPage + delta)); i += 1) {
      range.push({ value: i, type: 'page' });
    }
    if ((currentPage - delta) > 2) {
      range.unshift({ value: '...', type: 'separator' });
    }
    if ((currentPage + delta) < (lastPage - 1)) {
      range.push({ value: '...', type: 'separator' });
    }
    range.unshift({ value: 1, type: 'page' });
    if (lastPage !== 1) range.push({ value: lastPage, type: 'page' });
    return range;
  };

  return (
    <React.Fragment>
      { meta && meta.total > 0 ? (
        <div className="global-pagination">
          <div className="page-count">
            Showing
            {' '}
            {(meta.current_page - 1) * meta.per_page + 1}
            -
            {meta.current_page * meta.per_page > meta.total
              ? meta.total
              : meta.current_page * meta.per_page}
            {' '}
            of
            {' '}
            {meta.total}
          </div>
          <div className="pagination-outer">
            <Pagination aria-label="Page navigation example">
              {(pagination(meta?.current_page, meta?.last_page) || []).map((page) => (
                <PaginationItem
                  key={generateRandomString()}
                  active={page.value === meta.current_page}
                >
                  <PaginationLink
                    onClick={() => {
                      if (page.type === 'page') {
                        onPageChange(page.value, (perPage?.value || 10));
                      }
                    }}
                    disabled={page.type === 'separator'}
                  >
                    {page.value}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
          </div>
          <div className="per-page">
            <span>Rows Per Page:</span>
            <Select
              value={perPage}
              options={perPageOptions}
              className="global-select"
              components={{ Placeholder }}
              onChange={handlePerPageChange}
              placeholder=""
              styles={{
                placeholder: (base) => ({
                  ...base,
                  color: '#A6A6A6',
                }),
              }}
            />
          </div>
        </div>
      ) : '' }
    </React.Fragment>
  );
};

PaginationComponent.defaultProps = {
  meta: null,
  onPageChange: PropTypes.func,
};

PaginationComponent.propTypes = {
  meta: PropTypes.shape({
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
};

export default PaginationComponent;
