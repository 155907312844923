import React from 'react';

const DashboardIcon = (props) => (
  <svg {...props} width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.3259 0.074297C12.1789 0.0818737 12.0319 0.0894502 11.8865 0.114269C11.1476 0.193292 10.4172 0.350859 9.70598 0.565419C7.1102 1.34272 4.79746 3.00555 3.20838 5.19712C1.88127 7.01561 1.05799 9.20048 0.874084 11.4447C0.868606 11.5738 0.858901 11.7025 0.849198 11.8312C0.830058 12.0851 0.810928 12.3388 0.824276 12.5946C0.812852 12.7913 0.830767 12.9861 0.848694 13.181C0.861581 13.3211 0.874476 13.4613 0.876479 13.6023C1.03405 15.3719 1.57811 17.1061 2.46221 18.6478C3.3827 20.2599 4.66527 21.6626 6.18826 22.7239C7.63318 23.7369 9.29698 24.4375 11.0326 24.757C11.5793 24.8731 12.1366 24.9122 12.6923 24.9512C12.7808 24.9574 12.8691 24.9636 12.9574 24.9701C13.0504 24.9691 13.1435 24.9702 13.2367 24.9713C13.4805 24.9743 13.7245 24.9773 13.966 24.9409C16.8808 24.8145 19.7165 23.5664 21.831 21.5635C24.1351 19.4079 25.5872 16.3499 25.739 13.1928C25.7845 12.7575 25.7802 12.313 25.7433 11.8772C25.6466 9.7 24.9421 7.55536 23.75 5.73209C22.4249 3.68755 20.4914 2.04339 18.2582 1.06877C16.9186 0.484001 15.4747 0.129116 14.0139 0.0587137C13.7075 0.0177407 13.3971 0.0236818 13.0875 0.0296085C12.9809 0.0316485 12.8744 0.0336868 12.7682 0.0338095C12.6219 0.0590391 12.4739 0.0666682 12.3259 0.074297ZM12.4756 1.14703C12.5473 1.13757 12.6191 1.1281 12.6916 1.12672C13.5355 1.09368 14.3817 1.13151 15.2136 1.28094C17.2515 1.6205 19.1825 2.55009 20.7405 3.9045C22.2769 5.234 23.4531 6.98065 24.0939 8.90929C25.0149 11.6219 24.8406 14.6799 23.6231 17.2733C22.8679 18.8944 21.7294 20.3379 20.3204 21.4409C18.0666 23.2359 15.1078 24.1171 12.2409 23.8322C10.9239 23.7115 9.62648 23.3542 8.43203 22.7843C6.52542 21.8767 4.87744 20.4332 3.73472 18.6569C2.26633 16.4098 1.6418 13.6258 2.01776 10.9696C2.30799 8.76801 3.27878 6.66647 4.74717 5.00268C6.63606 2.83362 9.37552 1.41887 12.2429 1.16743C12.3209 1.16743 12.3982 1.15724 12.4756 1.14703Z" fill="#949DAE" />
    <path d="M13.1658 2.14648C13.4752 2.05836 13.8181 2.31411 13.8367 2.63355C13.843 3.01765 13.8372 3.40223 13.8396 3.78633C13.8521 4.07704 13.592 4.36296 13.2956 4.3438C12.9991 4.36296 12.739 4.07656 12.7524 3.78633C12.7534 3.41708 12.752 3.0483 12.7529 2.67953C12.7501 2.4324 12.9297 2.21162 13.1658 2.14648Z" fill="#949DAE" />
    <path d="M8.28251 3.45584C8.47983 3.40555 8.70061 3.50086 8.81795 3.66465C8.96067 3.86197 9.06891 4.08132 9.20013 4.2863C9.29592 4.46206 9.42427 4.62155 9.49372 4.81072C9.59669 5.08323 9.4099 5.42279 9.1283 5.48649C8.98126 5.49511 8.8146 5.51953 8.68672 5.42758C8.55167 5.35143 8.48941 5.20201 8.40895 5.07748C8.23941 4.78151 8.05023 4.49655 7.88931 4.19578C7.74707 3.89358 7.95876 3.51379 8.28251 3.45584Z" fill="#949DAE" />
    <path d="M18.0155 3.4788C18.2593 3.39643 18.5461 3.48934 18.675 3.71683C18.7732 3.88398 18.7674 4.10668 18.664 4.27047C18.47 4.60045 18.2727 4.92804 18.0739 5.25514C17.981 5.41846 17.7933 5.51712 17.6055 5.49509C17.2257 5.53915 16.9427 5.06549 17.1252 4.7403C17.2808 4.45438 17.4618 4.18283 17.6223 3.8993C17.7209 3.73551 17.823 3.5449 18.0155 3.4788Z" fill="#949DAE" />
    <path d="M4.63959 7.03336C4.80817 6.97589 5.00309 6.99169 5.15443 7.08843C5.43413 7.25845 5.71382 7.42799 5.99639 7.59322C6.13336 7.67464 6.27608 7.77234 6.33882 7.9256C6.43173 8.13729 6.36037 8.40022 6.18365 8.54629C6.03853 8.6823 5.81631 8.68326 5.63527 8.63537C5.28422 8.44093 4.94562 8.22589 4.60031 8.02138C4.43748 7.93039 4.29955 7.76229 4.30482 7.56784C4.278 7.34083 4.43077 7.11478 4.63959 7.03336Z" fill="#949DAE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7583 7.19299C21.6112 7.05842 21.389 7.08907 21.2099 7.12211C20.5836 7.36382 19.9573 7.60559 19.331 7.84736C17.0819 8.71557 14.8329 9.58377 12.583 10.4487C12.488 10.4883 12.4106 10.5541 12.3331 10.6202C12.2707 10.6733 12.2081 10.7265 12.1361 10.7663C11.5269 11.1374 11.0969 11.8237 11.1275 12.5488C11.1404 13.3199 11.5988 14.0661 12.287 14.4171C12.695 14.6279 13.1812 14.7351 13.6337 14.6221C13.7624 14.5925 13.8796 14.5339 13.9968 14.4754C14.1205 14.4135 14.2441 14.3518 14.3809 14.3242C14.5054 14.3012 14.6189 14.2409 14.7118 14.1556C15.9755 13.0597 17.2401 11.9649 18.5048 10.87C19.6016 9.92044 20.6984 8.97087 21.7947 8.02058C22.0245 7.80985 22.0159 7.37882 21.7583 7.19299ZM13.0641 11.4265C13.1291 11.4019 13.1938 11.3774 13.2563 11.3501C14.1351 11.0144 15.0126 10.6759 15.8901 10.3373C16.8256 9.97639 17.7611 9.61547 18.6979 9.25813C17.8622 9.99727 17.0177 10.7264 16.1734 11.4554C15.5318 12.0094 14.8902 12.5633 14.2525 13.1216C14.2458 13.1276 14.239 13.1336 14.2322 13.1395C14.0845 13.27 13.9338 13.4031 13.752 13.4827C13.3603 13.6681 12.8612 13.5967 12.5365 13.3094C12.104 12.8951 12.0935 12.1049 12.5504 11.7045C12.6938 11.5667 12.8799 11.4963 13.0641 11.4265Z" fill="#949DAE" />
    <path d="M3.39285 11.9136C3.78701 11.8811 4.18596 11.9098 4.58203 11.8988C4.82676 11.8715 5.08586 12.0166 5.17255 12.2513C5.22427 12.4467 5.20655 12.6818 5.04324 12.8207C4.88136 13.0104 4.61268 12.9888 4.38759 12.9869C4.08443 12.9807 3.77983 12.9975 3.47667 12.9793C3.1917 12.9663 2.97858 12.6924 2.98098 12.4184C3.00253 12.1861 3.16632 11.9788 3.39285 11.9136Z" fill="#949DAE" />
    <path d="M21.7027 11.9534C21.7679 11.9203 21.8373 11.8954 21.912 11.9007C22.2818 11.8988 22.6515 11.9016 23.0217 11.8997C23.2473 11.8834 23.482 12.0228 23.561 12.2383C23.6783 12.4625 23.5662 12.7527 23.368 12.8916C23.0907 13.0573 22.7492 12.962 22.4436 12.9869C22.1462 12.9649 21.7775 13.0697 21.549 12.8217C21.2827 12.5841 21.3661 12.0832 21.7027 11.9534Z" fill="#949DAE" />
    <path d="M5.69519 16.2321C5.97968 16.1493 6.29864 16.359 6.35707 16.6464C6.41167 16.8681 6.29385 17.1153 6.09749 17.2278C5.8087 17.4045 5.51655 17.5765 5.2268 17.7518C5.1133 17.8107 5.00123 17.8969 4.86665 17.8921C4.57067 17.9122 4.27805 17.6392 4.30439 17.3375C4.28667 17.0822 4.50602 16.9036 4.70956 16.7963C5.04194 16.6153 5.34606 16.3806 5.69519 16.2321Z" fill="#949DAE" />
    <path d="M20.6377 16.2314C20.8882 16.1572 21.1094 16.3353 21.3125 16.4551C21.5668 16.616 21.8374 16.7534 22.0831 16.9278C22.3082 17.0896 22.3599 17.4359 22.1942 17.6567C22.0529 17.8655 21.7493 17.967 21.5228 17.8382C21.222 17.6773 20.9366 17.4891 20.642 17.3176C20.4854 17.2304 20.3163 17.1289 20.2584 16.9479C20.1305 16.6572 20.336 16.3018 20.6377 16.2314Z" fill="#949DAE" />
  </svg>
);
export default DashboardIcon;
