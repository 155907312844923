import React from 'react';

const StatusUnpaidIcon = (props) => (
  <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.606445" y="0.314453" width="18" height="18" rx="6" fill="#283A5D" />
    <circle cx="5.73186" cy="9.31438" r="1.22454" fill="white" />
    <circle cx="9.60637" cy="9.31438" r="1.22454" fill="white" />
    <circle cx="13.4811" cy="9.31438" r="1.22454" fill="white" />
  </svg>

);
export default StatusUnpaidIcon;
