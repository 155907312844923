import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import OpenMakeReadiesFilter from './components/open-make-readies-filter';
import OpenMakeReadiesContainer from './components/open-make-readies-container';

const OpenMakeReadies = () => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const startDate = moment().subtract(119, 'days').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.job_no) {
      setFilters({
        search_keyword: location?.state?.job_no,
      });
      window.history.replaceState({}, document.title);
    }
  }, [location?.state]);

  return (
    <React.Fragment>
      <OpenMakeReadiesFilter
        startDate={startDate}
        endDate={endDate}
        setLoading={setLoading}
        setFilters={setFilters}
      />
      <OpenMakeReadiesContainer
        loading={loading}
        setLoading={setLoading}
        startDate={startDate}
        endDate={endDate}
        filters={filters}
      />
    </React.Fragment>
  );
};
export default OpenMakeReadies;
