import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { isEmpty, size } from 'lodash';
import { Link } from 'react-router-dom';
import Pusher from 'pusher-js';

import Loader from 'components/shared/loader';
import MakeReadiesCard from 'components/open-make-readies/components/make-readies-card';

import { AccordionIcon } from 'assets/images/svg-icons';

import { getJobs } from 'services/jobs';
import { AuthContext } from 'context/auth-context/auth-context';

const OpenMakeReadiesContainer = (props) => {
  const {
    loading,
    setLoading,
    startDate,
    endDate,
    filters,
  } = props;

  const [userDetails] = useContext(AuthContext);

  const [totalJobs, setTotalJobs] = useState(0);
  const [openJobs, setOpenJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});

  const getClassName = (job) => {
    switch (job) {
      case 'in_progress':
        return 'work-in-progress';
      case 'scheduled':
        return 'scheduled';
      case 'walk-thru':
        return 'paused';
      case 'quote_in_progress': return 'qip';

      default:
        return '';
    }
  };

  const accordionButtonHandler = (type) => {
    setSelectedJob((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const fetchingOpenJobs = useCallback(
    (loadingState) => {
      setLoading(loadingState);
    },
    [setLoading],
  );

  const fetchOpenMakeReadyJobs = useCallback(
    (params) => {
      setOpenJobs([]);
      fetchingOpenJobs(true);
      getJobs('make-readies/open', params)
        .then((res) => {
          fetchingOpenJobs(false);
          setOpenJobs(res?.data);
        })
        .catch(() => {});
    },
    [setOpenJobs, fetchingOpenJobs],
  );

  const onJobNotesModalCloseHandler = useCallback((type, jobId) => {
    setOpenJobs((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        data: prev[type]?.data?.map((item) => {
          if (item?.job_id === jobId) {
            return {
              ...item,
              has_unread_customer_notes: false,
            };
          }
          return item;
        }),
      },
    }));
  }, []);

  useEffect(() => {
    let pusher = null;
    if (userDetails?.partner?.partner_key
      && process.env.REACT_APP_PUSHER_CLUSTER
      && process.env.REACT_APP_PUSHER_KEY) {
      pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
      });
      const jobChannel = pusher.subscribe(`open-make-ready-jobs-${userDetails?.partner?.partner_key}`);
      jobChannel.bind('open_make_ready_job.updated', (response) => {
        setOpenJobs((prevJobs) => {
          let matched = false;
          let filteredJobs = {};
          if (prevJobs) {
            ['quote_in_progress', 'scheduled', 'in_progress', 'walk-thru']?.forEach((job) => {
              filteredJobs = {
                ...filteredJobs,
                [job]: {
                  ...prevJobs[job], data: [],
                },
              };
              prevJobs[job]?.data?.forEach((item) => {
                if (item?.job_id === response?.job_id) {
                  if (response?.status === 'closed') {
                    matched = true;
                    filteredJobs[job].data = [...filteredJobs[job]?.data];
                  } else if (job === response?.status) {
                    matched = true;
                    filteredJobs[job].data = [...filteredJobs[job]?.data, response];
                  }
                } else {
                  filteredJobs[job].data = [...filteredJobs[job]?.data, item];
                }
              });
            });
          }
          if (!matched && ['quote_in_progress', 'scheduled', 'in_progress', 'walk-thru']?.includes(response?.status)) {
            filteredJobs = {
              ...filteredJobs,
              [response?.status]: {
                ...filteredJobs[response?.status],
                data: [
                  ...filteredJobs[response?.status]?.data,
                  response,
                ],
              },
            };
          }
          return { ...prevJobs, ...filteredJobs };
        });
      });
    }
    return () => {
      if (pusher) {
        pusher.disconnect();
      }
    };
  }, [userDetails?.partner?.partner_key, setOpenJobs]);

  useEffect(() => {
    fetchOpenMakeReadyJobs({
      from_date: startDate,
      to_date: endDate,
      ...filters,
    });
  }, [endDate, fetchOpenMakeReadyJobs, filters, startDate]);

  useEffect(() => {
    let total = 0;
    if (size(openJobs) > 0) {
      ['quote_in_progress', 'scheduled', 'in_progress', 'walk-thru']?.forEach((type) => { total += size(openJobs[type]?.data); });
      setTotalJobs(total);
    }
  }, [openJobs, setTotalJobs]);

  return (
    <div className="data-card p-0 data-card-open-jobs">
      <ul className="open-jobs open-make-ready-jobs">
        {loading ? <Loader />
          : (
            <React.Fragment>
              {openJobs ? ['quote_in_progress', 'scheduled', 'in_progress', 'walk-thru'].map((type) => (
                <li key={type} className={getClassName(type)}>
                  <div
                    className="jobs-heading"
                    onClick={() => accordionButtonHandler(type)}
                  >
                    <div className="jobs-heading-icon" />
                    <div className="jobs-heading-data">
                      <div className="jobs-heading-data--inner">
                        <h4>
                          {(Math.floor((size(openJobs[type]?.data) / totalJobs) * 100)) || 0}
                          % (
                          {size(openJobs[type]?.data) || 0}
                          )
                        </h4>
                        <p>
                          {openJobs?.[type]?.label}
                        </p>
                      </div>
                      <Link
                        to="#"
                        className={`accordion-btn ${selectedJob?.[type] ? 'accordion-arrow' : ''}`}
                      >
                        <AccordionIcon />
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`jobs-data-wrap d-lg-block ${!selectedJob?.[type] ? 'd-none' : ''}`}
                  >
                    { !isEmpty(openJobs?.[type]?.data) ? openJobs?.[type]?.data?.map((job) => (
                      <React.Fragment key={job?.job_no}>
                        <MakeReadiesCard
                          job={job}
                          type={type}
                          onJobNotesModalCloseHandler={onJobNotesModalCloseHandler}
                        />
                      </React.Fragment>
                    )) : (
                      <div className="no-data-found">
                        No Data Found
                      </div>
                    )}
                  </div>
                </li>
              )) : ''}
            </React.Fragment>
          )}
      </ul>
    </div>
  );
};

export default OpenMakeReadiesContainer;
