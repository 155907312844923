import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isArray, isEmpty } from 'lodash';

import {
  MyProfileHeaderIcon,
  UploadIcon,
} from 'assets/images/svg-icons';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import { AuthContext } from 'context/auth-context/auth-context';
import FileUploader from 'components/shared/form-fields/file-uploader';
import ImageCropModal from 'components/my-profile/image-crop-modal';
import { uploadAttachment } from 'services/attachment';
import { updateProfile } from 'services/profile';
import Loader from 'components/shared/loader';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import catchHandler from 'helpers/catch-handler';
import PhoneNumber from 'components/shared/form-fields/phone-number';
import { convertArrayToObject } from 'helpers/utils';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Not a valid email')
    .required('Email is required'),
  first_name: Yup.string()
    .required('First Name is required'),
  last_name: Yup.string()
    .required('Last Name is required'),
  phone_no: Yup.string()
    .required('Phone Number is required'),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be atleast 8 characters'),

  password_confirmation: Yup.string()
    .required('Please re-enter the password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const buildFormData = (userDetails) => {
  const formData = {
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
  };
  if (!isEmpty(userDetails)) {
    formData.first_name = userDetails.first_name;
    formData.last_name = userDetails.last_name;
    formData.email = userDetails.email;
    formData.phone_no = userDetails.phone_no;
  }
  return formData;
};

const MyProfile = () => {
  const [userDetails, setUserDetails] = useContext(AuthContext);
  const [showImageCropModal, setImageCropModal] = useState(false);
  const [attachmentData, setAttachmentData] = useState();
  const [profilePicUrl, setProfilepicUrl] = useState();
  const [oldProfilePicUrl, setOldProfilePicUrl] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isGeneralInfoButtonLoading, setGeneralInfoButtonLoading] = useState(false);
  const [isPasswordSubmitButtonLoading, setPasswordSubmitButtonLoading] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(userDetails),
    resolver: yupResolver(schema),
  });
  const rhfMethod = useForm({
    mode: 'all',
    resolver: yupResolver(passwordSchema),
  });
  const {
    reset, watch, setValue, setError,
  } = methods;
  const profilePicture = watch('profilePicture');
  useEffect(() => {
    reset(buildFormData(userDetails));
    if (userDetails?.profile_pic_url) {
      setProfilepicUrl(userDetails?.profile_pic_url);
    }
  }, [userDetails, reset]);

  useEffect(() => {
    if (isArray(profilePicture)) {
      const formData = new FormData();
      formData.append('file', profilePicture[0]);
      formData.append('entity_type', 'property_manager');
      setLoading(true);
      uploadAttachment(formData).then((res) => {
        setAttachmentData(res?.data);
        setOldProfilePicUrl(profilePicture);
        setProfilepicUrl(res?.data?.attachment_url);
        setImageCropModal(true);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [profilePicture]);

  const profileUpdate = (params) => {
    if (!isEmpty(params.attachment)) {
      setLoading(true);
    }
    setImageCropModal(false);
    updateProfile(params).then((res) => {
      setProfilepicUrl(res?.data?.profile_pic_url);
      if (profilePicture) {
        setValue('profilePicture', '');
      }
      setUserDetails({
        ...res?.data,
        propertyManagerSettingsObj:
          convertArrayToObject(res?.data?.settings, 'slug'),
      });
      setLoading(false);
      setGeneralInfoButtonLoading(false);
      setPasswordSubmitButtonLoading(false);
    }).catch((error) => {
      setLoading(false);
      setGeneralInfoButtonLoading(false);
      setPasswordSubmitButtonLoading(false);
      catchHandler(error, setError, true);
    });
  };
  const onSubmit = (formData) => {
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone_no: formData.phone_no,
    };
    setGeneralInfoButtonLoading(true);
    profileUpdate(payload);
  };

  const onSubmitPassword = (formData) => {
    const payload = {
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    };
    setPasswordSubmitButtonLoading(true);
    profileUpdate(payload);
  };

  return (
    <React.Fragment>
      <div className="page-header d-xs-flex d-sm-flex d-md-flex">
        <span>
          <MyProfileHeaderIcon />
        </span>
        <h3>My Profile</h3>
      </div>
      <div className="data-card my-profile-card">
        <Row>
          <Col>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Row>
                  <Col xs="12">
                    <div className="info-wrapper--header sub-account">
                      <h3 className="header3">General Info</h3>
                      <div className="asset-management">
                        {
                          userDetails?.partner?.partner_logo_url ? (
                            <img src={userDetails.partner.partner_logo_url} alt="" width="27px" />
                          )
                            : ''
                        }
                        <div>
                          <Link to={userDetails?.property_manager_type !== 'employee' ? '/company-profile' : '#'}>
                            <h6>Company</h6>
                            <h5>{userDetails?.partner?.label}</h5>
                          </Link>
                        </div>

                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="8" xl="7">
                    <div className="info-wrapper">
                      <FormGroup row>
                        <Col lg="6">
                          <Label for="">First Name</Label>
                          <CustomInputField
                            className="form-control"
                            name="first_name"
                          />
                        </Col>
                        <Col lg="6">
                          <Label for="">Last Name</Label>
                          <CustomInputField
                            className="form-control"
                            name="last_name"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col lg="6">
                          <Label for="">Email Address</Label>
                          <CustomInputField
                            className="form-control"
                            name="email"
                          />
                        </Col>
                        <Col lg="6">
                          <Label for="">Phone Number</Label>
                          <PhoneNumber
                            className="form-control"
                            name="phone_no"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col className="p-0">
                          <ButtonWithLoader
                            color="primary"
                            type="submit"
                            isLoading={isGeneralInfoButtonLoading}
                          >
                            Update
                          </ButtonWithLoader>
                        </Col>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xs="12" lg="4" xl={{ size: 3, offset: 1 }}>
                    <FileUploader
                      name="profilePicture"
                      uploadBoundary={(
                        <div className="logo-uploader">
                          <div className="logo-uploader-data">
                            {
                              isLoading ? (
                                <Loader />
                              )
                                : (
                                  <React.Fragment>
                                    {profilePicUrl && (
                                      <div>
                                        <div className="uploaded-img-wrap">
                                          <img src={profilePicUrl} alt="" />
                                        </div>
                                        <div className="upload-data">
                                          <UploadIcon />
                                          <div className="logo">Profile Picture</div>
                                          <p>
                                            <Link to="#">Browse</Link>
                                            {' '}
                                            or Drag & Drop
                                            {' '}
                                            <br className="d-none d-md-block" />
                                            Picture to Upload
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    <React.Fragment>
                                      <UploadIcon />
                                      <div className="logo">Profile Picture</div>
                                      <p>
                                        <Link to="#">Browse</Link>
                                        {' '}
                                        or Drag & Drop
                                        {' '}
                                        <br className="d-none d-md-block" />
                                        Picture to Upload
                                      </p>
                                    </React.Fragment>
                                  </React.Fragment>
                                )
                            }
                          </div>
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className="hr" />
          </Col>
        </Row>
        <Row>
          <Col xs="12" xl="7">
            <FormProvider {...rhfMethod}>
              <Form onSubmit={rhfMethod.handleSubmit(onSubmitPassword)}>
                <FormGroup row>
                  <Col>
                    <h3 className="header3">Change Password</h3>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col lg="6">
                    <Label for="">New Password</Label>
                    <CustomInputField
                      className="form-control"
                      name="password"
                      type="password"
                    />
                  </Col>
                  <Col lg="6">
                    <Label for="">New Password Confirmation</Label>
                    <CustomInputField
                      className="form-control"
                      name="password_confirmation"
                      type="password"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <ButtonWithLoader
                      color="primary"
                      type="submit"
                      isLoading={isPasswordSubmitButtonLoading}
                    >
                      Change Password
                    </ButtonWithLoader>
                  </Col>
                </FormGroup>
              </Form>
            </FormProvider>
          </Col>
        </Row>
      </div>
      {
        showImageCropModal && (
          <ImageCropModal
            profilePicture={profilePicture}
            showImageCropModal={showImageCropModal}
            attachmentId={attachmentData?.attachment_id}
            toggle={() => setImageCropModal(false)}
            profileUpdate={profileUpdate}
            oldProfilePicUrl={oldProfilePicUrl}
          />
        )
      }
    </React.Fragment>
  );
};
export default MyProfile;
