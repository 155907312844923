import React, { createContext, useEffect, useState } from 'react';

import getLookUpData from 'services/lookup';
import { convertArrayToObject, generateDropdownOptions, generateDropdownOptionsFromHash } from 'helpers/utils';
import { isEmpty } from 'lodash';

export const LookupContext = createContext();

export const LookupProvider = ({ children }) => {
  const [lookupDetails, setLookupDetails] = useState('');

  useEffect(() => {
    getLookUpData()
      .then((res) => {
        const {
          property_manager_settings: propertyManagerSettings,
          service_categories: serviceCategories, states,
          invoice_statuses: invoiceStatus,
          quote_statuses: quoteStatus,
          closed_job_statuses: closedJobStatus,
          market_cities: marketCities,
          resident_owners: residentOwners,
          is_resident_portal_enabled: isResidentPortalEnabled,
        } = res?.data;
        setLookupDetails({
          ...(propertyManagerSettings
            ? {
              property_manager_settings: generateDropdownOptions(propertyManagerSettings,
                'consumer_setting_id', 'label'),
            } : {}),
          ...(serviceCategories
            ? { service_categories: generateDropdownOptions(serviceCategories, 'service_category_id', 'label') } : {}),
          ...(states
            ? { states: generateDropdownOptions(states, 'state_id', 'label') } : {}),
          ...(propertyManagerSettings
            ? {
              propertyManagerSettingsLookupObj: convertArrayToObject(propertyManagerSettings,
                'slug'),
            } : {}),
          ...(serviceCategories
            ? { serviceCategoriesLookupObj: convertArrayToObject(serviceCategories, 'service_category_id') } : {}),
          ...(invoiceStatus
            ? { invoiceStatus: generateDropdownOptionsFromHash(invoiceStatus) } : {}),
          ...(closedJobStatus
            ? { closedJobStatus: generateDropdownOptionsFromHash(closedJobStatus) } : {}),
          ...(!isEmpty(quoteStatus)
            ? { quoteStatus: generateDropdownOptionsFromHash(quoteStatus) } : {}),
          ...(!isEmpty(quoteStatus)
            ? { quoteStatusObj: quoteStatus } : {}),
          ...(!isEmpty(marketCities) ? { marketCities: generateDropdownOptions(marketCities, 'market_city_id', 'market_city') } : {}),
          ...(!isEmpty(residentOwners) ? { residentOwners: generateDropdownOptions(residentOwners, 'resident_id', 'owner_name') } : {}),
          isResidentPortalEnabled: !!isResidentPortalEnabled,
        });
      }).catch(() => {});
  }, []);
  return (
    <LookupContext.Provider value={lookupDetails}>
      {children}
    </LookupContext.Provider>
  );
};
