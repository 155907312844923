import React from 'react';

const AnsweringService = (props) => (
  <svg {...props} width="60" height="60" viewBox="0 0 48 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.94385 29.255V23.495C2.94385 11.783 12.3518 2.375 24.0638 2.375C35.7758 2.375 45.1838 11.783 45.1838 23.495V29.255" stroke="#3e7cff" strokeWidth="2.5" strokeLinejoin="round" />
    <path d="M45.184 29.2549C45.184 39.8149 37.504 48.6469 27.328 50.1829C25.6 50.3749 24.064 49.0309 24.064 47.3029V47.3029C24.064 45.7669 25.216 44.6149 26.752 44.6149V44.6149C28.48 44.6149 29.824 45.9589 29.824 47.6869V48.4549" stroke="#3e7cff" strokeWidth="2.5" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6238 21.5752C6.39985 21.5752 2.94385 25.0312 2.94385 29.2552C2.94385 33.4792 6.39985 36.9352 10.6238 36.9352V21.5752Z" stroke="#3e7cff" strokeWidth="2.5" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.5039 36.9352C41.7279 36.9352 45.1839 33.4792 45.1839 29.2552C45.1839 25.0312 41.7279 21.5752 37.5039 21.5752V36.9352Z" stroke="#3e7cff" strokeWidth="2.5" strokeLinejoin="round" />
  </svg>

);
export default AnsweringService;
