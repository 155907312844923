import React, { useEffect, useState } from 'react';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { getSpecificJobDetails } from 'services/jobs';
import Loader from '../loader';

const JobDescriptionModal = ({
  toggle,
  jobId,
  jobDescription,
  entity,
}) => {
  const [description, setDescription] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (jobId) {
      setLoading(true);
      getSpecificJobDetails(jobId, 'description').then((res) => {
        setDescription(res?.data);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [jobId]);

  return (
    <Modal
      isOpen
      toggle={toggle}
      centered
      className="job-description-modal"
    >
      <ModalHeader toggle={toggle}>
        {
          entity === 'make-ready' ? 'Make Ready Scope' : 'Job Description'
        }
        {' '}
        {
          description?.job_no
            ? (
              <React.Fragment>
                (#
                {description.job_no}
                )
              </React.Fragment>
            )
            : ''
        }

      </ModalHeader>
      {
        isLoading ? <Loader /> : (
          <ModalBody>
            <div className="divider" />
            <div className="job-desc-data">
              <div className="job-desc-data-inner">
                <ul>
                  <li>
                    {description?.description || jobDescription}
                  </li>
                </ul>
              </div>
              <div className="button-outer-wrap">
                <Button color="secondary" onClick={toggle}>Close</Button>
              </div>
            </div>
          </ModalBody>
        )
      }
    </Modal>
  );
};

export default JobDescriptionModal;
