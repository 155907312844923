import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { OpenJobsHeaderIcon } from 'assets/images/svg-icons';
import Filter from 'components/shared/filter/filter';
import { LookupContext } from 'context/lookup-context/lookup-context';

const OpenJobsFilter = ({
  serviceFilters,
  pmFilter,
  setFilterParams,
  startDate,
  endDate,
  setLoading,
}) => {
  const lookupDetails = useContext(LookupContext);
  const location = useLocation();

  return (
    <Filter
      title="Open Jobs"
      HeaderIcon={() => <OpenJobsHeaderIcon />}
      filters={[{
        filter_type: 'custom-input',
        name: 'search_keyword',
        placeholder: 'Search by Address/Tenant/Work Order',
        type: 'text',
        inputProps: {
          className: 'form-control',
        },
      },
      {
        filter_type: 'select',
        name: 'service_category',
        options: serviceFilters,
        placeholder: 'Filter by Service',
        inputProps: {
          className: 'global-select global-select-resize',
        },
      },
      {
        filter_type: 'select',
        name: 'property_manager',
        options: pmFilter,
        placeholder: 'Filter by PM',
        type: 'text',
        inputProps: {
          className: 'global-select global-select-resize',
        },
      },
      {
        filter_type: 'select',
        name: 'market_city',
        options: lookupDetails?.marketCities ?? [],
        placeholder: 'All Markets',
        type: 'text',
        inputProps: {
          className: 'global-select global-select-resize',
        },
      },
      {
        filter_type: 'date-range',
        name: 'dateRange',
        from_name: 'from_date',
        to_name: 'to_date',
      },
      {
        filter_type: 'clear',
        name: 'clear-filter',
      },
      ]}
      clearStateValues={{
        dateRange: {
          startDate,
          endDate,
          type: 'date-range',
        },
      }}
      defaultValues={{
        search_keyword: location?.state?.job_no || '',
        uniqueKey: location?.key,
        dateRange: {
          startDate,
          endDate,
          type: 'date-range',
        },
      }}
      onFilter={(params) => {
        setLoading(true);
        setFilterParams(params);
      }}
    />
  );
};

export default OpenJobsFilter;
