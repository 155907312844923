import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Filter from 'components/shared/filter/filter';

import { OpenMakeReadyIcon } from 'assets/images/svg-icons';
import { getOpenMakeReadyFilters } from 'services/jobs';
import { useLocation } from 'react-router-dom';

const OpenMakeReadiesFilter = ({
  startDate,
  endDate,
  setLoading,
  setFilters,
}) => {
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    setLoading(true);
    getOpenMakeReadyFilters().then((response) => {
      if (!isEmpty(response?.data)) {
        setFilterValues(response?.data);
      }
    }).catch(() => {
      setLoading(false);
    });
  }, [setLoading]);

  const location = useLocation();

  return (
    <Filter
      title="Open Make Ready Board"
      HeaderIcon={() => <OpenMakeReadyIcon />}
      filters={[{
        filter_type: 'custom-input',
        name: 'search_keyword',
        placeholder: 'Search by Address/Work Order #',
        type: 'text',
        inputProps: {
          className: 'form-control',
        },
      },
      {
        filter_type: 'select',
        name: 'property_manager',
        placeholder: 'Filter by PM',
        options: filterValues?.property_managers,
        type: 'text',
        inputProps: {
          className: 'global-select global-select-resize',
        },
      },
      {
        filter_type: 'date-range',
        name: 'dateRange',
        from_name: 'from_date',
        to_name: 'to_date',
      },
      {
        filter_type: 'clear',
        name: 'clear-filter',
      },
      ]}
      clearStateValues={{
        dateRange: {
          startDate,
          endDate,
          type: 'date-range',
        },
      }}
      defaultValues={{
        search_keyword: location?.state?.job_no,
        uniqueKey: location?.key,
        dateRange: {
          startDate,
          endDate,
          type: 'date-range',
        },
      }}
      onFilter={(params) => {
        setLoading(true);
        setFilters(params);
      }}
    />
  );
};

export default OpenMakeReadiesFilter;
