import React from 'react';
import NoDataIcon from 'assets/images/global/no-data.svg';

const NoDataLoader = () => (
  <div className="loader-wrapper">
    <div className="loader no-data">
      <img src={NoDataIcon} alt="" />
      <h6>NO DATA</h6>
    </div>
  </div>
);
export default NoDataLoader;
