import React, { useState } from 'react';
import {
  Form,
  FormGroup,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import { forgotPassword } from 'services/auth';
import catchHandler from 'helpers/catch-handler';
import FormGeneralAlert from 'components/shared/form-fields/form-general-alert';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';

import {
  EmailIcon,
} from 'assets/images/svg-icons';

const ForgotPassword = () => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });
  const { errors, setError, formState } = methods;
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formData) => {
    setIsLoading(true);
    forgotPassword(formData).then((res) => {
      setIsLoading(false);
      setSuccessMessage(res.data && res.data.message);
    }).catch((error) => {
      setIsLoading(false);
      catchHandler(error, setError);
      setSuccessMessage('');
    });
  };

  return (
    <div className="auth-card--content">
      <h5>Reset Password</h5>
      <h4>
        <strong>Lula Customer</strong>
        {' '}
        Account
      </h4>
      {
        (errors || successMessage)
        && <FormGeneralAlert errors={errors} successMessage={successMessage} />
      }
      <FormProvider {...methods}>
        <Form className="auth-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <FormGroup>
            <i>
              <EmailIcon />
            </i>
            <CustomInputField className="form-control" name="email" placeholder="Email Address" clearError />
          </FormGroup>
          <div className="auth-btn-outer auth-btn-outer--forgot-password d-flex justify-content-center">
            <ButtonWithLoader
              isLoading={isLoading}
              color="primary"
              disabled={!formState.isDirty}
            >
              Send Reset Link
            </ButtonWithLoader>
            <Link className="auth-wrap__text-link" to="/auth/login">Back to Login Page</Link>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ForgotPassword;
