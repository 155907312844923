import React from 'react';

const FlatPriceCatalogSidebar = () => (
  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5417 11.3875H5.61666M7.92499 16.0042H5.61666M14.85 6.77083H5.61666M19.4667 6.54V18.5433C19.4667 20.4825 19.4666 21.4521 19.0893 22.1928C18.7573 22.8443 18.2276 23.374 17.5761 23.7059C16.8354 24.0833 15.8658 24.0833 13.9267 24.0833H6.54C4.60082 24.0833 3.63122 24.0833 2.89056 23.7059C2.23905 23.374 1.70935 22.8443 1.37739 22.1928C1 21.4521 1 20.4825 1 18.5433V6.54C1 4.60082 1 3.63122 1.37739 2.89056C1.70935 2.23905 2.23905 1.70935 2.89056 1.37739C3.63122 1 4.60082 1 6.54 1H13.9267C15.8658 1 16.8354 1 17.5761 1.37739C18.2276 1.70935 18.7573 2.23905 19.0893 2.89056C19.4666 3.63122 19.4667 4.60082 19.4667 6.54Z" stroke="#949DAE" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default FlatPriceCatalogSidebar;
