import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  NavbarToggler,
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import {
  OpenJobsIcon,
  DashboardIcon,
  ClosedJobsIcon,
  HelpIcon,
  CreateJobIcon,
  QuotesIcon,
  TenentConversationIcon,
  InvoicesIcon,
  PreferredVendorsIcon,
  LogoIcon,
  SettingsIcon,
  LogGreyIcon,
  LogoIconDev,
  LogoIconQA,
  LogoIconStaging,
  OpenMakeReadiesIcon,
  ClosedMakeReadiesIcon,
  FlatPriceCatalogSidebarIcon,
} from 'assets/images/svg-icons';
import { AuthContext } from 'context/auth-context/auth-context';
import { getQuotesCount } from 'services/quotes';

const Sidebar = () => {
  const hideMenu = () => {
    document.getElementById('sidebarNav').classList.remove('open');
  };
  const [userDetails] = useContext(AuthContext);
  const [quoteCount, setQuoteCount] = useState('');

  const timeOutRef = useRef();

  const fetchQuotesCount = useCallback(() => {
    if (!document.hidden) {
      getQuotesCount({ quote_status: 'pending_approval', response_data: 'count' })
        .then((res) => {
          setQuoteCount(res?.data?.quote_count || '');
        }).catch(() => {});
    }

    timeOutRef.current = setTimeout(() => {
      fetchQuotesCount();
    }, 60000);
  }, []);

  useEffect(() => {
    fetchQuotesCount();

    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, [fetchQuotesCount]);

  const displayLogo = () => {
    if (process.env.REACT_APP_LOGO_NAME === 'lula-dev') {
      return <LogoIconDev />;
    }
    if (process.env.REACT_APP_LOGO_NAME === 'lula-staging') {
      return <LogoIconStaging />;
    }
    if (process.env.REACT_APP_LOGO_NAME === 'lula-qa') {
      return <LogoIconQA />;
    }
    return <LogoIcon />;
  };

  return (
    <div className="global-sidebar offcanvas-collapse" id="sidebarNav">
      <aside className="sidebar-wrapper sidebar-collapse">
        <div className="sidebar-top-section">
          <div className="sidebar-logo-outer d-none d-lg-block">
            <Link to="/">{displayLogo()}</Link>
            <h5>Customer Dashboard</h5>
          </div>
          <div className="sidebar-top-section-mobile">
            {/* <Link to="#" className="mobile-notification unread" onClick={hideMenu}>
              <NotificationsIcon />
            </Link> */}
            <Link
              to="/my-profile"
              className="mobile-settings"
              onClick={hideMenu}
            >
              <SettingsIcon />
            </Link>
            <NavbarToggler
              onClick={hideMenu}
              className="mobile-navbar-toggler"
            />
          </div>
          <div className="sidebar-asset-management">
            {userDetails?.partner?.partner_logo_url ? (
              <img
                src={userDetails.partner.partner_logo_url}
                alt=""
                width="27px"
              />
            ) : (
              <React.Fragment>
                {userDetails?.partner?.label?.charAt(0).toUpperCase()}
              </React.Fragment>
            )}
            <Link
              to={
                userDetails?.property_manager_type !== 'employee'
                  ? '/company-profile'
                  : '#'
              }
              onClick={hideMenu}
            >
              <h5>
                {userDetails?.partner?.label}
                <br />
                Group
              </h5>
            </Link>
          </div>
          <ul className="admin-sidebar text-left">
            <li className="sidebar__list">
              <NavLink
                to="/dashboard"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <DashboardIcon />
                </i>
                Dashboard
              </NavLink>
            </li>
            <li className="sidebar__list">
              <NavLink
                to="/open-jobs"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <OpenJobsIcon />
                </i>
                Open Jobs
              </NavLink>
            </li>
            <li className="sidebar__list">
              <NavLink
                to="/closed-jobs"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <ClosedJobsIcon />
                </i>
                Closed Jobs
              </NavLink>
            </li>
            <li className="sidebar__list">
              <NavLink
                to="/open-make-readies"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <OpenMakeReadiesIcon />
                </i>
                Open Make Readies
              </NavLink>
            </li>
            <li className="sidebar__list">
              <NavLink
                to="/closed-make-readies"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <ClosedMakeReadiesIcon />
                </i>
                Closed Make Readies
              </NavLink>
            </li>
            <li className="sidebar__list">
              <NavLink
                to="/quotes"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <QuotesIcon />
                  {quoteCount ? (
                    <span className="item-count">{quoteCount}</span>
                  ) : (
                    ''
                  )}
                </i>
                Quotes
              </NavLink>
            </li>
            <li className="sidebar__list">
              <NavLink
                to="/invoices"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <InvoicesIcon />
                </i>
                Invoices
              </NavLink>
            </li>
            {userDetails?.flat_price_catalog
              ? (
                <li className="sidebar__list">
                  <a
                    href={userDetails?.flat_price_catalog?.link}
                    target="_blank"
                    rel="noreferrer"
                    className="sidebar__list--text"
                    onClick={hideMenu}
                  >
                    <i className="catalog-icon">
                      <FlatPriceCatalogSidebarIcon />
                    </i>
                    Flat Price Catalog
                    <span className="custom-tag new">
                      New
                    </span>
                  </a>
                </li>
              ) : ''}
            <li className="sidebar__list">
              <NavLink
                to="/help"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <HelpIcon />
                </i>
                Help
              </NavLink>
            </li>
            <li>
              <h6 className="coming-soon">Coming Soon</h6>
            </li>
            <li className="sidebar__list comingsoon">
              <NavLink
                style={{ pointerEvents: 'none' }}
                to="/create-job"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <CreateJobIcon />
                </i>
                Create Job
              </NavLink>
            </li>
            <li className="sidebar__list comingsoon">
              <NavLink
                style={{ pointerEvents: 'none' }}
                to="/tenant-conversation"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <TenentConversationIcon />
                </i>
                Tenant Conversations
              </NavLink>
            </li>
            <li className="sidebar__list comingsoon">
              <NavLink
                style={{ pointerEvents: 'none' }}
                to="/preferred-vendors"
                className="sidebar__list--text"
                activeClassName="active"
                onClick={hideMenu}
              >
                <i>
                  <PreferredVendorsIcon />
                </i>
                Preferred Vendors
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sidebar-bottom-section d-none d-lg-block">
          <LogGreyIcon />
          <div className="copoyright">
            Copyright
            {' '}
            <span>{new Date().getFullYear()}</span>
            {' '}
            &copy; All rights
            reserved.
          </div>
          <div className="cla">
            By using Lula you agree to our
            {' '}
            <Link to="/customer-service-agreement">CSA</Link>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
