import React from 'react';

const CalendarIconSmall = (props) => (
  <svg {...props} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.27528 12.8897H10.6437C11.9154 12.8897 12.9463 11.8623 12.9463 10.5948V4.16897C12.9463 2.9015 11.9154 1.87402 10.6437 1.87402H3.27528C2.00358 1.87402 0.972656 2.9015 0.972656 4.16897V10.5948C0.972656 11.8623 2.00358 12.8897 3.27528 12.8897ZM1.89371 4.16896C1.89371 3.40849 2.51226 2.792 3.27528 2.792H10.6437C11.4067 2.792 12.0253 3.40849 12.0253 4.16896V10.5948C12.0253 11.3553 11.4067 11.9718 10.6437 11.9718H3.27528C2.51226 11.9718 1.89371 11.3553 1.89371 10.5948V4.16896Z" fill="#283A5D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.80135 11.0537H10.6434C10.8978 11.0537 11.104 10.8482 11.104 10.5947V8.75879C11.104 8.5053 10.8978 8.2998 10.6434 8.2998H8.80135C8.547 8.2998 8.34082 8.5053 8.34082 8.75879V10.5947C8.34082 10.8482 8.547 11.0537 8.80135 11.0537ZM10.1829 10.1358H9.26187V9.21778H10.1829V10.1358Z" fill="#283A5D" />
    <path d="M12.4858 5.54591H1.43318C1.17884 5.54591 0.972656 5.34041 0.972656 5.08692C0.972656 4.83343 1.17884 4.62793 1.43318 4.62793H12.4858C12.7401 4.62793 12.9463 4.83343 12.9463 5.08692C12.9463 5.34041 12.7401 5.54591 12.4858 5.54591Z" fill="#283A5D" />
    <path d="M4.65681 3.70998C4.40247 3.70998 4.19629 3.50449 4.19629 3.251V1.41504C4.19629 1.16155 4.40247 0.956055 4.65681 0.956055C4.91116 0.956055 5.11734 1.16155 5.11734 1.41504V3.251C5.11734 3.50449 4.91116 3.70998 4.65681 3.70998Z" fill="#283A5D" />
    <path d="M9.26228 3.70998C9.00794 3.70998 8.80176 3.50449 8.80176 3.251V1.41504C8.80176 1.16155 9.00794 0.956055 9.26228 0.956055C9.51662 0.956055 9.72281 1.16155 9.72281 1.41504V3.251C9.72281 3.50449 9.51662 3.70998 9.26228 3.70998Z" fill="#283A5D" />
  </svg>
);

export default CalendarIconSmall;
