import React from 'react';

const ClosedJobsHeaderIcons = (props) => (
  <svg {...props} width="27" height="27" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8021 1.41824C12.6988 1.28025 13.6064 1.20734 14.514 1.23546C14.7212 1.26046 14.929 1.28025 15.1383 1.28129C18.0924 1.49999 20.946 2.75598 23.1377 4.74306C22.894 5.05654 22.6352 5.35908 22.367 5.65225C20.5841 4.03175 18.3304 2.92209 15.9439 2.56279C14.1542 2.27379 12.3025 2.40501 10.5627 2.9122C8.78134 3.43344 7.12544 4.36918 5.7476 5.61215C3.95683 7.23056 2.64565 9.38064 2.05358 11.7229C1.6193 13.4152 1.5464 15.2003 1.85363 16.9197C2.22334 19.0953 3.20751 21.1594 4.64471 22.832C6.74531 25.3049 9.85716 26.903 13.0971 27.1254C13.6517 27.191 14.213 27.1644 14.7702 27.1519C15.0321 27.1066 15.2998 27.1248 15.5601 27.0712C17.7982 26.8082 19.9519 25.8965 21.7151 24.4957C22.9555 23.5225 23.9974 22.3009 24.7837 20.935C26.0569 18.7302 26.6151 16.1235 26.3631 13.5923C26.1897 11.8327 25.6304 10.1107 24.7337 8.58549C24.6994 8.52144 24.64 8.43812 24.7082 8.37407C24.953 8.07987 25.2034 7.78982 25.4508 7.4977C26.4896 9.06351 27.1452 10.8704 27.4457 12.7211C27.5649 13.6136 27.6389 14.5145 27.592 15.4148C27.5404 15.6814 27.5665 15.9548 27.5233 16.2224C27.2842 18.5282 26.4323 20.7658 25.0821 22.6497C24.0568 24.0911 22.7456 25.3268 21.2485 26.2683C19.5015 27.3727 17.4962 28.0658 15.4398 28.2746C14.7853 28.3189 14.1287 28.3866 13.4715 28.3355C13.2643 28.3012 13.0534 28.3053 12.8446 28.2939C10.9231 28.1199 9.03702 27.5373 7.36133 26.5791C5.4737 25.5075 3.84852 23.9776 2.6696 22.1545C1.46777 20.3164 0.733548 18.1772 0.54088 15.9902C0.542442 15.6403 0.470582 15.2955 0.484121 14.9456C0.482038 13.662 0.637735 12.3732 0.990265 11.1365C1.75781 8.36835 3.44444 5.86887 5.71115 4.10674C7.47485 2.71953 9.59055 1.7916 11.8021 1.41824Z" fill="#48C898" stroke="#48C898" strokeWidth="0.7" />
    <path d="M24.1812 5.34588C24.3988 5.11572 24.5717 4.84286 24.8144 4.6377C25.0966 4.8866 25.3898 5.12249 25.673 5.36983C25.6074 5.50939 25.5001 5.62134 25.4002 5.7359C21.4343 10.4104 17.4753 15.0907 13.5073 19.7637C11.6025 18.0068 9.71594 16.229 7.81738 14.4653C8.06056 14.1539 8.34384 13.8774 8.62034 13.5973C10.2049 15.0907 11.8067 16.5644 13.3923 18.0562C13.8661 17.5522 14.2905 17.0033 14.7456 16.4831C17.8903 12.7698 21.0381 9.06021 24.1812 5.34588Z" fill="#48C898" stroke="#48C898" strokeWidth="0.7" />
  </svg>

);

export default ClosedJobsHeaderIcons;
