import React from 'react';

const EnRouteIcon = (props) => (
  <svg {...props} width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.1709 4.05417C2.1709 7.74115 2.2546 8.82366 4.71258 8.82366C6.35123 8.82366 7.17056 8.00434 7.17056 5.95602C7.17056 3.9077 7.17056 2.67871 9.62854 2.67871C12.0865 2.67871 12.0457 3.66057 12.0457 7.75721" stroke="white" strokeWidth="1.5" />
    <circle cx="2.25472" cy="3.09603" r="1.64232" stroke="white" strokeWidth="1.2" />
    <circle cx="12.0868" cy="8.80404" r="1.64232" stroke="white" strokeWidth="1.2" />
  </svg>

);

export default EnRouteIcon;
