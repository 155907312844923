import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import {
  Button,
  Form,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { sendAskQuestion } from 'services/jobs';
import catchHandler from 'helpers/catch-handler';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';

const AskQuestionModal = ({ closeModal, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .required('This is a required field')
      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespace'),
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      question: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    setError,
    formState: { errors },
  } = methods;
  const onSubmit = (formData) => {
    setIsLoading(true);
    sendAskQuestion(data?.job_id, formData)
      ?.then((res) => {
        setIsLoading(false);
        setShowSuccess(res?.data?.message || 'Submitted successfully');
      })
      .catch((err) => {
        catchHandler(err, setError);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      isOpen
      toggle={closeModal}
      centered
      className="global-modal ask-question-modal"
    >
      <ModalHeader toggle={closeModal}>
        Ask a Question regarding #
        {data?.job_no}
      </ModalHeader>
      <ModalBody>
        <div className="divider" />
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="ask-question-data"
          >
            {!showSuccess ? (
              <React.Fragment>
                <CustomInputField
                  type="textarea"
                  name="question"
                  placeholder="Write your question here"
                  className="custom-textarea"
                />
                {errors?.general_errors?.message ? (
                  <FormFeedback className="text-error">
                    {errors?.general_errors?.message}
                  </FormFeedback>
                ) : (
                  ''
                )}
                <div className="btn-action-data">
                  <Button color="secondary" onClick={closeModal}>
                    Close
                  </Button>
                  <ButtonWithLoader type="submit" color="primary" isLoading={isLoading}>
                    Submit
                  </ButtonWithLoader>
                </div>
              </React.Fragment>
            ) : (
              <p>{showSuccess}</p>
            )}
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AskQuestionModal;
