import React from 'react';

const StatusPartiallyPaidIcon = (props) => (
  <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.606445" y="0.212891" width="18" height="18" rx="6" fill="#3E7CFF" />
    <path d="M5.10645 9.21368C9.10645 12.2137 11.1064 6.21368 14.1064 9.21368" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="bevel" />
  </svg>

);
export default StatusPartiallyPaidIcon;
