import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  QuotesIcon,
  StatusCompletedIcon, StatusVoidIcon,
  PortableDocumentIcon, StatusUnpaidIcon, StatusExpiredIcon,
} from 'assets/images/svg-icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CustomInput } from 'reactstrap';

import Filter from 'components/shared/filter/filter';
import { LookupContext } from 'context/lookup-context/lookup-context';
import ListingComponent from 'components/shared/listing-component';
import { generateRandomString, getKeyByValue, openInANewTab } from 'helpers/utils';
import HeaderComponent from 'components/shared/header-component';
import JobDescriptionModal from 'components/shared/modals/job-description-modal';
import JobDetails from 'components/shared/job-details';

import { getQuotePdfLink, getQuotes } from 'services/quotes';

const Quotes = () => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const lookupData = useContext(LookupContext);
  const [loading, setLoading] = useState(false);
  const [showPendingApproval, setShowPendingApproval] = useState(false);
  const [filter, setFilter] = useState({});
  const [showDescription, setDescription] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState();
  const [pdfJobIds, setPdfJobIds] = useState([]);
  const [reset, setReset] = useState(false);

  const { serviceCategoriesLookupObj } = lookupData;

  const fetchQuotes = useCallback((params) => {
    // Needed to set to avoid expanded items being called again on page change
    setData([]);
    getQuotes(params).then((res) => {
      setLoading(false);
      setData(res?.data?.data);
      setMeta(res?.data?.meta);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    if (lookupData) {
      fetchQuotes({
        from_date: startDate,
        to_date: endDate,
        ...filter,
        ...(showPendingApproval ? ({
          quote_status: getKeyByValue((lookupData?.quoteStatusObj || {}),
            'Pending Approval'),
        }) : {}),
      });
    }
  }, [fetchQuotes, filter, showPendingApproval, startDate, endDate, lookupData]);

  const showDescriptionHandler = (jobId) => {
    setSelectedJobId(jobId);
    setDescription(true);
  };

  const getClassName = (status) => {
    switch (status) {
      case 'Expired': return 'past-due-expired';
      case 'Rejected': return 'past-due';
      case 'Approved': return 'completed';
      default: return '';
    }
  };

  const viewPDFHandler = (jobId) => {
    setPdfJobIds([...pdfJobIds, jobId]);
    getQuotePdfLink(jobId).then((res) => {
      setPdfJobIds((prevIds) => prevIds.filter((id) => (id !== jobId)));
      if (res.data.pdf_url) {
        openInANewTab(res.data.pdf_url);
      }
    }).catch(() => {
      setPdfJobIds((prevIds) => prevIds.filter((id) => (id !== jobId)));
    });
  };
  const getStatusIcon = (status) => {
    switch (status) {
      case 'Approved': return <StatusCompletedIcon />;
      case 'Rejected': return <StatusVoidIcon />;
      case 'Pending Approval': return <StatusUnpaidIcon />;
      case 'Expired': return <StatusExpiredIcon />;
      default: return '';
    }
  };

  return (
    <React.Fragment>
      <HeaderComponent>
        <div className={`archived-btn ${showPendingApproval ? 'active' : ''}`}>
          Show Pending Approval Quotes Only
          <CustomInput
            type="switch"
            id="my_jobs_switch"
            name="my_jobs_switch"
            label=""
            checked={showPendingApproval}
            onChange={() => setShowPendingApproval(!showPendingApproval)}
          />
        </div>
      </HeaderComponent>
      <Filter
        title="Quotes"
        HeaderIcon={() => <QuotesIcon />}
        filters={[{
          filter_type: 'custom-input',
          name: 'search_keyword',
          placeholder: 'Search by Quote/Address/ Tenant',
          type: 'text',
          inputProps: {
            className: 'form-control form-control-closed-jobs',
          },
        },
        {
          filter_type: 'select',
          name: 'service_category',
          options: lookupData?.service_categories ?? [],
          placeholder: 'Filter by Service',
          inputProps: {
            className: 'global-select global-select-resize',
          },
        },
        {
          filter_type: 'select',
          name: 'quote_status',
          options: lookupData?.quoteStatus ?? [],
          placeholder: 'Filter by Status',
          inputProps: {
            className: 'global-select global-select-resize',
          },
        },
        {
          filter_type: 'select',
          name: 'market_city',
          options: lookupData?.marketCities ?? [],
          placeholder: 'All Markets',
          type: 'text',
          inputProps: {
            className: 'global-select global-select-resize',
          },
        },
        ...(lookupData?.isResidentPortalEnabled ? [{
          filter_type: 'select',
          name: 'resident_owner',
          options: lookupData?.residentOwners ?? [],
          placeholder: 'Owner',
          type: 'text',
          inputProps: {
            className: 'global-select global-select-resize',
          },
        }] : []),
        {
          filter_type: 'date-range',
          name: 'dateRange',
          from_name: 'from_date',
          to_name: 'to_date',
        },
        {
          filter_type: 'clear',
          name: 'clear-filter',
        },
        ]}
        defaultValues={{
          dateRange: {
            startDate,
            endDate,
            type: 'date-range',
          },
        }}
        onFilter={(params, _values, isReset) => {
          setFilter((prev) => ({
            ...(prev.per_page ? { per_page: prev?.per_page } : {}),
            ...(!isReset && prev.sort ? { sort: prev.sort } : {}),
            page: 1,
            ...params,
          }));
          setReset(isReset);
        }}
      />
      <div className="data-card p-0 custom-data-card">
        <ListingComponent
          isLoading={loading}
          mappings={[
            {
              key: 'expand',
              label: '',
              type: 'expand',
            },
            {
              key: 'job_no',
              label: 'QUOTE #',
              render: (item) => (
                <React.Fragment>
                  <div className="services">
                    {
                      serviceCategoriesLookupObj?.[item.service_category_id]?.logo && (
                        <img
                          src={serviceCategoriesLookupObj[item.service_category_id].logo}
                          alt=""
                          width="27px"
                          height="27px"
                          className="d-block d-sm-none"
                        />

                      )
                    }
                    <Link to="#" onClick={() => showDescriptionHandler(item.job_id)}>{item.job_no}</Link>
                  </div>
                </React.Fragment>
              ),
            },
            {
              key: 'service_category',
              label: 'SERVICE TYPE',
              className: 'd-none d-sm-table-cell',
              render: (item) => (
                <div className="services">
                  {
                    serviceCategoriesLookupObj?.[item.service_category_id]?.logo && (
                      <img
                        src={serviceCategoriesLookupObj[item.service_category_id].logo}
                        alt=""
                        width="27px"
                        height="27px"
                      />

                    )
                  }
                  {item.service_category}
                </div>
              ),
            },
            {
              key: 'address',
              label: 'PROPERTY ADDRESS',
              className: 'd-none d-xl-table-cell',
              render: (item) => (
                <React.Fragment>
                  {item?.address?.split('\n').map((addressInfo) => (
                    <div key={generateRandomString()}>
                      {addressInfo}
                      <br />
                    </div>
                  ))}
                </React.Fragment>
              ),
            },
            {
              key: 'quote_status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'quote_status',
              className: 'd-none d-sm-table-cell',
              render: (item) => (
                <div className={`d-none d-sm-block status ${getClassName(item.quote_status)}`}>
                  {getStatusIcon(item.quote_status)}
                  {item.quote_status}
                </div>
              ),
            },
            {
              key: 'estimate',
              label: 'ESTIMATE',
              sortable: true,
              sortKey: 'estimate',
              render: (item) => (
                <React.Fragment>
                  $
                  {item.estimate}
                </React.Fragment>
              ),
            },
            ...(lookupData?.isResidentPortalEnabled ? [{
              key: 'resident_owner_name',
              label: 'OWNER',
              sortable: true,
              sortKey: 'resident_owner_name',
              render: (item) => (
                <React.Fragment>
                  {item?.resident_owner_name || '-'}
                </React.Fragment>
              ),
            }] : []),
            {
              key: 'submitted_date',
              label: 'SUBMIT DATE',
              sortable: true,
              sortKey: 'submitted_date',
              render: (item) => (
                <React.Fragment>
                  {item.submitted_date}
                </React.Fragment>
              ),
            },
            {
              key: 'actions',
              label: 'ACTIONS',
              className: 'text-center',
              render: (item) => (
                <button
                  type="button"
                  disabled={pdfJobIds.includes(item?.job_id)}
                  className="pdf-btn"
                  onClick={() => viewPDFHandler(item?.job_id)}
                >
                  <PortableDocumentIcon />
                  <span>Proposal</span>
                </button>
              ),
            },
            {
              id: 'status_mob',
              key: 'status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'status',
              className: 'd-table-cell d-sm-none text-center',
              render: (item) => (
                <div className={`d-block d-sm-none status ${getClassName(item.quote_status)}`}>
                  {getStatusIcon(item.quote_status)}
                </div>
              ),
            },
          ]}
          dataSource={data}
          meta={meta}
          shouldPaginate
          className="global-table"
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          onSortChange={(sort) => {
            setFilter((prev) => ({ ...prev, sort }));
            setReset(false);
          }}
          primaryKey="job_id"
          renderExpanded={(job) => (
            <JobDetails
              job={job}
              quotes
              confirmIcon={(
                <div className="fill-green">
                  <QuotesIcon width="50" height="40" />
                </div>
            )}
              setData={setData}
            />
          )}
          reset={reset}
        />
      </div>
      {
        showDescription && (
          <JobDescriptionModal
            showDescription={showDescription}
            toggle={() => setDescription(false)}
            jobId={selectedJobId}
          />
        )
      }
    </React.Fragment>
  );
};
export default Quotes;
