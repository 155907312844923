import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { generateRandomString } from 'helpers/utils';

const HeaderPortal = (props) => {
  const headerRoot = document.getElementById('header-portal-container');
  return headerRoot && ReactDOM.createPortal(
    props.children,
    headerRoot,
  );
};

const HeaderComponent = ({ children }) => {
  const headerRoot = document.getElementById('header-portal-container');
  const [key, setKey] = useState(generateRandomString());

  /**
   * Re-render the header component when headerRoot created
   */
  useEffect(() => {
    setKey(generateRandomString());
  }, [headerRoot]);

  return (
    <React.Fragment key={key}>
      { headerRoot && <HeaderPortal>{children}</HeaderPortal> }
    </React.Fragment>
  );
};

export default HeaderComponent;
