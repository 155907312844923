import React, { useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Label,
  FormGroup,
  Button,
  Row,
  Col,
  Input,
} from 'reactstrap';
import Moment from 'moment';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import SelectField from 'components/shared/form-fields/select-field';
import DatePicker from 'components/shared/form-fields/date-picker';
import { LookupContext } from 'context/lookup-context/lookup-context';
import CustomCheckbox from 'components/shared/form-fields/custom-checkbox-field';
import { defineds } from 'components/shared/filter/components/filter-form';

const ExportModal = ({
  closeModal,
  exportHandler,
  isLoading,
  title,
  entity,
}) => {
  const lookupData = useContext(LookupContext);
  const validationSchema = Yup.object().shape(
    {
      dateRange: Yup.object().required('This field is required').nullable(),
      customFromDate: Yup.date()
        .when(
          'customToDate',
          (customToDate, yup) => customToDate && yup.max(customToDate, 'From date cannot be after To date'),
        )
        .when('dateRange', {
          is: (val) => val?.value === 'custom',
          then: Yup.date().required('From date is required'),
        }),
      customToDate: Yup.date()
        .when(
          'customFromDate',
          (customFromDate, yup) => customFromDate && yup.min(customFromDate, 'To date cannot be before From date'),
        )
        .when('dateRange', {
          is: (val) => val?.value === 'custom',
          then: Yup.date().required('To date is required'),
        }),
    },
    ['customToDate', 'customFromDate'],
  );

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      dateRange: {
        label: 'Last 30 days',
        value: {
          from: defineds.startOfLast30Days,
          to: defineds.endOfLast30Days,
        },
      },
      selectedStatuses: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const { watch, setError, setValue } = methods;
  const { dateRange, selectedStatuses } = watch();

  const onSubmit = (formData) => {
    const payload = {
      ...(formData?.dateRange?.value === 'custom'
        ? {
          from_date: formData?.customFromDate
            ? Moment(formData?.customFromDate).format('YYYY-MM-DD') : null,
          to_date: formData?.customFromDate
            ? Moment(formData?.customToDate).format('YYYY-MM-DD') : null,
        }
        : {
          from_date: formData?.dateRange?.value?.from
            ? Moment(formData?.dateRange?.value?.from).format(
              'YYYY-MM-DD',
            ) : null,
          to_date: formData?.dateRange?.value?.to
            ? Moment(formData?.dateRange?.value?.to).format(
              'YYYY-MM-DD',
            ) : null,
        }),
      ...(!isEmpty(formData?.selectedStatuses) && (entity === 'Jobs')
        ? { closed_job_statuses: formData?.selectedStatuses }
        : {}),
      ...(!isEmpty(formData?.selectedStatuses) && (entity === 'Invoices')
        ? { invoice_statuses: formData?.selectedStatuses }
        : {}),
    };
    exportHandler(payload, setError);
  };

  const dateRangeOptions = [
    {
      label: 'Today',
      value: {
        from: Moment().format('YYYY-MM-DD'),
        to: Moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last 30 days',
      value: {
        from: defineds.startOfLast30Days,
        to: defineds.endOfLast30Days,
      },
    },
    {
      label: 'This Week',
      value: {
        from: defineds.startOfWeek,
        to: defineds.endOfWeek,
      },
    },
    {
      label: 'Last Week',
      value: {
        from: defineds.startOfLastWeek,
        to: defineds.endOfLastWeek,
      },
    },
    {
      label: 'This Month',
      value: {
        from: defineds.startOfMonth,
        to: defineds.endOfMonth,
      },
    },
    {
      label: 'Last Month',
      value: {
        from: defineds.startOfLastMonth,
        to: defineds.endOfLastMonth,
      },
    },
    {
      label: 'This Year',
      value: {
        from: defineds.startOfYear,
        to: defineds.endOfYear,
      },
    },
    {
      label: 'Custom Date Range',
      value: 'custom',
    },
  ];

  const statusOptions = () => {
    if (entity === 'Invoices') {
      return lookupData?.invoiceStatus;
    } if (entity === 'Jobs') {
      return lookupData?.closedJobStatus;
    }
    return [];
  };

  return (
    <Modal isOpen toggle={closeModal} centered className="global-modal">
      <ModalHeader toggle={closeModal}>{title}</ModalHeader>
      <ModalBody>
        <div className="divider" />
        <div className="export-jobs-form export-excel">
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Row>
                <Col sm="12">
                  <h4>Date Range</h4>
                </Col>
                <Col sm="12" md="4" className="mb-3">
                  <SelectField
                    name="dateRange"
                    options={dateRangeOptions}
                    className="global-select global-select-resize"
                  />
                </Col>
                {dateRange?.value === 'custom' ? (
                  <React.Fragment>
                    <Col sm="12" md="4" className="mb-3">
                      <DatePicker
                        name="customFromDate"
                        placeholder="From Date"
                        clearError
                      />
                    </Col>
                    <Col sm="12" md="4" className="mb-3">
                      <DatePicker
                        name="customToDate"
                        placeholder="To Date"
                        clearError
                      />
                    </Col>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Row>
              <Row>
                <Col sm="12">
                  <h4>
                    Select
                    {' '}
                    {entity}
                    {' '}
                    Status
                  </h4>
                </Col>
                <Col sm="12" className="invoice-status-outer">
                  <Row>
                    <Col sm="12" md="4" className="mb-3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={!(selectedStatuses?.length > 0)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue('selectedStatuses', []);
                              }
                            }}
                          />
                          All Statuses
                        </Label>
                      </FormGroup>
                    </Col>
                    {statusOptions()?.map((item) => (
                      <Col key={item?.value} sm="12" md="4" className="mb-3">
                        <FormGroup check>
                          <Label check>
                            <CustomCheckbox
                              type="checkbox"
                              name="selectedStatuses"
                              fieldValue={item?.value}
                              value={item?.value}
                              handleOnchange={(event, currentValues, onChange) => {
                                if (!currentValues.includes(event?.target?.value)) {
                                  const newValue = [...currentValues, event.target.value];
                                  onChange(newValue);
                                } else {
                                  const newValue = currentValues?.filter(
                                    (val) => val !== event.target.value,
                                  );
                                  onChange(newValue);
                                }
                              }}
                            />
                            {item?.label}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="close-job-btn-outer">
                    <Button color="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                    <ButtonWithLoader
                      color="primary"
                      type="submit"
                      isLoading={isLoading}
                    >
                      Export Now
                    </ButtonWithLoader>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ExportModal;
