import React from 'react';

const StatusExpired = (props) => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0H6C2.68629 0 0 2.68629 0 6V12C0 15.3137 2.68629 18 6 18H12C15.3137 18 18 15.3137 18 12V6C18 2.68629 15.3137 0 12 0Z" fill="#FF235E" />
    <path d="M9 14C11.7614 14 14 11.7614 14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 11L9.293 9.793C9.10545 9.60551 9.00006 9.35119 9 9.086V6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default StatusExpired;
