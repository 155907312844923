import { ConfirmBigIcon } from 'assets/images/svg-icons';
import React from 'react';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';

const ConfirmationModal = ({
  modal, toggle, confirmMessage,
  title, handler, isLoading, successButtonText,
  cancelButtonText, confirmIcon,
}) => (
  <Modal
    isOpen={modal}
    toggle={toggle}
    centered
    className="global-modal notes-modal"
  >
    <ModalHeader toggle={toggle} />
    <ModalBody>
      <div className="notification-data">
        <div className="notification-icon">
          {confirmIcon || <ConfirmBigIcon />}
        </div>
        <h4>{title}</h4>
        <p>{confirmMessage}</p>
      </div>
      <div className="notification-btn-wrap">
        <Button
          color="primary"
          className="btn-block d-flex align-items-center justify-content-center"
          onClick={() => handler()}
          disabled={isLoading}
        >
          {isLoading ? <span className="spinner-border spinner-border-sm mr-2 d-block" />
            : (
              successButtonText || 'Yes, Confirm'
            )}
        </Button>
        <Button color="secondary" className="btn-block justify-content-center" onClick={toggle}>{cancelButtonText || 'No' }</Button>
      </div>
    </ModalBody>
  </Modal>
);

export default ConfirmationModal;
