import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { debounce, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import Loader from 'components/shared/loader';

import { globalSearch } from 'services/dashboard';
import useOutsideClick from 'hooks/use-outside-click';
import useWindowDimensions from 'hooks/use-window-dimensions';

const GlobalSearch = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showSearchResult, setShowSearchResult] = useState([]);
  const [globalSearchLoading, setGlobalSearchLoading] = useState(false);
  const [showSearchInputField, setShowSearchInputField] = useState(false);

  const [expandSearchInput, setExpandSearchInput] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const searchResultRef = useRef(null);

  const { width } = useWindowDimensions();

  useOutsideClick(searchResultRef, () => {
    if (showSearchInputField) {
      setShowSearchInputField(false);
    }
  });

  const searchResultOptions = [
    { value: 'open_jobs', label: 'Open Jobs' },
    { value: 'closed_jobs', label: 'Closed Jobs' },
    { value: 'open_make_readies', label: 'Open Make Readies' },
    { value: 'closed_make_readies', label: 'Closed Make Readies' },
  ];

  const fetchResult = useCallback((query) => {
    setSearchKeyword(query?.trim());
    setShowSearchResult([]);
    if (query?.trim()?.length > 2) {
      setGlobalSearchLoading(true);
      globalSearch({ search_keyword: query }).then((res) => {
        setShowSearchResult(res?.data);
        setGlobalSearchLoading(false);
      }).catch(() => {
        setGlobalSearchLoading(false);
      });
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(fetchResult, 500), []);

  const getHighlightedText = (message) => {
    if (isEmpty(searchKeyword)) {
      return message;
    }
    return (
      <Highlighter
        searchWords={[searchKeyword]}
        autoEscape
        textToHighlight={message?.toString()}
      />
    );
  };

  const onSearchResultClickHandler = (item, key) => {
    const navigateKey = `/${key?.replace(/_/g, '-')}`;
    const payload = {
      job_no: item?.job_no,
    };
    history.push(navigateKey, payload);
    setShowSearchInputField(false);
  };

  const getLabelColor = (slug) => `stat ${slug || ''}`;

  const renderComponent = () => {
    if (globalSearchLoading) {
      return <Loader />;
    }
    if (searchKeyword?.length < 3) {
      return (
        <div className="search-text-info">
          Please type at least 3 characters to
          {' '}
          <br />
          start searching...
        </div>
      );
    }
    if (!globalSearchLoading && isEmpty(showSearchResult)) {
      return <div className="search-text-info">Nothing Found</div>;
    }
    if (!globalSearchLoading && !isEmpty(showSearchResult)) {
      return (
        <React.Fragment>
          {(searchResultOptions || [])?.map((option) => (
            <React.Fragment key={option?.value}>
              <div className="job-status-head">{option?.label}</div>
              {(showSearchResult?.[option?.value] || [])?.map((item) => (
                <React.Fragment key={item?.job_id}>
                  <div
                    role="presentation"
                    onClick={() => onSearchResultClickHandler(item, option?.value)}
                    className="job-result"
                  >
                    <div className="job-reseult-category-status">
                      {item?.service_category
                        ? (
                          <div className="category">
                            {getHighlightedText(item?.service_category)}
                          </div>
                        )
                        : ''}
                      {item?.job_status_label
                        ? (
                          <div className={getLabelColor(item?.status_group_slug)}>
                            {getHighlightedText(item?.job_status_label)}
                          </div>
                        ) : ''}
                    </div>
                    <div className="job-wo-id">
                      {item?.job_no
                        ? (
                          <div>
                            <strong>Lula Job ID:</strong>
                            {' '}
                            {getHighlightedText(item?.job_no)}
                          </div>
                        )
                        : ''}
                      {item?.reference_no
                        ? (
                          <div>
                            <strong>PM WO ID:</strong>
                            {' '}
                            {getHighlightedText(item?.reference_no)}
                          </div>
                        )
                        : ''}
                    </div>
                    {item?.property_address
                      ? (
                        <div className="property-address">
                          <strong>Property Address:</strong>
                          {' '}
                          {getHighlightedText(item?.property_address)}
                        </div>
                      )
                      : ''}
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return '';
  };

  useEffect(() => {
    if (width <= 1439.99 && !isEmpty(searchKeyword)) {
      setExpandSearchInput(true);
    }
  }, [width, searchKeyword, showSearchResult]);

  useEffect(() => {
    if (!location?.state) {
      const element = document?.getElementById('searchright');
      if (element) {
        element.value = '';
      }
      setSearchKeyword('');
      setShowSearchResult([]);
      setExpandSearchInput(false);
    }
  }, [location]);

  return (
    <React.Fragment>
      <div
        className="ls-page-search"
        ref={searchResultRef}
      >
        {expandSearchInput ? (
          <div
            className="search-close"
            onClick={() => {
              setShowSearchInputField(false);
              setExpandSearchInput(false);
            }}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1355_58424)">
                <path d="M0.732559 15.0001C0.58768 15.0001 0.446047 14.9572 0.325575 14.8767C0.205104 14.7962 0.111207 14.6818 0.0557603 14.548C0.000313745 14.4141 -0.0141909 14.2668 0.0140811 14.1247C0.042353 13.9827 0.112131 13.8521 0.21459 13.7497L13.7497 0.21455C13.8871 0.0771758 14.0734 0 14.2677 0C14.462 0 14.6483 0.0771758 14.7857 0.21455C14.9231 0.351924 15.0002 0.538243 15.0002 0.732518C15.0002 0.926794 14.9231 1.11311 14.7857 1.25049L1.25053 14.7856C1.18257 14.8537 1.10183 14.9077 1.01294 14.9445C0.924049 14.9813 0.828765 15.0002 0.732559 15.0001Z" fill="black" fillOpacity="0.3" />
                <path d="M14.2677 15.0001C14.1715 15.0002 14.0762 14.9813 13.9873 14.9445C13.8984 14.9077 13.8177 14.8537 13.7497 14.7856L0.21455 1.25049C0.0771758 1.11311 0 0.926794 0 0.732518C0 0.538243 0.0771758 0.351924 0.21455 0.21455C0.351924 0.0771758 0.538242 0 0.732518 0C0.926794 0 1.11311 0.0771758 1.25049 0.21455L14.7856 13.7497C14.8881 13.8521 14.9579 13.9827 14.9862 14.1247C15.0144 14.2668 14.9999 14.4141 14.9445 14.548C14.889 14.6818 14.7951 14.7962 14.6747 14.8767C14.5542 14.9572 14.4126 15.0001 14.2677 15.0001Z" fill="black" fillOpacity="0.3" />
              </g>
              <defs>
                <clipPath id="clip0_1355_58424">
                  <rect width="15" height="15" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        ) : ''}
        <input
          id="searchright"
          autoComplete="off"
          className={`search expand-left ${expandSearchInput ? 'show' : ''} ${searchKeyword ? 'search-keyword' : ''}`}
          placeholder="Search by Job/WO ID or Address"
          onFocus={() => setShowSearchInputField(true)}
          onChange={(e) => {
            setShowSearchInputField(true);
            debouncedChangeHandler(e?.target?.value);
          }}
        />
        <label
          htmlFor="searchright"
          onClick={() => setExpandSearchInput(true)}
          className="secondary-icon-button"
        >
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0606 19.1619L14.8969 14.0808C16.2491 12.6116 17.0799 10.6687 17.0799 8.53069C17.0793 3.9571 13.3121 0.25 8.66464 0.25C4.01721 0.25 0.25 3.9571 0.25 8.53069C0.25 13.1043 4.01721 16.8114 8.66464 16.8114C10.6727 16.8114 12.5144 16.1168 13.961 14.9622L19.1448 20.0634C19.3974 20.3122 19.8075 20.3122 20.06 20.0634C20.3132 19.8146 20.3132 19.4107 20.0606 19.1619ZM8.66464 15.5373C4.7324 15.5373 1.54471 12.4004 1.54471 8.53069C1.54471 4.66101 4.7324 1.52403 8.66464 1.52403C12.5969 1.52403 15.7846 4.66101 15.7846 8.53069C15.7846 12.4004 12.5969 15.5373 8.66464 15.5373Z" fill="#283A5D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 8.53069C0 3.81526 3.88293 0 8.66464 0C13.4464 0 17.3293 3.81526 17.3299 8.53065C17.3299 10.6486 16.5389 12.5801 15.2422 14.0698L20.236 18.9837C20.5879 19.3304 20.5881 19.8947 20.2355 20.2415C19.8856 20.5862 19.3193 20.5862 18.9694 20.2415L13.9416 15.2939C12.4801 16.4008 10.651 17.0614 8.66464 17.0614C3.88293 17.0614 0 13.2461 0 8.53069ZM8.66464 0.5C4.15148 0.5 0.5 4.09894 0.5 8.53069C0.5 12.9624 4.15148 16.5614 8.66464 16.5614C10.6141 16.5614 12.4012 15.8873 13.805 14.7668L13.9783 14.6285L19.3202 19.8852C19.4754 20.0381 19.7293 20.0382 19.8846 19.8853C20.0381 19.7344 20.0383 19.4909 19.8852 19.34L14.5492 14.0894L14.7129 13.9115C16.0252 12.4857 16.8299 10.6023 16.8299 8.53072M8.66464 0.5C13.1778 0.5 16.8293 4.09896 16.8299 8.53072L8.66464 0.5ZM8.66464 1.77403C4.86668 1.77403 1.79471 4.80285 1.79471 8.53069C1.79471 12.2585 4.86668 15.2873 8.66464 15.2873C12.4626 15.2873 15.5346 12.2585 15.5346 8.53069C15.5346 4.80285 12.4626 1.77403 8.66464 1.77403ZM1.29471 8.53069C1.29471 4.51917 4.59813 1.27403 8.66464 1.27403C12.7312 1.27403 16.0346 4.51917 16.0346 8.53069C16.0346 12.5422 12.7312 15.7873 8.66464 15.7873C4.59813 15.7873 1.29471 12.5422 1.29471 8.53069Z" fill="#283A5D" />
          </svg>
        </label>
        {showSearchInputField && searchKeyword ? (
          <div className={`ls-search-result ${searchKeyword?.length < 3 || isEmpty(showSearchResult) ? 'atleast-three-keyword' : ''}`}>
            {renderComponent()}
          </div>
        ) : ''}
      </div>
    </React.Fragment>
  );
};

export default GlobalSearch;
