import React from 'react';

const EmergencyIcon = (props) => (
  <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.875977" y="0.358398" width="15.5396" height="15.5423" rx="5" fill="#FF5D5D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.58596 4.12988L9.31196 9.37885H8.00106L7.72706 4.12988H9.58596ZM9.33882 11.8851C9.17406 12.0481 8.94483 12.1296 8.65113 12.1296C8.35027 12.1296 8.11746 12.0499 7.9527 11.8905C7.78795 11.7311 7.70557 11.5064 7.70557 11.2163C7.70557 10.9154 7.78615 10.688 7.94733 10.5339C8.10851 10.3799 8.34311 10.3029 8.65113 10.3029C8.94841 10.3029 9.17854 10.3817 9.3415 10.5393C9.50447 10.6969 9.58596 10.9226 9.58596 11.2163C9.58596 11.4992 9.50358 11.7222 9.33882 11.8851Z" fill="white" />
  </svg>
);

export default EmergencyIcon;
