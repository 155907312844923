import React, { useState } from 'react';
import {
  Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import Button from 'reactstrap/lib/Button';

import FilterForm from 'components/shared/filter/components/filter-form';

const FilterModal = ({
  showFilterModal,
  toggle,
  filters,
  onFilter,
  defaultValues,
  currentFilters,
}) => {
  const [filter, setFilters] = useState(defaultValues);
  const [rawFilters, setRawFilter] = useState({});
  const [reset, setReset] = useState(false);
  return (
    <Modal
      isOpen={showFilterModal}
      toggle={toggle}
      centered
      className="global-modal crop-image-modal"
    >
      <ModalHeader toggle={toggle}>Filter Results</ModalHeader>
      <ModalBody className="filter-mobile">
        <div className="divider" />
        <div className="filter-data-inner">
          <FilterForm
            filters={filters}
            onFilter={(values, savedFilter, isReset) => {
              setFilters(values);
              setRawFilter(savedFilter);
              setReset(isReset);
            }}
            defaultValues={defaultValues}
            currentFilters={currentFilters}
          />
          <div className="filter-btn-outer">
            <Button color="secondary mr-md-4" onClick={toggle} type="button">Close</Button>
            <Button
              color="primary"
              onClick={() => {
                onFilter(filter, rawFilters, reset);
                toggle();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FilterModal;
