import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { isEmpty } from 'lodash';

import ImageSlider from 'components/shared/image-slider';
import Loader from 'components/shared/loader';

import { getSpecificJobDetails } from 'services/jobs';
import { generateRandomString } from 'helpers/utils';

const PausedNotesModal = ({ showPausedNotes, toggle, job }) => {
  const [pausedNotes, setPausedNotes] = useState();
  const [showImageSlider, setImageSlider] = useState(false);
  const [sliderImageArray, setSliderImageArray] = useState([]);
  const [isPauseNotesFetching, setIsPauseNotesFetching] = useState(false);

  useEffect(() => {
    setIsPauseNotesFetching(true);
    getSpecificJobDetails(job?.job_id, 'paused_note').then((res) => {
      setPausedNotes(res?.data);
      setIsPauseNotesFetching(false);
    }).catch(() => {
      setIsPauseNotesFetching(false);
    });
  }, [job]);

  const onImageClickHandler = (images, img) => {
    const imageArrayForSlider = images.filter((image) => image !== img);
    imageArrayForSlider.unshift(img);
    setSliderImageArray(imageArrayForSlider);
    setImageSlider(true);
  };

  return (
    <Modal
      isOpen={showPausedNotes}
      toggle={toggle}
      centered
      className="global-modal notes-modal paused-notes-modal"
    >
      <ModalHeader toggle={toggle}>
        Pause Notes
        &nbsp;
      </ModalHeader>
      <ModalBody>
        <div className="divider" />
        <div className="pause-note-data-container">
          {isPauseNotesFetching ? <Loader /> : ''}
          {!isPauseNotesFetching && isEmpty(pausedNotes?.pause_note) ? (
            <div className="no-data-found">
              No Data Found
            </div>
          ) : ''}

          {!isEmpty(pausedNotes) ? (
            <React.Fragment>
              {pausedNotes?.job_media ? (
                <div className="paused-notes-modal__image-wrap">
                  {(pausedNotes?.job_media || [])?.map((image) => (
                    <div className="paused-notes-modal__image" key={image?.job_media_id}>
                      <img
                        src={image?.job_media_thumbnail_url}
                        alt="job media"
                        key={job?.job_media_id}
                        width="70px"
                        height="70px"
                        onClick={() => onImageClickHandler(pausedNotes?.job_media, image)}
                      />
                    </div>
                  )) }
                </div>
              ) : ''}

              {(pausedNotes?.pause_note || [])?.map((pauseData, index) => (
                <React.Fragment key={generateRandomString()}>
                  <div className="pause-note-section">
                    <div className="notes-heading">
                      {pauseData?.label}
                      {' '}
                      :
                    </div>
                    <p className="notes-heading__title">{pauseData?.value}</p>
                  </div>
                  {Number((pausedNotes?.pause_note || [])?.length || 0) - 1 !== index ? <hr className="hr-md" /> : '' }
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : ''}
        </div>
        <div className="paused-notes-modal__button">
          <Button color="secondary" className="btn-block justify-content-center" onClick={toggle}>Close</Button>
        </div>
        {
          showImageSlider && (
            <ImageSlider
              showImageSlider={showImageSlider}
              toggle={() => setImageSlider(false)}
              imageArray={sliderImageArray}
            />
          )
        }
      </ModalBody>
    </Modal>
  );
};

export default PausedNotesModal;
