import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';

import {
  CalendarIconSmall,
  EnRouteIcon,
  EnRouteStoppedIcon,
  ExternalWorkOrderIcon,
  PausedIcon,
  PendingReviewIcon,
  ReachedIcon,
  StartedIcon2,
  ScheduledIcon,
  StoppedIcon,
  WalkThruIcon,
} from 'assets/images/svg-icons';
import JobDescriptionModal from 'components/shared/modals/job-description-modal';
import CustomerNotesModal from 'components/shared/modals/customer-notes-modal';
import { openInANewTab } from 'helpers/utils';

const MakeReadiesCard = ({ type, job, onJobNotesModalCloseHandler }) => {
  const [showViewScope, setShowViewScope] = useState(false);
  const [customerNotesModalData, setCustomerNotesModalData] = useState({});

  const mapIcon = {
    paused: <PausedIcon />,
    reached: <ReachedIcon />,
    stopped: <StoppedIcon />,
    started: <StartedIcon2 />,
    enrouted: <EnRouteIcon />,
    'to-be-billed': <PendingReviewIcon />,
    'enroute-stopped': <EnRouteStoppedIcon />,
    'go-back-in-progress': <StartedIcon2 />,
  };

  const getStatusIcon = (statusSlug) => {
    switch (type) {
      case 'scheduled':
        return <ScheduledIcon />;
      case 'walk-thru':
        return <WalkThruIcon />;
      case 'in_progress':
        return mapIcon[statusSlug] || '';
      default:
        return '';
    }
  };

  const onExternalSourceLinkClickHandler = () => {
    if (job?.external_job_source?.link) {
      openInANewTab(job?.external_job_source?.link);
    } else {
      setShowViewScope(true);
    }
  };

  return (
    <div className="jobs-card">
      <div className="jobs-card-top-section">
        <div className={`mrc-jobs-status ${type === 'walk-thru' ? type : (job?.status_slug || '')}`}>
          <i>
            {type === 'quote_in_progress'
              ? (
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.90625 4.06882V3.28125" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.5215 11.3119L5.4209 11.532L5.64102 10.4314C5.71361 10.0685 5.89204 9.73508 6.15379 9.47333L10.2052 5.42188L11.5311 6.74769L7.47963 10.7991C7.21788 11.0609 6.8845 11.2393 6.5215 11.3119Z" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.09375 0.46875H1.40625C0.888492 0.46875 0.46875 0.888492 0.46875 1.40625V10.5938C0.46875 11.1115 0.888492 11.5312 1.40625 11.5312H3.50925" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.90625 3.28125H7.03125C6.51349 3.28125 6.09375 2.86151 6.09375 2.34375V0.46875L8.90625 3.28125Z" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.34375 4.96875H7.03125" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.34375 6.84375H6.1313" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.34375 8.71875H4.35869" stroke="#444444" strokeWidth="0.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )
              : getStatusIcon(job?.status_slug)}

          </i>
          <p>{job?.status_label}</p>
        </div>
        <div className="wo-section">
          <div className="wo-section--left">
            <ExternalWorkOrderIcon />
            <div className="wo">
              {job?.external_job_source ? (
                <React.Fragment>

                  {job?.external_job_source?.label}
                  {' '}
                  <Link to="#" onClick={onExternalSourceLinkClickHandler}>
                    #
                    {job?.external_job_source?.reference_no}
                  </Link>
                </React.Fragment>
              ) : 'No Work Order Associated'}
            </div>
          </div>

          <div className="wo-section--right">
            <span className="job-id">
              #
              {job?.job_no}
            </span>
          </div>
        </div>
      </div>
      <div className="jobs-card-middle-section">
        <ul>
          <li>
            <strong>{job?.address}</strong>
          </li>
        </ul>
        {[
          { key: 'start_date', displayIn: ['scheduled', 'in_progress', 'walk-thru'], label: 'Start Date' },
          { key: 'complete_date', displayIn: ['scheduled', 'in_progress', 'walk-thru'], label: 'Anticipated Completion Date' },
          { key: 'delivery_date', displayIn: [], label: 'Delivery Date' },
        ]?.map((item) => (
          <React.Fragment key={item?.key}>
            {item?.displayIn?.includes(type) ? (
              <div className="data-row">
                <div className="head-data">
                  <CalendarIconSmall />
                  <p>
                    <strong>{item?.label}</strong>
                  </p>
                </div>
                <div className="sub-data">
                  <div className="date-range">{job?.[item?.key] || 'Not Available'}</div>
                </div>
              </div>
            ) : ''}
          </React.Fragment>
        ))}
      </div>
      <div className="view-scope">
        {type === 'quote_in_progress'
        && job?.status_slug === 'pending-approval'
        && job?.quote_review_url
          ? (
            <Button
              type="button"
              onClick={() => window.open(job?.quote_review_url, '_blank')}
              className="btn review-quote-btn"
            >
              Review Quote
            </Button>
          )
          : ''}
        <Button type="button" onClick={() => setShowViewScope(true)}>
          View Scope
        </Button>
        {job?.has_customer_notes ? (
          <Button
            className={`btn job-notes-btn ${job?.has_unread_customer_notes ? 'notification-dot' : ''}`}
            onClick={() => setCustomerNotesModalData({ data: job })}
          >
            Job Notes
          </Button>
        ) : ''}

      </div>
      {showViewScope ? (
        <JobDescriptionModal
          toggle={() => setShowViewScope(false)}
          jobId={job?.job_id}
          entity="make-ready"
        />
      ) : (
        ''
      )}
      {!isEmpty(customerNotesModalData)
        ? (
          <CustomerNotesModal
            toggle={() => setCustomerNotesModalData({})}
            hideNotificationDot={() => {
              if (job?.has_unread_customer_notes) {
                onJobNotesModalCloseHandler(type, job?.job_id);
              }
            }}
            jobId={job?.job_id}
          />
        ) : ''}
    </div>
  );
};

export default MakeReadiesCard;
