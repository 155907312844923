import React, { useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { PasswordIcon } from 'assets/images/svg-icons';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import FormGeneralAlert from 'components/shared/form-fields/form-general-alert';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import { defaultCustomerResetPassword } from 'services/auth';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(8, 'Must contain at least 8 letters')
    .matches(/^\S*$/, 'Password should not contain spaces'),
  password_confirmation: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

const ResetDefaultPassword = ({ defaultPropertyManagerId }) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors, setError, handleSubmit } = methods;
  const [successMessage, setSuccessMessage] = useState(
    'To make your account secure, please create a new a password to replace the temporary password you were given in the email.',
  );
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const onSubmit = (formData) => {
    const payload = {
      property_manager_id: defaultPropertyManagerId,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    };
    setIsLoading(true);
    defaultCustomerResetPassword(payload)
      .then((res) => {
        if (res?.data?.token) {
          localStorage?.setItem('userAccessToken', res?.data?.token);
          history.push('/');
        }
        setSuccessMessage(res?.data?.message);
        setIsLoading(false);
      })
      .catch((error) => {
        setSuccessMessage('');
        setIsLoading(false);
        catchHandler(error, setError);
      });
  };

  return (
    <div className="auth-card--content text-left">
      <h5>Login to</h5>
      <h4>
        <strong>Lula Customer</strong>
        {' '}
        Account
      </h4>
      {(errors || successMessage) && (
        <FormGeneralAlert errors={errors} successMessage={successMessage} />
      )}
      <FormProvider {...methods}>
        <Form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <i>
              <PasswordIcon />
            </i>
            <CustomInputField
              className="form-control"
              name="password"
              type="password"
              placeholder="Password"
              clearError
            />
          </FormGroup>
          <FormGroup>
            <i>
              <PasswordIcon />
            </i>
            <CustomInputField
              className="form-control"
              name="password_confirmation"
              type="password"
              placeholder="Confirm Password"
              clearError
            />
          </FormGroup>
          <div className="auth-btn-outer auth-btn-outer--forgot-password d-flex justify-content-center">
            <ButtonWithLoader
              isLoading={isLoading}
              color="primary"
              type="submit"
            >
              Change Password
            </ButtonWithLoader>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ResetDefaultPassword;
