import React from 'react';

const ReachedIcon = (props) => (
  <svg {...props} width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.4092 5.71534C9.4092 8.90052 5.31398 11.6307 5.31398 11.6307C5.31398 11.6307 1.21875 8.90052 1.21875 5.71534C1.21875 3.45361 3.05225 1.62012 5.31398 1.62012C7.57571 1.62012 9.4092 3.45361 9.4092 5.71534V5.71534Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="5.31374" cy="5.79763" r="1.30446" fill="white" />
  </svg>

);
export default ReachedIcon;
