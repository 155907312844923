import { request } from 'helpers/api';

export const listPropertyManager = (params) => (
  request({
    method: 'GET',
    url: '/api/property-managers',
    params,
  })
);

export const editPropertyManager = (consumerId, params) => (
  request({
    method: 'PUT',
    url: `/api/property-managers/${consumerId}`,
    params,
  })
);

export const deletePropertyManager = (consumerId) => (
  request({
    method: 'DELETE',
    url: `/api/property-managers/${consumerId}`,
  })
);

export const createPropertyManager = (params) => (
  request({
    method: 'POST',
    url: '/api/property-managers',
    params,
  })
);

export const getPropertyManagerDetails = (consumerId, params) => (
  request({
    method: 'GET',
    url: `/api/property-managers/${consumerId}`,
    params,
  })
);
