import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

import { isEmpty } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import {
  EmailIcon, PasswordIcon,
} from 'assets/images/svg-icons';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import { login } from 'services/auth';
import catchHandler from 'helpers/catch-handler';
import FormGeneralAlert from 'components/shared/form-fields/form-general-alert';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import ResetDefaultPassword from 'components/auth/reset-default-customer-password';

const Login = () => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      keepSignedIn: false,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPropertyManagerId, setDefaultPropertyManagerId] = useState();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const pmAuthToken = params.get('pm_auth_token');

  useEffect(() => {
    if (localStorage.getItem('userAccessToken')) {
      localStorage.clear();
    }
  }, []);

  const {
    errors, setError, formState,
  } = methods;

  const onSubmit = useCallback((formData) => {
    const payLoad = {
      ...(formData.email ? { email: formData.email } : {}),
      ...(formData.password ? { password: formData.password } : {}),
      ...(formData.keepSignedIn ? { remember: 1 } : {}),
      ...(formData.pm_auth_token ? { pm_auth_token: formData.pm_auth_token } : {}),
    };
    setIsLoading(true);
    login(payLoad).then((response) => {
      if (response?.data?.need_password_reset) {
        setDefaultPropertyManagerId(response?.data?.property_manager_id);
      } else if (response?.data?.token) {
        localStorage?.setItem('userAccessToken', response?.data?.token);
        history.push('/');
      }
      setIsLoading(false);
    }).catch((error) => {
      catchHandler(error, setError);
      setIsLoading(false);
    });
  }, [history, setError]);

  useEffect(() => {
    if (pmAuthToken) {
      onSubmit({ pm_auth_token: pmAuthToken });
    }
  }, [pmAuthToken, onSubmit]);

  return (
    <React.Fragment>
      {!isEmpty(defaultPropertyManagerId)
        ? (
          <ResetDefaultPassword
            defaultPropertyManagerId={defaultPropertyManagerId}
          />
        ) : (
          <div className="auth-card--content">
            <h5>Login to</h5>
            <h4>
              <strong>Lula Customer</strong>
              {' '}
              Account
            </h4>
            {errors
          && <FormGeneralAlert errors={errors} />}
            <FormProvider {...methods}>
              <Form className="auth-form" onSubmit={methods.handleSubmit(onSubmit)}>
                <FormGroup>
                  <i>
                    <EmailIcon />
                  </i>
                  <CustomInputField className="form-control" name="email" placeholder="Email Address" clearError />
                </FormGroup>
                <FormGroup>
                  <i>
                    <PasswordIcon />
                  </i>
                  <CustomInputField className="form-control" name="password" type="password" placeholder="Password" clearError />
                  <Link className="forgot-password" to="/auth/forgot-password">Forgot?</Link>
                </FormGroup>
                <div className="keep-me">
                  <Label for="keepMeSignedInCheckbox">Keep me signed in</Label>
                  <CustomInputField name="keepSignedIn" type="checkbox" id="keepMeSignedInCheckbox" label="Keep me signed in" />
                </div>
                <div className="auth-btn-outer d-flex justify-content-center ">
                  <ButtonWithLoader
                    isLoading={isLoading}
                    color="primary"
                    disabled={!formState.isDirty}
                  >
                    Sign In
                  </ButtonWithLoader>
                </div>
              </Form>
            </FormProvider>
          </div>
        )}
    </React.Fragment>

  );
};

export default Login;
