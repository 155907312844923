import React from 'react';

const UploadIcon = (props) => (
  <svg {...props} width="47" height="37" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M37.4444 15.5658C37.4461 15.5148 37.4529 15.4655 37.4529 15.4145C37.4529 7.90385 31.3635 1.81445 23.8529 1.81445C17.3691 1.81445 11.9563 6.35515 10.5929 12.4259C5.50653 13.7791 1.75293 18.4014 1.75293 23.9145C1.75293 30.4867 7.08073 35.8145 13.6529 35.8145H35.7529C41.3867 35.8145 45.9529 31.2483 45.9529 25.6145C45.9529 20.5587 42.2707 16.3733 37.4444 15.5658Z" stroke="url(#paint0_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.8529 12.0146V29.0146" stroke="url(#paint1_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.0527 18.8146L23.8527 12.0146L30.6527 18.8146" stroke="url(#paint2_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear" x1="45.9529" y1="1.81445" x2="1.75293" y2="1.81445" gradientUnits="userSpaceOnUse">
        <stop stopColor="#72B3FF" />
        <stop offset="1" stopColor="#3E7CFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="24.7029" y1="12.0146" x2="23.0029" y2="12.0146" gradientUnits="userSpaceOnUse">
        <stop stopColor="#72B3FF" />
        <stop offset="1" stopColor="#3E7CFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="30.6527" y1="12.0146" x2="17.0527" y2="12.0146" gradientUnits="userSpaceOnUse">
        <stop stopColor="#72B3FF" />
        <stop offset="1" stopColor="#3E7CFF" />
      </linearGradient>
    </defs>
  </svg>
);
export default UploadIcon;
