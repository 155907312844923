import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import ImageCrop from 'components/shared/form-fields/image-crop';
import { deleteAttachment } from 'services/attachment';

const ImageCropModal = ({
  showImageCropModal, toggle, profilePicture, attachmentId, profileUpdate, oldProfilePicUrl,
}) => {
  const [uploadedImg, setUploadedImg] = useState();
  const [fileName, setFileName] = useState();
  const methods = useForm({});

  useEffect(() => {
    if (isArray(profilePicture)) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUploadedImg(reader.result));
      reader.readAsDataURL(profilePicture[0]);
      setFileName(profilePicture[0].name);
    }
  }, [profilePicture]);

  const onSubmit = (data) => {
    if (attachmentId) {
      const payload = {
        attachment: {
          attachment_id: attachmentId,
          resize_options: {
            height: data?.cropedImage?.height,
            width: data?.cropedImage?.width,
            x: data?.cropedImage?.x,
            y: data?.cropedImage?.y,
          },
        },
      };
      profileUpdate(payload);
    }
  };
  const cancelUpdate = () => {
    toggle();
    deleteAttachment(attachmentId);
    profileUpdate(oldProfilePicUrl);
  };

  return (
    <Modal
      isOpen={showImageCropModal}
      toggle={toggle}
      centered
      className="global-modal crop-image-modal"
    >
      <ModalHeader toggle={toggle}>Crop Profile Picture</ModalHeader>
      <ModalBody>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <div className="divider" />
            <h5>Please use below tool to crop profile picture properly.</h5>
            <div className="crop-area">
              <ImageCrop name="cropedImage" uploadedImg={uploadedImg} fileName={fileName} />
            </div>
            <div className="button-outer-wrap">
              <Button color="secondary mr-md-4" onClick={cancelUpdate}>Cancel</Button>
              <Button color="primary" type="submit">Crop & Upload Image</Button>
            </div>
          </FormProvider>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default ImageCropModal;
