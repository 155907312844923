import React from 'react';

const OpenJobsIcon = (props) => (
  <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0116 0.353725C12.7681 0.297954 13.5284 0.307011 14.2863 0.339425C16.6606 0.531049 18.9706 1.43768 20.8473 2.90489C22.5123 4.19525 23.8398 5.917 24.6626 7.85612C25.1636 9.0316 25.4777 10.2862 25.5969 11.558C25.6093 11.9832 25.6917 12.4098 25.6364 12.8345C25.564 13.1477 25.1888 13.3484 24.8947 13.2025C24.6593 13.1119 24.5472 12.8488 24.5668 12.6095C24.562 9.6713 23.3417 6.75547 21.2501 4.69004C19.6565 3.08269 17.5635 1.97728 15.3374 1.56877C13.2019 1.16217 10.9491 1.38906 8.93898 2.21991C6.83064 3.08412 4.99783 4.60233 3.75847 6.51428C3.13975 7.45857 2.66784 8.49725 2.34942 9.5793C2.29222 9.83956 2.20594 10.1475 1.92613 10.2462C1.76788 10.2624 1.58388 10.2895 1.4485 10.1861C1.27261 10.0674 1.16154 9.84052 1.21398 9.62887C1.68255 7.8504 2.53199 6.16916 3.717 4.75963C5.76289 2.27997 8.81028 0.657368 12.0116 0.353725Z" fill="#949DAE" />
    <path d="M0.835972 12.6564C0.895556 12.4652 1.06811 12.3284 1.22828 12.2207C1.38177 12.2312 1.53382 12.2569 1.6835 12.2917C1.76215 12.4424 1.86511 12.5849 1.90658 12.7508C1.9104 13.5735 2.01336 14.3924 2.18639 15.1966C2.2312 15.4111 2.33512 15.6218 2.30127 15.8453C2.22453 15.9826 2.13253 16.1109 2.04244 16.2391C1.88466 16.25 1.7264 16.2596 1.56815 16.2643C1.44421 16.1075 1.26355 15.9783 1.21731 15.7743C1.05334 15.1094 0.929877 14.4334 0.875059 13.7508C0.870769 13.3852 0.787351 13.022 0.835972 12.6564Z" fill="#949DAE" />
    <path d="M2.95539 18.1047C3.07551 18.0713 3.16846 18.1838 3.2657 18.2353C3.47734 18.3531 3.54789 18.5962 3.68184 18.7816C4.03935 19.3465 4.45119 19.8751 4.89736 20.3723C5.03512 20.5286 5.2134 20.6583 5.29729 20.8551C5.29777 21.0134 5.3135 21.1802 5.24915 21.3294C5.13284 21.4267 5.00414 21.5396 4.85017 21.5611C4.65044 21.5306 4.43213 21.47 4.29866 21.3065C3.60605 20.5825 2.99352 19.7778 2.49968 18.9051C2.35716 18.7058 2.48395 18.4679 2.55975 18.2701C2.66032 18.1662 2.82239 18.15 2.95539 18.1047Z" fill="#949DAE" />
    <path d="M21.3212 20.6965C21.5362 20.5721 21.8198 20.656 21.969 20.8448C22.1544 21.0602 22.1158 21.4201 21.8932 21.5965C21.6649 21.7891 21.4737 22.0894 21.1505 22.1142C20.7344 22.1304 20.4212 21.5932 20.6982 21.2585C20.8974 21.0641 21.08 20.84 21.3212 20.6965Z" fill="#949DAE" />
    <path d="M7.1094 22.4131C7.25526 22.3798 7.40779 22.4141 7.53411 22.4904C8.11899 22.8307 8.73486 23.1158 9.36884 23.3512C9.57905 23.4223 9.7416 23.6315 9.73206 23.857C9.75733 24.2102 9.34691 24.5105 9.01943 24.3689C8.30728 24.1139 7.61848 23.7922 6.96639 23.408C6.77858 23.3026 6.65608 23.0781 6.68754 22.8626C6.73616 22.6548 6.89203 22.4556 7.1094 22.4131Z" fill="#949DAE" />
    <path d="M18.0015 22.9827C18.2303 22.9017 18.4472 23.0227 18.6498 23.1152C18.6898 23.2778 18.7651 23.4432 18.7456 23.6124C18.6169 23.7783 18.4867 23.9689 18.2799 24.0409C17.9243 24.1901 17.5696 24.3498 17.193 24.4366C17.0334 24.3784 16.8756 24.3045 16.7397 24.203C16.6716 24.0476 16.6916 23.8727 16.6787 23.7077C16.8155 23.5814 16.919 23.3998 17.1072 23.3431C17.4128 23.243 17.7064 23.1095 18.0015 22.9827Z" fill="#949DAE" />
    <path d="M12.2351 24.1853C12.3743 24.12 12.5173 24.0523 12.6699 24.0266C13.0183 24.0214 13.3677 24.0409 13.7162 24.0252C13.9655 23.9885 14.1042 24.2349 14.2615 24.3812C14.2558 24.5409 14.2195 24.6973 14.2005 24.8555C14.0456 24.9504 13.8911 25.0519 13.7166 25.1086C13.5255 25.1239 13.3334 25.1139 13.1418 25.1148C12.8891 25.1086 12.6284 25.1387 12.3862 25.0491C12.2537 24.9461 12.1746 24.7912 12.0659 24.6658C12.1188 24.5066 12.1145 24.3107 12.2351 24.1853Z" fill="#949DAE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.87613 11.4689C8.75979 11.4658 8.64288 11.4628 8.52886 11.4857C8.19587 11.5443 7.88229 11.7051 7.64317 11.9443C7.33807 12.2484 7.15206 12.6757 7.162 13.1089C7.15376 13.4785 7.28787 13.8452 7.51802 14.1325C7.78819 14.4767 8.20218 14.7 8.63605 14.7471C8.75028 14.7512 8.86475 14.7515 8.97898 14.7469C9.37865 14.703 9.76014 14.508 10.0279 14.2077C10.2944 13.9089 10.4564 13.5117 10.4475 13.1091C10.4567 12.7055 10.2942 12.3078 10.0269 12.0085C9.75966 11.7083 9.37817 11.5145 8.97898 11.4709C8.9448 11.4706 8.91049 11.4698 8.87613 11.4689ZM8.12724 12.7344C8.24399 12.5217 8.46607 12.37 8.70776 12.3434C8.89985 12.3182 9.1008 12.3697 9.25743 12.484C9.41833 12.5986 9.52843 12.7794 9.56252 12.9734C9.5707 13.1113 9.57837 13.2546 9.52366 13.3848C9.43128 13.6374 9.19539 13.8295 8.92967 13.8697C8.69685 13.9104 8.4463 13.8356 8.27552 13.672C8.15792 13.5645 8.07713 13.4186 8.0473 13.2621C8.0035 13.0847 8.04287 12.8938 8.12724 12.7344Z" fill="#949DAE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5279 12.1432C14.2558 11.7615 13.8113 11.5085 13.3444 11.4695C13.3088 11.4696 13.2731 11.4689 13.2373 11.4681C13.1304 11.4659 13.023 11.4637 12.9185 11.4862C12.4895 11.5606 12.0935 11.8107 11.8468 12.1701C11.5645 12.5669 11.4804 13.0927 11.615 13.559C11.7362 13.9963 12.0513 14.3734 12.4565 14.5767C12.9345 14.8231 13.5353 14.8112 14.0021 14.5429C14.4105 14.3155 14.7132 13.9058 14.8066 13.4475C14.8442 13.3097 14.8406 13.1663 14.837 13.0244C14.8363 12.9953 14.8356 12.9662 14.8352 12.9372C14.8022 12.6525 14.6991 12.3741 14.5279 12.1432ZM12.5754 12.655C12.6976 12.4854 12.8929 12.3709 13.1004 12.3463C13.2931 12.3202 13.4939 12.373 13.651 12.4869C13.8203 12.6093 13.9352 12.8046 13.9593 13.012C13.9849 13.2007 13.9349 13.3971 13.8255 13.5525C13.7041 13.7273 13.5053 13.846 13.2939 13.8708C13.1014 13.8973 12.9005 13.8442 12.7437 13.7301C12.5739 13.6082 12.4591 13.4129 12.4348 13.2054C12.4087 13.0127 12.4611 12.8119 12.5754 12.655Z" fill="#949DAE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5395 11.4678C17.4542 11.4681 17.3683 11.4683 17.2856 11.4918C17.047 11.5377 16.8183 11.6349 16.6223 11.7792C16.2035 12.0807 15.9358 12.5905 15.9469 13.1087C15.9387 13.4643 16.0643 13.8171 16.2777 14.0997C16.5469 14.4605 16.9711 14.6975 17.4176 14.7467C17.6147 14.7533 17.8158 14.7593 18.0069 14.6999C18.5109 14.5726 18.9394 14.187 19.124 13.7014C19.2336 13.439 19.2498 13.147 19.2178 12.8672C19.163 12.4857 18.9644 12.1289 18.6767 11.8736C18.3784 11.6138 17.9848 11.4542 17.5869 11.4676C17.5711 11.4678 17.5553 11.4678 17.5395 11.4678ZM16.86 12.8595C16.9481 12.5846 17.2056 12.375 17.4922 12.3426C17.7291 12.3105 17.9809 12.3948 18.145 12.5699C18.2528 12.6764 18.3233 12.8182 18.3501 12.9669C18.3885 13.1249 18.3544 13.2917 18.291 13.4386C18.1852 13.6654 17.963 13.835 17.7145 13.8711C17.5133 13.9054 17.2997 13.8538 17.1355 13.733C16.9746 13.6188 16.8647 13.4379 16.8307 13.2442C16.8302 13.2328 16.8296 13.2213 16.829 13.2098C16.8233 13.0923 16.8173 12.9712 16.86 12.8595Z" fill="#949DAE" />
  </svg>
);
export default OpenJobsIcon;
