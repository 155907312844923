import Dashboard from 'components/dashboard/dashboard';
import OpenJobs from 'components/open-jobs/open-jobs';
import ClosedJobs from 'components/closed-jobs/closed-jobs';
import Invoices from 'components/invoices/invoices';
import Notifications from 'components/notifications/notifications';
import PropertyManagers from 'components/property-managers/property-managers';
import MyProfile from 'components/my-profile/my-profile';
import Help from 'components/help/help';
import CompanyProfile from 'components/my-profile/company-profile';
import Quotes from 'components/quotes/quotes';
import CustomerServiceAgreement from 'components/customer-service-agreement/customer-service-agreement';
import OpenMakeReadies from 'components/open-make-readies/open-make-readies';
import ClosedMakeReadies from 'components/closed-make-readies/closed-make-readies';

const portalRoutes = [
  {
    path: '/customer-service-agreement',
    component: CustomerServiceAgreement,
    exact: true,
    key: 'cla',
  },
  {
    path: '/quotes',
    component: Quotes,
    exact: true,
    key: 'quotes',
  },
  {
    path: '/help',
    component: Help,
    exact: true,
    key: 'help',
  },
  {
    path: '/my-profile',
    component: MyProfile,
    exact: true,
    key: 'my-profile',
  },
  {
    path: '/company-profile',
    component: CompanyProfile,
    exact: true,
    key: 'company-profile',
  },
  {
    path: '/property-managers',
    component: PropertyManagers,
    exact: true,
    key: 'property-managers',
  },
  {
    path: '/notifications',
    component: Notifications,
    exact: true,
    key: 'notifications',
  },
  {
    path: '/closed-jobs',
    component: ClosedJobs,
    exact: true,
    key: 'closed-jobs',
  },
  {
    path: '/invoices',
    component: Invoices,
    exact: true,
    key: 'invoices',
  },
  {
    path: '/open-jobs',
    component: OpenJobs,
    exact: true,
    key: 'open-jobs',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    key: 'dashboard',
  },
  {
    path: '/open-make-readies',
    component: OpenMakeReadies,
    exact: true,
    key: 'open-make-readies',
  },
  {
    path: '/closed-make-readies',
    component: ClosedMakeReadies,
    exact: true,
    key: 'closed-make-readies',
  },
  {
    redirect: true,
    path: '/',
    to: '/dashboard',
    navbarName: 'Redirect',
    key: 'redirect',
  },
];

export default portalRoutes;
