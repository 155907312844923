import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import { Controller, useFormContext } from 'react-hook-form';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import Input from 'reactstrap/lib/Input';
import Moment from 'moment';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import useOutsideClick from 'hooks/use-outside-click';

const DateDisplay = ({
  onChange,
  value,
  name,
  disabled,
  theme, // Pass the prop theme='light' for calendar in light theme
  // The default theme will be 'dark' if 'theme' prop is not passed into the component
  propName,
  placeholder,
  clearError,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateDisplay, setDateDisplay] = useState('');
  const { errors, clearErrors } = useFormContext();
  const ref = useRef();
  useOutsideClick(ref, () => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
  });

  useEffect(() => {
    if (value) {
      const selectedDate = Moment(value).format('MM/DD/YYYY');
      setDateDisplay(selectedDate);
      setShowDatePicker(false);
    }
  }, [value]);

  return (
    <div ref={ref} className={theme ? [theme] : 'dark'}>
      <div>
        <Input
          name={`${name}DateDisplay`}
          className={!!errors && !!errors[name] ? 'is-invalid' : ''}
          placeholder={placeholder || 'Please select a Date'}
          onClick={() => setShowDatePicker(true)}
          defaultValue={dateDisplay}
          autoComplete="off"
          disabled={disabled}
          readOnly
        />
        {errors && errors[propName] && errors[propName].message && (
        <FormFeedback>{errors && errors[propName].message}</FormFeedback>
        )}
      </div>
      {showDatePicker && (
        <div className="date-picker">
          <Calendar
            name={name}
            date={value}
            onChange={(date) => {
              onChange(date);
              if (clearError) {
                clearErrors();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

const DatePicker = ({
  name: propName, disabled, theme, placeholder, clearError,
}) => {
  const { control } = useFormContext();
  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propName}
        render={(props) => (
          <DateDisplay
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            disabled={disabled}
            theme={theme}
            propName={propName}
            placeholder={placeholder}
            clearError={clearError}
          />
        )}
      />
    </React.Fragment>
  );
};

export default DatePicker;
