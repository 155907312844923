import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

const SelectField = (props) => {
  const { control, errors } = useFormContext();
  const {
    name: propsName,
    options,
    placeholder,
    ...otherProps
  } = props;

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        render={({
          onChange,
          onBlur,
          name,
          value,
        }) => (
          <React.Fragment>
            <Select
              {...otherProps}
              closeMenuOnSelect
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              options={options}
              placeholder={placeholder || ''}
              value={value || placeholder}
            />
            {errors && errors[propsName] && errors[propsName].message ? (
              <small style={{ color: 'red' }}>{errors[propsName].message}</small>
            ) : ''}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default SelectField;
