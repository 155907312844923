import { request } from 'helpers/api';

export const uploadAttachment = (params) => (
  request({
    method: 'POST',
    url: '/api/attachments',
    params,
  })
);

export const deleteAttachment = (attachmentId) => (
  request({
    method: 'DELETE',
    url: `/api/attachments/${attachmentId}`,
  })
);
