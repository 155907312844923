import React from 'react';

const StartedIcon = (props) => (
  <svg {...props} width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M4.13056 9.27551C6.53687 9.27551 8.29872 7.04167 8.29872 4.53766C8.29872 2.03364 6.53687 -0.200195 4.13056 -0.200195C1.72425 -0.200195 -0.0375977 2.03364 -0.0375977 4.53766C-0.0375977 7.04167 1.72425 9.27551 4.13056 9.27551Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="7" height="8" fill="white" transform="translate(0.712402 0.549805)" />
      </clipPath>
    </defs>
  </svg>
);
export default StartedIcon;
