import React from 'react';

const StatusPaymentSkipped = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <rect width="18" height="18" fill="#283A5D" rx="6" />
      <path fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".5" d="M6.62460933.0128306389C6.77351937-.0183670614 6.9444513.00339267914 7.05089052.121891507 7.1575919.206833145 7.1880031.34735388 7.20189787.475814999 7.20216004 2.78392049 7.20242221 5.09228816 7.20189787 7.40039365 7.1872166 7.52203847 7.16257304 7.65705372 7.06426096 7.7409467 6.90669947 7.92288959 6.58869265 7.92341392 6.43139332 7.74068453 6.33334341 7.65679156 6.30791335 7.52203847 6.29349425 7.40039365 6.29375641 5.09255032 6.29296991 2.78444483 6.29401858.476601496 6.29742673.272112367 6.42352836.072080053 6.62460933.0128306389zM.656169851.261887911C.806915042.245895812.96840902.258479759 1.09896746.342372734 2.68978802 1.33702883 4.2811329 2.33089843 5.87221562 3.32555452 6.04760437 3.43409106 6.18366829 3.62101509 6.1896981 3.83232053 6.20385504 4.10025372 6.10213481 4.39597646 5.87195345 4.5501298 4.28637621 5.54190207 2.70001247 6.53262568 1.11417307 7.52413579.928559857 7.6381778.685532392 7.69087308.482091926 7.59203667.281535281 7.49424892.110603343 7.32226832.0319536782 7.11174938-.0102549752 7.01160214.00232897113 6.90123045.000493812289 6.79557773.000755977838 4.85555266.00128030894 2.91578977.000493812289.975764701-.00448733315.847303582.0285455261.719104629.0846489536.604276118.194758484.396378837.433329134.293347777.656169851.261887911M.869572608 1.23950324C.866164456 3.03848324.866164456 4.83746324.869572608 6.63618108.910470434 6.613897.952154756 6.59344809.992266085 6.56959103 2.31069663 5.74481821 3.62938935 4.92083189 4.94808206 4.09632123 5.03066421 4.04703411 5.11298419 3.99669833 5.18979869 3.93797324 4.88358933 3.72850297 4.56165004 3.54288976 4.24941087 3.34259528 3.2400735 2.71130064 2.22994964 2.08053033 1.22061228 1.44923568 1.10421077 1.37818882.992528251 1.29927699.869572608 1.23950324z" transform="translate(5.399 5.061)" />
    </g>
  </svg>
);

export default StatusPaymentSkipped;
