import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';

import TooltipWrapper from 'components/shared/TooltipWrapper';
import { generateRandomString } from '../../../helpers/utils';

const JobStatus = (props) => {
  const {
    value,
    label,
    setPausedNotes,
    mapIcon,
    onMouseEnterHandle,
    onMouseLeaveHandle,
    showTooltip,
    quoteReviewUrl,
    jobIssues,
    jobId,
  } = props;

  const formatLabel = () => {
    if (value === 'to-be-billed'
    || value === 'kick-back-to-pro') {
      const splitStr = label.split('-');
      return (
        <div className="billed-msg-inner">
          {splitStr?.map((item) => (
            <div key={generateRandomString()}>{item}</div>
          ))}
        </div>
      );
    }
    if (['submitted-for-customer-approval', 'quote-submitted', 'waiting', 'pending']?.includes(value)) {
      return label;
    }
    return label;
  };

  return (
    <div className={`jobs-status ${value}`}>
      <div className="billed-msg-outer">
        {mapIcon?.[value]}
        {formatLabel()}
      </div>
      {value === 'paused' ? (
        <React.Fragment>
          <Link
            onMouseEnter={onMouseEnterHandle}
            onMouseLeave={onMouseLeaveHandle}
            to="#"
            data-tip="Click for more info."
            onClick={() => setPausedNotes(true)}
          >
            View Notes
          </Link>
          {showTooltip ? <ReactTooltip className="global-tooltip" arrowColor="#283a5d" /> : ''}
        </React.Fragment>
      ) : (
        ''
      )}
      {!isEmpty(jobIssues) ? (
        <TooltipWrapper renderTooltip={(isTooltipOpen, setIsTooltipOpen) => (
          <React.Fragment>
            <span
              className="view-job-issues"
              data-for={`job-closed-${jobId}`}
              data-tip
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            >
              View
            </span>
            {isTooltipOpen
              ? (
                <ReactTooltip
                  id={`job-closed-${jobId}`}
                  className="job-closed-label-tooltip"
                  arrowColor="#fff"
                >
                  <React.Fragment>
                    <h4>Issues Under Investigation:</h4>
                    <div className="job-issues-banner">
                      Lula is taking care of these issues.
                      No action required.
                    </div>
                    {(jobIssues || [])?.map((issues) => (
                      <div className="job-issues" key={generateRandomString()}>
                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1" y="0.5" width="5" height="5" rx="1" stroke="#283A5D" />
                        </svg>
                        <p>
                          {issues}
                        </p>
                      </div>
                    ))}
                  </React.Fragment>
                </ReactTooltip>
              ) : '' }
          </React.Fragment>
        )}
        />
      ) : ''}
      {value === 'submitted-for-customer-approval' && quoteReviewUrl
        ? (
          <a href={`${quoteReviewUrl}`} target="_blank" rel="noreferrer">
            Review
          </a>
        )
        : ''}
    </div>
  );
};

export default JobStatus;
