import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCrop = ({ name: propName, uploadedImg }) => {
  const { control, setValue } = useFormContext();
  const [crop, setCrop] = useState({});
  const [image, setImage] = useState();

  const onLoad = useCallback((img) => {
    setImage(img);
    const aspect = 16 / 16;
    const width = img.width / aspect < img.height * aspect ? 100
      : ((img.height * aspect) / img.width) * 100;
    const height = img.width / aspect > img.height * aspect ? 100
      : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;
    setCrop({
      unit: '%',
      width,
      height,
      x,
      y,
      aspect,
    });
    setValue(propName, {
      x: (img.naturalWidth / 100) * x,
      y: (img.naturalHeight / 100) * y,
      width: ((img.naturalWidth / 100) * width),
      height: ((img.naturalHeight / 100) * height),
    });
    return false;
  }, [propName, setValue]);

  return (
    <Controller
      control={control}
      name={propName}
      render={({ onChange }) => (
        <ReactCrop
          src={uploadedImg}
          crop={crop}
          onChange={(c) => {
            setCrop(c);
          }}
          onComplete={(c) => {
            onChange({
              x: (image.naturalWidth / image.width) * c.x,
              y: (image.naturalHeight / image.height) * c.y,
              width: c.width * (image.naturalWidth / image.width),
              height: c.height * (image.naturalHeight / image.height),
            });
          }}
          onImageLoaded={onLoad}
        />
      )}
    />
  );
};
export default ImageCrop;
