import React from 'react';

const LogoutIcon = (props) => (
  <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5833 18.6089C10.3702 18.646 10.1526 18.632 9.93955 18.668C9.36474 18.6939 8.79097 18.6529 8.22141 18.5791C6.47772 18.3321 4.81275 17.5603 3.49694 16.3904C1.65564 14.7797 0.500763 12.3965 0.426594 9.94746C0.398605 9.65043 0.396507 9.34745 0.429743 9.05077C0.543446 6.65425 1.68013 4.3333 3.4798 2.7509C4.87328 1.50786 6.6593 0.707386 8.51739 0.511816C8.7329 0.47578 8.95261 0.489775 9.16917 0.467733C9.55436 0.422253 9.9399 0.472633 10.3244 0.489426C11.6612 0.607327 12.9714 1.02505 14.1273 1.70693C15.1664 2.31673 16.0806 3.13609 16.8048 4.0982C16.8685 4.18601 16.943 4.27733 16.9524 4.38963C16.9755 4.62263 16.7548 4.84619 16.5214 4.82275C16.3741 4.81121 16.258 4.70345 16.1789 4.5866C14.8064 2.72851 12.6247 1.49247 10.3226 1.2857C8.57651 1.11322 6.77615 1.50996 5.26896 2.41294C4.25403 3.01469 3.37134 3.83686 2.69717 4.80456C1.30928 6.76866 0.85412 9.35025 1.47442 11.6712C1.79488 12.9163 2.41693 14.0821 3.26568 15.047C4.34954 16.2872 5.81054 17.1968 7.40694 17.6072C9.26363 18.0988 11.2897 17.9224 13.0288 17.1038C14.2386 16.5423 15.3074 15.6816 16.1173 14.6226C16.2044 14.5057 16.2888 14.3689 16.4364 14.3228C16.6788 14.2308 16.9591 14.4536 16.9538 14.7059C16.9573 14.8042 16.9031 14.8913 16.8482 14.9683C15.778 16.4223 14.2673 17.5488 12.5635 18.1488C11.9232 18.3741 11.2585 18.535 10.5833 18.6089Z" fill="#FF5D5D" stroke="#FF5D5D" strokeWidth="0.5" />
    <path d="M14.7385 13.0094C14.5828 13.1739 14.2788 13.1623 14.143 12.978C14.0556 12.8768 14.058 12.7338 14.0786 12.6092C14.115 12.4997 14.2049 12.4231 14.2837 12.3444C14.9897 11.6391 15.6946 10.9334 16.4006 10.2281C16.4857 10.1413 16.5759 10.0594 16.6529 9.96534C13.8044 9.95239 10.9551 9.96499 8.10624 9.95904C7.94635 9.95449 7.79067 9.84744 7.74029 9.6935C7.72699 9.577 7.7095 9.4409 7.78647 9.34119C7.86239 9.22504 8.00478 9.16172 8.14122 9.16522C10.0546 9.16522 11.968 9.16522 13.8813 9.16522C14.8049 9.16172 15.7286 9.17221 16.6522 9.15997C16.5962 9.0914 16.5343 9.02877 16.4706 8.9672C15.7177 8.2157 14.9669 7.46211 14.2133 6.71132C14.0209 6.56123 14.0066 6.22747 14.2091 6.08018C14.3082 5.98362 14.4568 5.99971 14.5831 6.01405C14.6982 6.05674 14.7763 6.1554 14.8609 6.23866C15.8346 7.21301 16.8082 8.18702 17.7826 9.16032C17.8574 9.23938 17.9519 9.30691 17.9935 9.41116C18.0632 9.54656 18.0226 9.71659 17.9222 9.8254C16.8604 10.8862 15.7992 11.9476 14.7385 13.0094Z" fill="#FF5D5D" stroke="#FF5D5D" strokeWidth="0.5" />
  </svg>

);
export default LogoutIcon;
