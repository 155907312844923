/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect, useState } from 'react';
import {
  Modal, ModalHeader, Form, FormGroup, Button, ModalBody, Col, Row,
} from 'reactstrap';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { createPropertyManager } from 'services/propertyManager';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import catchHandler from 'helpers/catch-handler';
import PhoneNumber from 'components/shared/form-fields/phone-number';

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
  phone_no: Yup.string()
    .required('Phone number is required'),
  isPasswordRequired: Yup.boolean(),
  email: Yup.string()
    .email('Not a valid email')
    .required('Email is required'),
  password: Yup.string().when('isPasswordRequired', { is: true, then: Yup.string().min(8).required('Password is required') }),
  confirmPassword: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string().oneOf([Yup.ref('password')],
      'Passwords must match').required('Confirm Password is required'),
  }),

});

const AddEditPropertyManagerModal = ({
  isOpen, toggle, editInfo, fetchPropertyManagerList, editPropertyManagerHandler,
}) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      firstName: editInfo?.first_name || '',
      lastName: editInfo?.last_name || '',
      phone_no: editInfo?.phone_no || '',
      email: editInfo?.email || '',
      isPasswordRequired: true,
    },
    resolver: yupResolver(schema),
  });
  const [isLoading, setLoading] = useState(false);
  const {
    register, setValue, formState, setError,
  } = methods;

  useEffect(() => {
    register({ name: 'isPasswordRequired' });
  }, [register]);
  const { password } = formState.dirtyFields;

  useEffect(() => {
    if (!isEmpty(editInfo) && !password) {
      setValue('isPasswordRequired', false);
    } else if (!isEmpty(editInfo) && password) {
      setValue('isPasswordRequired', true);
    }
  }, [editInfo, setValue, password]);

  const onSubmit = (formData) => {
    if (isEmpty(editInfo)) {
      const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.confirmPassword,
        phone_no: formData.phone_no,
        zip_code: '62345',
      };
      setLoading(true);
      createPropertyManager(payload).then(() => {
        setLoading(false);
        fetchPropertyManagerList();
        toggle();
      }).catch((error) => {
        setLoading(false);
        catchHandler(error, setError, true);
      });
    } else {
      const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.confirmPassword,
        phone_no: formData.phone_no,
        zip_code: '62345',
      };
      editPropertyManagerHandler(editInfo?.property_manager_id, payload, setLoading, setError);
    }
  };
  return (

    <Modal className="global-modal property-manager-modal" centered isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {editInfo ? 'Edit' : 'Add'}
        {' '}
        Property Manager
      </ModalHeader>
      <ModalBody>
        <FormProvider {...methods}>
          <div className="divider" />
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="form-contents">
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label htmlFor="firstName">First Name</label>
                    <CustomInputField className="form-control light" name="firstName" tabIndex="1" />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label htmlFor="lastName">Last Name</label>
                    <CustomInputField className="form-control light" name="lastName" tabIndex="2" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <PhoneNumber className="form-control light" name="phone_no" tabIndex="3" />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label htmlFor="email">Email</label>
                    <CustomInputField name="email" className="form-control light" tabIndex="4" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label htmlFor="newPassword">New Password</label>
                    <CustomInputField
                      className="form-control light"
                      name="password"
                      type="password"
                      tabIndex="5"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <CustomInputField
                      className="form-control light"
                      type="password"
                      name="confirmPassword"
                      tabIndex="6"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="button-outer-wrap">
                    <Button color="secondary align-self-end" onClick={toggle}>Close</Button>
                    <ButtonWithLoader
                      isLoading={isLoading}
                      color="primary"
                      type="submit"
                    >
                      {editInfo ? 'Save' : 'Create Account'}
                    </ButtonWithLoader>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AddEditPropertyManagerModal;
