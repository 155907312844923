import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ImageSlider = ({ showImageSlider, toggle, imageArray }) => (
  <Modal
    isOpen={showImageSlider}
    toggle={toggle}
    centered
    className="image-slider-modal"
  >
    <ModalHeader
      toggle={toggle}
    />
    <ModalBody>
      <div className="image-slider-data">
        <Carousel
          showIndicators={false}
          useKeyboardArrows
          showStatus={false}
          showArrows={false}
        >
          {
            imageArray.map((img) => (
              <div key={img.job_media_id || img.attachment_id}>
                <img
                  alt=""
                  src={img.url || img.job_media_url || img.attachment_url}
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    </ModalBody>

  </Modal>
);

export default ImageSlider;
