import React from 'react';

const EnRouteStoppedIcon = (props) => (
  <svg {...props} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.2124" y="1.52441" width="9.71343" height="10.1991" rx="2" stroke="white" strokeWidth="1.5" />
  </svg>

);

export default EnRouteStoppedIcon;
