import { request } from 'helpers/api';

const getFAQ = (params) => (
  request({
    method: 'GET',
    url: '/api/static-contents/faq',
    params,
  })
);

export default getFAQ;
