import React from 'react';
import {
  Switch, Redirect, Route, Link,
} from 'react-router-dom';
import { LogoIcon } from 'assets/images/svg-icons';

import authRoutes from 'routes/auth';

const renderComponents = (
  <Switch>
    {authRoutes.map((prop) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={prop.key} />;
      }
      return <Route path={prop.path} component={prop.component} key={prop.key} />;
    })}
  </Switch>
);

const Authentication = () => (
  <React.Fragment>
    <div className="auth-outer">
      <div className="auth-card">
        <div className="auth-card--inner">
          <div className="logo-wrap">
            <LogoIcon width="97" />
          </div>
          {renderComponents}
        </div>
        <Link to="#" className="auth-card--back_to_web" onClick={() => window.open('https://lula.life/')}>Back to Lula Website</Link>
      </div>
    </div>
  </React.Fragment>
);

export default Authentication;
