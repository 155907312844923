import React, {
  useState, useRef, useEffect, useCallback, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import CustomInput from 'reactstrap/lib/CustomInput';
import Button from 'reactstrap/lib/Button';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import Moment from 'moment';
import {
  isEqual, isObject, debounce, isEmpty,
} from 'lodash';

import {
  CalendarIcon,
  CloseIcon,
} from 'assets/images/svg-icons';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
} from 'date-fns';

import useOutsideClick from 'hooks/use-outside-click';

const Placeholder = (props) => <components.Placeholder {...props} />;

export const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLast30Days: startOfDay(addDays(new Date(), -29)),
  endOfLast30Days: endOfDay(new Date()),
};

const sideBarOptions = () => {
  const customRangeObjects = [
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: defineds.startOfLast30Days,
        endDate: defineds.endOfLast30Days,
        staticRange: true,
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        staticRange: true,
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
        staticRange: true,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        staticRange: true,
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
        staticRange: true,
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
        staticRange: true,
      }),
    },
  ];

  return customRangeObjects;
};

const staticRanges = [
  ...createStaticRanges(sideBarOptions()),
];

const FilterForm = forwardRef((props, ref) => {
  const {
    filters,
    onFilter,
    defaultValues,
    currentFilters,
    clearStateValues = null,
  } = props;

  const [showRangePicker, setShowRangePicker] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [initialCurrentFilters, setInitialCurrentFilters] = useState([]);
  const [beforeDebouncedChange, setBeforeDebouncedChange] = useState({});
  const [stepCount, setStepCount] = useState(0);
  const [dateRange, setDateRange] = useState();
  const [focusedRange, setFocusedRange] = useState([0, 0]);

  const dateRangeRef = useRef();
  useOutsideClick(dateRangeRef, () => {
    if (showRangePicker) {
      setShowRangePicker(false);
    }
  });

  const onFocusChange = (range) => {
    const [, rangeStep] = range;
    setFocusedRange(range);
    setStepCount(rangeStep);
    if (rangeStep === 0) {
      setShowRangePicker(false);
    }
  };

  const parseDefaultValues = (values) => {
    const object = {};
    Object.keys(values).map((key) => {
      if (isObject(values[key]) && values[key]?.type === 'date-range') {
        // construct default value
        object[key] = {
          startDate: Moment(values[key].startDate).toDate(),
          endDate: Moment(values[key].endDate).toDate(),
          key: 'selection',
        };
      } else {
        object[key] = values[key];
      }
      return true;
    });
    return object;
  };

  const parseAndSubmitFilter = useCallback((values, resetFilter) => {
    const object = {};
    if (filterOptions) {
      filterOptions.map((filter) => {
        if (filter.filter_type === 'select') {
          if (values[filter.name]?.value) {
            object[filter.name] = values[filter.name]?.value;
          }
        } else if (filter.filter_type === 'date-range') {
          if (values[filter.name]?.endDate && values[filter.name]?.startDate) {
            object[filter.from_name] = Moment(values[filter.name]?.startDate).format('YYYY-MM-DD');
            object[filter.to_name] = Moment(values[filter.name]?.endDate).format('YYYY-MM-DD');
          }
        } else if (values[filter.name]) {
          object[filter.name] = values[filter.name];
        }
        return false;
      });
    }
    if (onFilter) {
      onFilter(object, values, resetFilter?.reset);
    }
  }, [filterOptions, onFilter]);

  const onChange = (key, value) => {
    const newFilter = {
      ...filterValues,
      [key]: value,
    };
    setFilterValues(newFilter);
    parseAndSubmitFilter(newFilter);
  };

  const onResetHandler = () => {
    setBeforeDebouncedChange({});
    setFilterValues(parseDefaultValues(clearStateValues || defaultValues));
    parseAndSubmitFilter(parseDefaultValues(clearStateValues || defaultValues), { reset: true });
  };

  useEffect(() => {
    setFilterOptions(filters);
  }, [filterOptions, filters]);

  useEffect(() => {
    if (!isEqual(defaultValues, defaultFilters)) {
      setDefaultFilters(defaultValues);
      setFilterValues(parseDefaultValues(defaultValues));
      setBeforeDebouncedChange(parseDefaultValues(defaultValues));
    }
  }, [defaultFilters, defaultValues]);

  useEffect(() => {
    if (!isEqual(initialCurrentFilters, currentFilters) && currentFilters) {
      setInitialCurrentFilters(currentFilters);
      setFilterValues(currentFilters);
      setBeforeDebouncedChange(currentFilters);
    }
  }, [initialCurrentFilters, currentFilters]);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  const displayDateRange = (range) => {
    if (range && Moment(range?.endDate).format('YYYY-MM-DD') === Moment().format('YYYY-MM-DD')) {
      return `Last ${Moment(range?.endDate).diff(Moment(range?.startDate), 'days') + 1} days`;
    }

    return range ? `From: ${Moment(range?.startDate).format('MM/DD/YYYY')} to ${Moment(range?.endDate).format('MM/DD/YYYY')}` : '';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnchange = useCallback(debounce(onChange, 300), [filterValues]);

  useEffect(() => {
    if (dateRange) {
      if (stepCount === 0) {
        onChange(dateRange.name, dateRange.range);
        if (dateRange?.range?.staticRange) {
          setShowRangePicker(false);
        }
      } else {
        setFilterValues((prevFilter) => (
          {
            ...prevFilter,
            dateRange: dateRange.range,
          }
        ));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepCount, dateRange]);

  const renderFilter = (filter) => {
    switch (filter.filter_type) {
      case 'custom-input':
        return (
          <CustomInput
            autoComplete="off"
            name={filter.name}
            onChange={(event) => {
              if (filter.type === 'checkbox') {
                onChange(filter.name, event.target.checked);
              } else {
                setBeforeDebouncedChange({
                  ...beforeDebouncedChange,
                  [filter.name]: event.target.value,
                });
                debouncedOnchange(filter.name, event.target.value);
              }
            }}
            placeholder={filter.placeholder}
            type={filter.type}
            label={filter.label}
            id={filter.name}
            value={filter.type === 'checkbox' ? filterValues[filter.name] : (beforeDebouncedChange[filter.name] ?? '')}
            {...filter.type === 'checkbox' ? { checked: filterValues?.[filter?.name] || false } : {}}
            {...filter.inputProps}
          />
        );
      case 'select':
        return (
          <Select
            closeMenuOnSelect
            name={filter.name}
            onChange={(value) => onChange(filter.name, value)}
            options={filter.options}
            placeholder={filter.placeholder || ''}
            value={filterValues[filter.name] || filter.placeholder}
            components={{ Placeholder }}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: '#A6A6A6',
              }),
            }}
            {...filter.inputProps}
          />
        );
      case 'date-range':
        return (
          <div ref={dateRangeRef} className={`global-date-picker ${filter?.inputProps?.className || ''} ${!isEmpty(beforeDebouncedChange?.search_keyword) ? 'disabled' : ''}`}>
            <div className="dark">
              <CustomInput
                name={`${filter.name}DateDisplay`}
                placeholder={filter.placeholder || 'Date Not Specified'}
                onClick={() => setShowRangePicker(true)}
                value={displayDateRange(filterValues[filter.name])}
                autoComplete="off"
                className="form-control"
                id={`${filter.name}DateDisplay`}
                type="text"
                disabled={!isEmpty(beforeDebouncedChange?.search_keyword)}
                readOnly
              />
              {showRangePicker && (
                <div className="date-range-picker tl-date-range">
                  <DateRangePicker
                    color="#ff0000"
                    name={filter.name}
                    ranges={filterValues[filter.name]
                      ? [filterValues[filter.name]]
                      : [selectionRange]}
                    onChange={(range) => {
                      setDateRange({ name: filter.name, range: range && range.selection });
                    }}
                    onRangeFocusChange={onFocusChange}
                    focusedRange={focusedRange}
                    months={2}
                    direction="horizontal"
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    showMonthAndYearPickers={false}
                    monthDisplayFormat="MMMM yyyy"
                    // weekdayDisplayFormat="EEEEE"
                    staticRanges={staticRanges}
                    inputRanges={[]}
                  />
                </div>
              )}
            </div>
            <CalendarIcon />
          </div>
        );
      case 'switch':
        return (
          <div className={`global-switch ${filterValues[filter.name] ? 'active' : ''}`}>
            <CustomInput
              type="switch"
              id={filter?.name}
              name={filter?.name}
              label={filter?.label}
              checked={filterValues?.[filter?.name] || false}
              onChange={(event) => onChange(filter.name, event.target.checked)}
            />
          </div>
        );
      case 'clear':
        return (
          <Button ref={ref} className="btn-clear" type="button" onClick={() => onResetHandler()}>
            <i>
              <CloseIcon />
            </i>
            Clear
          </Button>
        );
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      { filterOptions && filterOptions.map((filter) => (
        <React.Fragment key={filter.name}>
          { renderFilter(filter) }
        </React.Fragment>
      ))}
    </React.Fragment>
  );
});

FilterForm.defaultProps = {
  filters: [],
  onFilter: null,
  defaultValues: {},
};

FilterForm.propTypes = {
  filters: PropTypes.instanceOf(Array),
  onFilter: PropTypes.func,
  defaultValues: PropTypes.shape(),
};

export default FilterForm;
