import React from 'react';

const PreferredVendorsIcon = (props) => (
  <svg {...props} width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.94663 3.04722H20.0205" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M1.40046 2.48347L2.26762 3.35063" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M4.21922 1.39928L2.26773 3.35077" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M1.40046 8.36628L2.26762 9.23345" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M4.21922 7.2821L2.26773 9.23358" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M1.40046 14.2481L2.26762 15.1153" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M4.21922 13.1639L2.26773 15.1154" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M6.94663 8.92906H20.0205" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M6.94663 14.8119H20.0205" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
  </svg>
);
export default PreferredVendorsIcon;
