import React from 'react';

const StatusCompletedIcon = (props) => (
  <svg {...props} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.25" width="18" height="18" rx="6" fill="#48C898" />
    <path d="M6.76849 12.9411C7.08091 13.2535 7.58745 13.2535 7.89987 12.9411L13.9508 6.8901C14.2633 6.57768 14.2633 6.07115 13.9508 5.75873C13.6384 5.44631 13.1319 5.44631 12.8195 5.75873L6.76849 11.8097C6.45607 12.1221 6.45607 12.6286 6.76849 12.9411Z" fill="white" stroke="white" strokeWidth="0.3" />
    <path d="M4.19721 9.30756C4.50962 8.99514 5.01616 8.99514 5.32858 9.30756L7.72269 11.7017C8.03511 12.0141 8.03511 12.5206 7.72269 12.833C7.41027 13.1455 6.90374 13.1455 6.59132 12.833L4.19721 10.4389C3.88479 10.1265 3.88479 9.61998 4.19721 9.30756Z" fill="white" stroke="white" strokeWidth="0.3" />
  </svg>

);
export default StatusCompletedIcon;
