import { request } from 'helpers/api';

export const getJobs = (status, params) => (
  request({
    method: 'GET',
    url: `/api/jobs/${status}`,
    params,
    cancel_token: 'getJobList',
  })
);

export const getJobDetails = (jobId) => (
  request({
    method: 'GET',
    url: `/api/job/${jobId}`,
  })
);

export const getClosedJobs = () => (
  request({
    method: 'GET',
    url: '/api/job/closed',
  })
);

export const getSpecificJobDetails = (jobId, requestType) => (
  request({
    method: 'GET',
    url: `/api/job/${jobId}/${requestType}`,
  })
);

export const getExportJobs = (params) => (
  request({
    method: 'GET',
    url: '/api/jobs/closed/generate-export-link',
    params,
  })
);

export const getExportMedia = (jobId, source, params = {}) => (
  request({
    method: 'POST',
    url: `/api/job/${jobId}/media/${source}/download`,
    params,
  })
);

export const sendAskQuestion = (jobId, params) => (
  request({
    method: 'POST',
    url: `/api/jobs/${jobId}/ask-a-question`,
    params,
  })
);

export const getReview = (jobId) => (
  request({
    method: 'GET',
    url: `/api/job/${jobId}/tenant_review`,
  })
);

export const getClosedMakeReadyJobs = (params = {}) => (
  request({
    method: 'GET',
    url: '/api/jobs/make-readies/closed',
    params,
    cancel_token: 'getClosedMakeReadyJobsList',
  })
);

export const getClosedMakeReadyJobDetails = (jobId, params = {}) => (
  request({
    method: 'GET',
    url: `/api/job/make-ready/${jobId}`,
    params,
  })
);

export const getOpenMakeReadyFilters = () => (
  request({
    method: 'GET',
    url: '/api/jobs/make-readies/open/filters',
  })
);

export const getCustomerNotes = (jobId, params = {}) => (
  request({
    method: 'GET',
    url: `/api/job/${jobId}/customer_notes`,
    params,
  })
);
