import { isEmpty } from 'lodash';
import React from 'react';

const FormGeneralAlert = ({ errors, successMessage }) => (
  <div className="validation-outer">
    {!isEmpty(errors)
      && (
      <div className="validation error">
        {Object.keys(errors).map((field) => (
          <React.Fragment key={field}>
            {errors[field].message}
            <br />
          </React.Fragment>
        ))}
      </div>
      )}
    {successMessage && <div className="validation success">{successMessage}</div>}
  </div>
);
export default FormGeneralAlert;
