import React from 'react';

const OutstandingIcon = (props) => (
  <svg {...props} width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.75" width="30" height="30" rx="10" fill="#283A5D" />
    <circle cx="9.04212" cy="15.7499" r="2.0409" fill="white" />
    <circle cx="15.4999" cy="15.7499" r="2.0409" fill="white" />
    <circle cx="21.9579" cy="15.7499" r="2.0409" fill="white" />
  </svg>

);
export default OutstandingIcon;
