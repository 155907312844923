import ImageSlider from 'components/shared/image-slider';
import ListingComponent from 'components/shared/listing-component';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

const formatApiResponse = (apiResponse = []) => {
  const formattedData = [];
  apiResponse.forEach((item) => {
    const data = {};
    Object.keys(item || {}).forEach((applianceKey) => {
      if (applianceKey !== 'location_appliance_attributes') {
        data[applianceKey] = item[applianceKey];
      } else {
        item.location_appliance_attributes.forEach((applianceAttributeKey) => {
          Object.keys(applianceAttributeKey || {}).forEach((locationKeys) => {
            if (locationKeys === 'slug') {
              if (applianceAttributeKey[locationKeys] === 'serial-image') {
                data[applianceAttributeKey[locationKeys]] = applianceAttributeKey?.attachments;
              } else {
                data[applianceAttributeKey[locationKeys]] = applianceAttributeKey?.value;
              }
            }
          });
        });
      }
    });
    data.actual_values = item;
    formattedData.push(data);
  });
  return formattedData;
};

const JobAppliancesTable = ({ dataSource, serviceCategory }) => {
  const [applianceDetails, setApplianceDetails] = useState([]);
  const [sliderImageArray, setSliderImageArray] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setApplianceDetails(
        { dataSource, location_appliances: formatApiResponse(dataSource) },
      );
    }
  }, [dataSource]);

  const onImageClickHandler = (images, selectedImage) => {
    if (selectedImage) {
      const imageArrayForSlider = images.filter(
        (image) => image?.attachment_id !== selectedImage?.attachment_id,
      );
      imageArrayForSlider.unshift(selectedImage);
      setSliderImageArray(imageArrayForSlider);
    } else {
      setSliderImageArray(images);
    }
  };

  return (
    <React.Fragment>
      {!isEmpty(applianceDetails?.location_appliances) ? (
        <ListingComponent
          mappings={[
            {
              key: 'appliance_type',
              label: 'Type',
            },
            {
              key: 'appliance-age',
              label: 'Built/Manufacture Year',
            },
            {
              key: 'attachments',
              label: `${serviceCategory} and S/N Photo`,
              render: (rowData) => {
                const images = [...(rowData?.attachments ?? []), ...(rowData?.['serial-image'] ?? [])];
                const limitedArrayOfAttachments = (images || []).filter((att, index) => index < 3);
                return (
                  <div className="appliance-img">
                    {limitedArrayOfAttachments?.map((attachment) => (
                      attachment?.attachment_url
                        ? (
                          <div
                            className="appliance-img--item"
                            key={attachment?.attachment_id}
                            onClick={() => onImageClickHandler(images, attachment)}
                          >
                            <img
                              alt="attachment"
                              src={attachment?.attachment_url}
                            />
                          </div>
                        ) : ''
                    ))}
                    {(images?.length > 3) ? (
                      <div
                        className="appliance-img--item view-more"
                        onClick={() => onImageClickHandler(images)}
                        role="presentation"
                      >
                        {images?.length - limitedArrayOfAttachments?.length}
                        +
                      </div>
                    ) : ''}
                  </div>
                );
              },
            }]}
          dataSource={applianceDetails?.location_appliances}
          primaryKey="location_appliance_id"
        />
      )
        : ''}

      {!isEmpty(sliderImageArray) ? (
        <ImageSlider
          showImageSlider={!isEmpty(sliderImageArray)}
          toggle={() => setSliderImageArray([])}
          imageArray={sliderImageArray}
        />
      ) : ''}

    </React.Fragment>
  );
};

export default JobAppliancesTable;
