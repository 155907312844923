import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'reactstrap';
import { get } from 'lodash';
import FormFeedback from 'reactstrap/lib/FormFeedback';

const CustomCheckbox = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext();

  const {
    hideFormError,
    name: propsName,
    clearError,
    shouldUnregister,
    type,
    fieldValue,
    handleOnchange,
    ...inputProps
  } = props;

  const { message: errorMessage = false } = get(errors, propsName, {});
  const currentValues = watch(propsName);

  return (
    <React.Fragment>
      <Controller
        name={propsName}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({
          onBlur,
          onChange,
        }) => (
          <Input
            type={type}
            onBlur={onBlur}
            {...inputProps}
            checked={currentValues?.includes(fieldValue)}
            onChange={(event) => {
              if (type === 'checkbox') {
                handleOnchange(event, currentValues, onChange);
              }
              if (clearError) {
                clearErrors();
              }
            }}
          />
        )}
      />
      {!hideFormError ? (
        <React.Fragment>
          {errorMessage ? (
            <FormFeedback>{errorMessage}</FormFeedback>
          ) : ''}
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default CustomCheckbox;
