import React from 'react';

// auth
import Email from 'assets/images/auth/email-icon';
import Password from 'assets/images/auth/password-icons';

// sidmenu
import OpenJobs from 'assets/images/side-menu/open-jobs-icon';
import Dashboard from 'assets/images/side-menu/dashboard-icon';
import ClosedJobs from 'assets/images/side-menu/closed-jobs-icon';
import Help from 'assets/images/side-menu/help-icon';
import CreateJobs from 'assets/images/side-menu/create-job-icon';
import Quotes from 'assets/images/side-menu/quotes-icon';
import TenantConversation from 'assets/images/side-menu/tenant-converstion-icon';
import Invoices from 'assets/images/side-menu/invoices-icon';
import PreferredVendors from 'assets/images/side-menu/preferred-vendors-icon';
import Amg from 'assets/images/side-menu/amg-icon';

// header
import DashboardHeader from 'assets/images/header/dashboard-header-icon';
import MyProfileHeader from 'assets/images/header/my-profile-header-icon';
import OpenJobsHeader from 'assets/images/header/open-jobs-header-icon';
import ClosedJobsHeader from 'assets/images/header/closed-jobs-header-icon';
import NotificationsHeader from 'assets/images/header/notifications-header-icon';
import PropertyManagersHeader from 'assets/images/header/property-managers-header-icon';
// global
import Upload from 'assets/images/global/upload-icon';
import NotificationXl from 'assets/images/global/notification-xl-icon';
import Close from 'assets/images/global/close-icon';
import Calendar from 'assets/images/global/calendar-icon';
import Logo from 'assets/images/global/logo-icon';
import LogoForDev from 'assets/images/global/logo-dev';
import LogoForQA from 'assets/images/global/logo-qa';
import LogoForStaging from 'assets/images/global/logo-staging';
import Notification from 'assets/images/global/notification-icon';
import Settings from 'assets/images/global/settings-icon';
import LogoGrey from 'assets/images/global/logo-grey-icon';
import ChatBubble from 'assets/images/global/chat-bubble-icon';
import Profile from 'assets/images/global/profile-icon';
import OurPM from 'assets/images/global/our-pm-icon';
import LogOut from 'assets/images/global/logout-icon';
import Filter from 'assets/images/global/filter-icon';
import CalendarSmall from 'assets/images/global/calendar-icon-small';
import Accordion from 'assets/images/global/accordion-icon';
import Appointment from 'assets/images/global/appointment-icon';
import User from 'assets/images/global/user-icon';
import Emergency from 'assets/images/global/emergency-icon';
import WorkOrder from 'assets/images/global/work-order-icon';
import Download from 'assets/images/global/download-icon';
import Edit from 'assets/images/global/edit-icon';
import StatusCompleted from 'assets/images/global/status-completed-icon';
import StatusUnpaid from 'assets/images/global/status-unpaid-icon';
import StatusVoided from 'assets/images/global/status-voided-icon';
import StatusVoid from 'assets/images/global/status-void-icon';
import StatusPartiallyPaid from 'assets/images/global/status-partially-paid-icon';
import StatusRefunded from 'assets/images/global/status-refunded';
import StatusPaymentSkipped from 'assets/images/global/status-payment-skipped';
import MakeReady from 'assets/images/global/make-ready-icon';

import ReviewIcon from 'assets/images/global/review-icon-green';
import PdfIcon from 'assets/images/global/pdf-icon';
import Archive from 'assets/images/global/archive-icon';
import Excel from 'assets/images/global/excel-icon';
import Plus from 'assets/images/global/plus-icon';
import ChatBubbleBig from 'assets/images/global/chat-bubble-icon-big';
import NotificationBig from 'assets/images/global/notification-icon-big';
import ConfirmBig from 'assets/images/global/confirm-icon-big';
import ExternalWorkOrder from 'assets/images/global/external-work-order-icon';
import AnsweringService from 'assets/images/global/answering-service-icon';
import PastDue from 'assets/images/global/past-due-icon';
import Outstanding from 'assets/images/global/outstanding-icon';
import Paid from 'assets/images/global/paid-icon';
import WalkThru from 'assets/images/global/walk-thru-icon';
import Scheduled from 'assets/images/global/scheduled-icon';
import Started2 from 'assets/images/global/started-icon2';

// status
import Started from 'assets/images/status/started-icon';
import Paused from 'assets/images/status/paused-icon';
import EnRoute from 'assets/images/status/en-route-icon';
import Stopped from 'assets/images/status/stopped-icon';
import Reached from 'assets/images/status/reached-icon';
import EnRouteStopped from 'assets/images/status/en-route-stopped';
import PendingReview from 'assets/images/status/pending-review';

// rating
import Star1 from 'assets/images/rating/star-1-icon';
import Star2 from 'assets/images/rating/star-2-icon';
import Star3 from 'assets/images/rating/star-3-icon';
import Star4 from 'assets/images/rating/star-4-icon';
import Star5 from 'assets/images/rating/star-5-icon';
import StarHalf from 'assets/images/rating/star-half';
import Star1Half from 'assets/images/rating/star-1-half';
import Star2Half from 'assets/images/rating/star-2-half';
import Star3Half from 'assets/images/rating/star-3-half';
import Star4Half from 'assets/images/rating/star-4-half';
import StarDisabled from 'assets/images/rating/star-disabled';

// help
import EmailUs from 'assets/images/help/email-us';
import LiveChat from 'assets/images/help/live-chat';
import PhoneCall from 'assets/images/help/phone-call';

// services
import Appliances from 'assets/images/services/appliances-icon';
import Plumbing from 'assets/images/services/plumbing-icon';

// open jobs
import PausedNotes from 'assets/images/open-jobs/paused-notes-icon';
import Returning from 'assets/images/open-jobs/returing-icon';

// Review
import NonReview from 'assets/images/review/non-review';
import Review from 'assets/images/review/review';

import GoBack from 'assets/images/open-jobs/go-back-icon';
import FlatPriceCatalog from 'assets/images/global/flat-price-catalog';
import FlatPriceCatalogSidebar from 'assets/images/global/flat-price-catalog-sidebar';
import StatusExpired from './global/expired-icon';
import OpenMakeReadies from './side-menu/open-make-readies';
import ClosedMakeReadies from './side-menu/closed-make-readies';
import OpenMakeReady from './global/open-make-ready';
import ClosedMakeReady from './global/close-make-ready';

export const PastDueIcon = (props) => (
  <PastDue {...props} />
);
export const OutstandingIcon = (props) => (
  <Outstanding {...props} />
);
export const PaidIcon = (props) => (
  <Paid {...props} />
);
// sidemenu
export const OpenJobsIcon = (props) => (
  <OpenJobs {...props} />
);

export const DashboardIcon = (props) => (
  <Dashboard {...props} />
);

export const ClosedJobsIcon = (props) => (
  <ClosedJobs {...props} />
);

export const HelpIcon = (props) => (
  <Help {...props} />
);

export const CreateJobIcon = (props) => (
  <CreateJobs {...props} />
);

export const QuotesIcon = (props) => (
  <Quotes {...props} />
);

export const OpenMakeReadiesIcon = (props) => (
  <OpenMakeReadies {...props} />
);

export const ClosedMakeReadiesIcon = (props) => (
  <ClosedMakeReadies {...props} />
);

export const TenentConversationIcon = (props) => (
  <TenantConversation {...props} />
);

export const InvoicesIcon = (props) => (
  <Invoices {...props} />
);
export const FlatPriceCatalogSidebarIcon = (props) => (
  <FlatPriceCatalogSidebar {...props} />
);

export const PreferredVendorsIcon = (props) => (
  <PreferredVendors {...props} />
);

export const AmgIcon = (props) => (
  <Amg {...props} />
);

// header icons

export const DashboardHeaderIcon = (props) => (
  <DashboardHeader {...props} />
);

export const MyProfileHeaderIcon = (props) => (
  <MyProfileHeader {...props} />
);

export const OpenJobsHeaderIcon = (props) => (
  <OpenJobsHeader {...props} />
);

export const ClosedJobsHeaderIcons = (props) => (
  <ClosedJobsHeader {...props} />
);

export const NotificationsHeaderIcons = (props) => (
  <NotificationsHeader {...props} />
);
export const PropertyManagersHeaderIcons = (props) => (
  <PropertyManagersHeader {...props} />
);
// global icons

export const UploadIcon = (props) => (
  <Upload {...props} />
);

export const NotificationXlIcon = (props) => (
  <NotificationXl {...props} />
);

export const CloseIcon = (props) => (
  <Close {...props} />
);

export const CalendarIcon = (props) => (
  <Calendar {...props} />
);

export const LogoIcon = (props) => (
  <Logo {...props} />
);

export const LogoIconDev = (props) => (
  <LogoForDev {...props} />
);

export const LogoIconQA = (props) => (
  <LogoForQA {...props} />
);

export const LogoIconStaging = (props) => (
  <LogoForStaging {...props} />
);

export const NotificationsIcon = (props) => (
  <Notification {...props} />
);

export const SettingsIcon = (props) => (
  <Settings {...props} />
);

export const LogGreyIcon = (props) => (
  <LogoGrey {...props} />
);

export const ChatBubbleIcon = (props) => (
  <ChatBubble {...props} />
);

export const ProfileIcon = (props) => (
  <Profile {...props} />
);

export const OurPMIcon = (props) => (
  <OurPM {...props} />
);

export const LogoutIcon = (props) => (
  <LogOut {...props} />
);

export const DownloadIcon = (props) => (
  <Download {...props} />
);

export const StatusVoidIcon = (props) => (
  <StatusVoid {...props} />
);
export const StatusExpiredIcon = (props) => (
  <StatusExpired {...props} />
);
export const StatusVoidedIcon = (props) => (
  <StatusVoided {...props} />
);
export const StatusPartiallyPaidIcon = (props) => (
  <StatusPartiallyPaid {...props} />
);
export const StatusPaymentSkippedIcon = (props) => (
  <StatusPaymentSkipped {...props} />
);
export const StatusRefundedIcon = (props) => (
  <StatusRefunded {...props} />
);
export const ReviewIconGreen = (props) => (
  <ReviewIcon {...props} />
);
// auth
export const EmailIcon = () => (
  <Email />
);

export const PasswordIcon = () => (
  <Password />
);

export const FilterIcon = (props) => (
  <Filter {...props} />
);
export const CalendarIconSmall = (props) => (
  <CalendarSmall {...props} />
);

export const AccordionIcon = (props) => (
  <Accordion {...props} />
);

export const AppointmentIcon = (props) => (
  <Appointment {...props} />
);

export const UserIcon = (props) => (
  <User {...props} />
);

export const EmergencyIcon = (props) => (
  <Emergency {...props} />
);

export const WorkOrderIcon = (props) => (
  <WorkOrder {...props} />
);
export const StatusCompletedIcon = (props) => (
  <StatusCompleted {...props} />
);
export const StatusUnpaidIcon = (props) => (
  <StatusUnpaid {...props} />
);
export const EditIcon = (props) => (
  <Edit {...props} />
);
// status

export const StartedIcon = (props) => (
  <Started {...props} />
);
export const PausedIcon = (props) => (
  <Paused {...props} />
);

export const EnRouteIcon = (props) => (
  <EnRoute {...props} />
);

export const StoppedIcon = (props) => (
  <Stopped {...props} />
);
export const ReachedIcon = (props) => (
  <Reached {...props} />
);

export const EnRouteStoppedIcon = (props) => (
  <EnRouteStopped {...props} />
);
export const PendingReviewIcon = (props) => (
  <PendingReview {...props} />
);
// rating
export const Star1Icon = (props) => (
  <Star1 {...props} />
);
export const Star2Icon = (props) => (
  <Star2 {...props} />
);
export const Star3Icon = (props) => (
  <Star3 {...props} />
);
export const Star4Icon = (props) => (
  <Star4 {...props} />
);
export const Star5Icon = (props) => (
  <Star5 {...props} />
);

export const StarHalfIcon = (props) => (
  <StarHalf {...props} />
);
export const Star1HalfIcon = (props) => (
  <Star1Half {...props} />
);
export const Star2HalfIcon = (props) => (
  <Star2Half {...props} />
);
export const Star3HalfIcon = (props) => (
  <Star3Half {...props} />
);
export const Star4HalfIcon = (props) => (
  <Star4Half {...props} />
);
export const StarDisabledIcon = (props) => (
  <StarDisabled {...props} />
);
export const EmailUsIcon = (props) => (
  <EmailUs {...props} />
);

export const LiveChatIcon = (props) => (
  <LiveChat {...props} />
);
export const PhoneCallIcon = (props) => (
  <PhoneCall {...props} />
);

export const AppliancesIcon = (props) => (
  <Appliances {...props} />
);

export const PlumbingIcon = (props) => (
  <Plumbing {...props} />
);

export const PausedNotesIcon = (props) => (
  <PausedNotes {...props} />
);

export const ReturningIcon = (props) => (
  <Returning {...props} />
);

export const ArchiveIcon = (props) => (
  <Archive {...props} />
);

export const ExcelIcon = (props) => (
  <Excel {...props} />
);

export const PlusIcon = (props) => (
  <Plus {...props} />
);

export const ChatBubbleBigIcon = (props) => (
  <ChatBubbleBig {...props} />
);

export const NotificationBigIcon = (props) => (
  <NotificationBig {...props} />
);
export const ConfirmBigIcon = (props) => (
  <ConfirmBig {...props} />
);
export const ExternalWorkOrderIcon = (props) => (
  <ExternalWorkOrder {...props} />
);

export const AnsweringServiceIcon = (props) => (
  <AnsweringService {...props} />
);
export const FlatPriceCatalogIcon = (props) => (
  <FlatPriceCatalog {...props} />
);

export const NonReviewICon = (props) => (
  <NonReview {...props} />
);

export const ReviewICon = (props) => (
  <Review {...props} />
);

export const GoBackIcon = (props) => (
  <GoBack {...props} />
);

export const PortableDocumentIcon = (props) => (
  <PdfIcon {...props} />
);

export const WalkThruIcon = (props) => (
  <WalkThru {...props} />
);

export const ScheduledIcon = (props) => (
  <Scheduled {...props} />
);

export const StartedIcon2 = (props) => (
  <Started2 {...props} />
);

export const MakeReadyIcon = (props) => (
  <MakeReady {...props} />
);
export const OpenMakeReadyIcon = (props) => (
  <OpenMakeReady {...props} />
);
export const ClosedMakeReadyIcon = (props) => (
  <ClosedMakeReady {...props} />
);
