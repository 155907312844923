import React, {
  // useContext,
  useEffect,
  // useRef,
  useState,
} from 'react';
import {
  Row, Col, UncontrolledCollapse, Button, CardBody, Card,
} from 'reactstrap';
import {
  // LiveChatIcon,
  PhoneCallIcon,
  EmailUsIcon,
  HelpIcon,
} from 'assets/images/svg-icons';
import getFAQ from 'services/help';
// import { useIntercom } from 'react-use-intercom';
// import { AuthContext } from 'context/auth-context/auth-context';

const Help = () => {
  const [faqList, setFaqList] = useState([]);
  // const { show } = useIntercom();

  // const intercomBooted = useRef(false);
  // const { boot, update } = useIntercom();
  // const [userDetails] = useContext(AuthContext);

  const setFaqToggle = (id) => {
    const newFaqArray = [...faqList];

    newFaqArray.map((item) => {
      if (item.id === id) {
        const tempItem = item;
        tempItem.toggle = !tempItem.toggle;
        return tempItem;
      }
      return item;
    });

    setFaqList(newFaqArray);
  };

  useEffect(() => {
    getFAQ().then((res) => {
      setFaqList(
        res?.data?.content?.map((faq, index) => (
          {
            id: index,
            question: faq.heading,
            answer: faq.content,
          }
        )),
      );
    }).catch(() => {});
  }, []);

  // const showIntercomeHandler = () => {
  //   if (userDetails.intercom_user_hash) {
  //     if (!intercomBooted.current) {
  //       boot({
  //         name: `${userDetails?.first_name} ${userDetails?.last_name}`,
  //         userHash: userDetails?.intercom_user_hash,
  //         createdAt: userDetails?.created_at,
  //         email: userDetails?.email,
  //         userId: userDetails?.property_manager_id,
  //       });
  //       intercomBooted.current = true;
  //     }
  //   }
  //   show();
  // };

  // useEffect(() => {
  //   if (intercomBooted.current) {
  //     update({
  //       name: `${userDetails?.first_name} ${userDetails?.last_name}`,
  //       userHash: userDetails?.intercom_user_hash,
  //       createdAt: userDetails?.created_at,
  //       email: userDetails?.email,
  //       userId: userDetails?.property_manager_id,
  //     });
  //   }
  // }, [userDetails, update]);

  return (
    <div>
      <div className="page-header page-header-with-filter">
        <div className="page-header-inner">
          <span>
            <HelpIcon />
          </span>
          <h3>Help</h3>
        </div>
      </div>
      <div className="help-content">
        <Row className="">
          <Col xs="12" lg="6">
            <a href="tel:(913) 303-7781" className="contact-card">
              <PhoneCallIcon />
              <h3>(913) 303-7781</h3>
              <p>Please call our Lula support line at anytime for assistance.</p>
            </a>
          </Col>
          <Col xs="12" lg="6">
            <a href="mailto:info@lula.life" className="contact-card">
              <EmailUsIcon />
              <h3>Email Us</h3>
              <p>Our support team monitors info@lula.life and responds within 1 hour.</p>
            </a>
          </Col>

        </Row>

        <Row className="">
          <Col xs="12" lg="12">
            <div className="faq">
              <h2 className="underlined">Frequently Asked Questions</h2>
              {
                faqList.map((item) => {
                  const togglerID = `toggler-${item.id}`;
                  return (
                    <div className={item.toggle ? 'item expanded' : 'item'} key={item.id}>
                      <Button color="primary" id={togglerID} onClick={() => setFaqToggle(item.id)}>
                        <span className="toggle-button" />
                        {item.question}
                      </Button>
                      <UncontrolledCollapse toggler={togglerID} defaultOpen={item.toggle}>
                        <Card className="answer-contaienr">
                          <CardBody className="answer-body">
                            {item.answer}
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </div>
                  );
                })
              }
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Help;
