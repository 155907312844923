import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const options = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  panControl: false,
  rotateControl: false,
  scrollwheel: false,
  draggable: false,
};

const markerIcon = () => ({
  path: 'M18.0063 0.289062C8.69634 0.289062 1.14551 7.8399 1.14551 17.1499C1.14551 27.9801 13.2881 41.4941 15.1962 43.5595C15.2508 43.6189 15.3049 43.6781 15.3586 43.7369L15.3587 43.737C16.0807 44.5282 16.7404 45.2512 18.0063 45.2512C19.2722 45.2512 19.932 44.5282 20.654 43.737C20.7077 43.6782 20.7618 43.6189 20.8165 43.5595C22.7245 41.4941 34.8671 27.9801 34.8671 17.1499C34.8671 7.8399 27.3163 0.289062 18.0063 0.289062ZM18.0063 28.3904C11.7987 28.3904 6.76578 23.3575 6.76578 17.1499C6.76578 10.9423 11.7987 5.90933 18.0063 5.90933C24.2139 5.90933 29.2469 10.9423 29.2469 17.1499C29.2469 23.3575 24.2139 28.3904 18.0063 28.3904ZM12.3861 17.1499C12.3861 14.0475 14.9039 11.5296 18.0063 11.5296C21.1087 11.5296 23.6266 14.0475 23.6266 17.1499C23.6266 20.2523 21.1087 22.7701 18.0063 22.7701C14.9039 22.7701 12.3861 20.2523 12.3861 17.1499Z',
  fillColor: '#1376FB',
  fillOpacity: 1,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeColor: 'transparent',
});

const RenderMaps = ({
  latitude,
  longitude,
  height,
  width,
}) => {
  const containerStyle = {
    height,
    width,
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      }}
      zoom={12}
      options={options}
    >
      <Marker
        position={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
        icon={markerIcon()}
      />
    </GoogleMap>
  );
};

RenderMaps.defaultProps = {
  height: '300px',
  width: '500px',
};

RenderMaps.propTypes = {
  longitude: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  latitude: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default React.memo(RenderMaps);
