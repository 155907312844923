import React from 'react';

const StatusVoidIcon = (props) => (
  <svg {...props} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.213867" width="18" height="18" rx="6" fill="#FF5D5D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.99805 4.2373V10.7588H8.00195V4.2373H9.99805ZM9.99805 12.4404V14.1904H8.00195V12.4404H9.99805Z" fill="white" />
  </svg>

);
export default StatusVoidIcon;
