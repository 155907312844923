import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const PhoneNumber = (props) => {
  const { control, errors } = useFormContext();
  const {
    getValue,
    name: propsName,
    placeholder,
    type,
  } = props;
  return (
    <FormGroup>
      <Controller
        control={control}
        name={propsName}
        render={({
          name,
          onBlur,
          onChange,
          value,
        }) => (
          <NumberFormat
            customInput={Input}
            format="(###) ###-####"
            invalid={!!(errors && errors[name])}
            name={name}
            onBlur={onBlur}
            onValueChange={({ value: phoneNumber }) => {
              if (getValue) {
                getValue(phoneNumber);
              }
              onChange(phoneNumber);
            }}
            placeholder={placeholder}
            type={type}
            value={value}
            {...props}
          />
        )}
      />
      {errors && errors[propsName] && errors[propsName].message ? (
        <FormFeedback>{errors[propsName].message}</FormFeedback>
      ) : ''}
    </FormGroup>
  );
};
PhoneNumber.defaultProps = {
  placeholder: '',
  type: 'text',
};

PhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default PhoneNumber;
