import React from 'react';

const CreateJobIcon = (props) => (
  <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="12.6904" r="12" stroke="#949DAE" />
    <path d="M9.05129 12.6907H16.9486" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
    <path d="M13 8.74148V16.6388" stroke="#949DAE" strokeWidth="1.1" strokeLinecap="round" />
  </svg>
);

export default CreateJobIcon;
