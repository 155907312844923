import React from 'react';

const DownloadIcon = (props) => (
  <svg {...props} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.70664 1.35918C8.70207 1.14645 8.9282 0.962376 9.13422 1.02668C9.27959 1.06782 9.40089 1.20314 9.39815 1.35796C9.39693 4.08131 9.39784 6.80466 9.39754 9.52832C9.40028 10.0909 9.39175 10.6538 9.40211 11.2164C10.1271 10.4999 10.8436 9.77457 11.5665 9.05594C11.697 8.91483 11.9466 8.92245 12.07 9.06874C12.1943 9.20466 12.1828 9.4442 12.0441 9.56672C11.0466 10.5593 10.05 11.5532 9.05224 12.5458C8.07822 11.5788 7.10938 10.6066 6.13384 9.64078C6.04607 9.55819 5.95434 9.46737 5.93148 9.34363C5.90954 9.18242 6.02077 9.02424 6.16919 8.96786C6.24995 8.95354 6.3362 8.94988 6.41605 8.97243C6.52089 9.02059 6.59373 9.11384 6.6751 9.19247C7.35136 9.8663 8.02275 10.5447 8.70176 11.2158C8.71213 10.1455 8.70329 9.07483 8.70634 8.00451C8.70634 5.7895 8.70603 3.57419 8.70664 1.35918Z" fill="black" stroke="#3E7CFF" />
    <path d="M1.11578 12.4097C1.34496 12.4073 1.57414 12.4094 1.80332 12.4082C1.81277 13.0945 1.80424 13.7809 1.80729 14.4672C1.79936 14.7445 1.91791 15.0218 2.11632 15.2141C2.30252 15.4049 2.56858 15.5073 2.8322 15.5171C6.9474 15.5171 11.0626 15.5165 15.1775 15.5174C15.4719 15.5299 15.7779 15.4266 15.9863 15.2141C16.1856 15.0212 16.3027 14.7433 16.2957 14.4653C16.2966 13.7799 16.2944 13.0948 16.2966 12.4094C16.5267 12.4082 16.7571 12.4079 16.9872 12.4097C16.9902 13.0644 16.9875 13.7193 16.9887 14.3739C17.0018 14.7677 16.8903 15.169 16.6553 15.4875C16.3621 15.898 15.8797 16.1699 15.375 16.197C15.2388 16.2159 15.101 16.2083 14.9639 16.2095C11.1239 16.2095 7.28386 16.2095 3.44386 16.2095C3.03944 16.2034 2.61734 16.2449 2.23334 16.09C1.82405 15.9346 1.47631 15.6198 1.2895 15.223C1.18344 15.0179 1.13803 14.7869 1.11548 14.5592C1.11395 13.8427 1.11365 13.1262 1.11578 12.4097Z" fill="black" stroke="#3E7CFF" />
  </svg>
);

export default DownloadIcon;
