import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FilterModal from 'components/shared/filter/components/filter-modal';
import Moment from 'moment';

import {
  FilterIcon,
  PastDueIcon, OutstandingIcon,
} from 'assets/images/svg-icons';
import FilterForm from 'components/shared/filter/components/filter-form';

const Filter = (props) => {
  const {
    HeaderIcon,
    title,
    filters,
    onFilter,
    defaultValues,
    clearStateValues = null,
    invoiceStatus,
  } = props;

  const parseDefaultValues = (values) => {
    const object = {};

    if (filters) {
      filters.map((filter) => {
        if (filter.filter_type === 'select') {
          if (values[filter.name]?.value) {
            object[filter.name] = values[filter.name]?.value;
          }
        } else if (filter.filter_type === 'date-range') {
          if (values[filter.name]?.endDate && values[filter.name]?.startDate) {
            object[filter.from_name] = Moment(values[filter.name]?.startDate).format('YYYY-MM-DD');
            object[filter.to_name] = Moment(values[filter.name]?.endDate).format('YYYY-MM-DD');
          }
        } else if (values[filter.name]) {
          object[filter.name] = values[filter.name];
        }
        return false;
      });
    }
    return object;
  };

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(null);
  // TODO Getting Complicated. Use Context to manipulate values
  const [lastFilter, setLastFilter] = useState(parseDefaultValues(defaultValues));

  const clearBtnRef = useRef(null);

  return (
    <React.Fragment>
      <div className="page-header page-header-with-filter">
        <div className="page-header-inner">
          <span>
            {HeaderIcon && <HeaderIcon />}
          </span>
          <h3>{title}</h3>
        </div>
        <button type="button" className="filter-icon filters-added" onClick={() => setShowFilterModal(true)}>
          <FilterIcon />
          Filters
        </button>
      </div>
      {
        invoiceStatus && (
        <div className="invoice-summary">
          <Link
            to="#"
            className={`invoice-summary--item invoice-summary--item-50 past-due ${lastFilter.status === 'past-due' ? 'active' : ''}`}
            onClick={() => {
              setCurrentFilters({
                ...(lastFilter.status === 'past-due' ? { status: undefined } : { status: { label: 'Past Due', value: 'past-due' } }),
              });
              onFilter({ ...(lastFilter.status === 'past-due' ? {} : { status: 'past-due' }) });
              setLastFilter({ ...(lastFilter.status === 'past-due' ? {} : { status: 'past-due' }) });
              if (lastFilter.status === 'past-due' && clearBtnRef) {
                clearBtnRef.current.onClick();
              }
            }}
          >
            <h4>
              <PastDueIcon />
              {invoiceStatus?.due?.label}
              {' '}
              (
              {invoiceStatus?.due?.count}
              )
            </h4>
            <h3>{invoiceStatus?.due?.value}</h3>
          </Link>
          <Link
            to="#"
            className={`invoice-summary--item invoice-summary--item-50 outstanding ${lastFilter.status === 'outstanding' ? 'active' : ''}`}
            onClick={() => {
              setCurrentFilters({
                ...(lastFilter.status === 'outstanding' ? { status: undefined } : { status: { value: 'outstanding', label: 'Total Outstanding' } }),
              });
              onFilter({ ...(lastFilter.status === 'outstanding' ? {} : { status: 'outstanding' }) });
              setLastFilter({ ...(lastFilter.status === 'outstanding' ? {} : { status: 'outstanding' }) });
              if (lastFilter.status === 'outstanding' && clearBtnRef) {
                clearBtnRef.current.onClick();
              }
            }}
          >
            <h4>
              <OutstandingIcon />
              {invoiceStatus?.outstanding?.label}
              {' '}
              (
              {invoiceStatus?.outstanding?.count}
              )
            </h4>
            <h3>{invoiceStatus?.outstanding?.value}</h3>
          </Link>
        </div>
        )
      }
      <div className="global-filters global-filters-open-jobs">
        <FilterForm
          filters={filters}
          onFilter={(filter, rowFilter, isResetFilter) => {
            onFilter(filter, rowFilter, isResetFilter);
            setCurrentFilters(rowFilter);
            setLastFilter(filter);
          }}
          clearStateValues={clearStateValues}
          defaultValues={defaultValues}
          currentFilters={currentFilters}
          ref={clearBtnRef}
        />
      </div>
      {
        showFilterModal && (
        <FilterModal
          filters={filters}
          onFilter={(filter, rawFilter, isResetFilter) => {
            onFilter(filter, rawFilter, isResetFilter);
            setCurrentFilters(rawFilter);
            setLastFilter(filter);
          }}
          clearStateValues={clearStateValues}
          defaultValues={defaultValues}
          currentFilters={currentFilters}
          showFilterModal={showFilterModal}
          toggle={() => setShowFilterModal(false)}
        />
        )
      }
    </React.Fragment>
  );
};

Filter.defaultProps = {
  HeaderIcon: null,
  title: null,
  filters: [],
  onFilter: null,
  defaultValues: {},
};

Filter.propTypes = {
  HeaderIcon: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.instanceOf(Array),
  onFilter: PropTypes.func,
  defaultValues: PropTypes.shape(),
};

export default Filter;
