import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import './sass/App.scss';
import baseRoutes from 'routes/index';

function App() {
  const faviconEl = document.getElementById('favicon');

  useEffect(() => {
    if (faviconEl) {
      if (process.env.REACT_APP_FAVICON_NAME) {
        faviconEl.href = `/${process.env.REACT_APP_FAVICON_NAME}`;
      } else {
        faviconEl.href = '/favicon.ico';
      }
    }
  }, [faviconEl]);

  return (
    <React.Fragment>
      <Switch>
        {baseRoutes.map((prop) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.to} key={prop.key} />;
          }
          return (
            <Route
              path={prop.path}
              exact={prop.exact}
              component={prop.component}
              key={prop.key}
            />
          );
        })}
      </Switch>
    </React.Fragment>
  );
}

export default App;
