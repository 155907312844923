import { request } from 'helpers/api';

export const getInvoice = (params) => (
  request({
    method: 'GET',
    url: '/api/invoices',
    params,
    cancel_token: 'getInvoice',
  })
);

export const getInvoiceHeader = (params) => (
  request({
    method: 'GET',
    url: '/api/invoices/headers',
    params,
  })
);

export const getExportInvoices = (params) => (
  request({
    method: 'GET',
    url: '/api/invoices/generate-export-link',
    params,
  })
);
