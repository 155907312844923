import React from 'react';

const StatusVoidedIcon = (props) => (
  <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.606445" y="0.212891" width="18" height="18" rx="6" fill="#FF8E00" />
    <rect x="14.8135" y="8.0918" width="2.24287" height="10.4142" rx="1.12143" transform="rotate(90 14.8135 8.0918)" fill="white" />
  </svg>

);
export default StatusVoidedIcon;
