import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import Loader from 'components/shared/loader';

import { getCustomerNotes } from 'services/jobs';

const CustomerNotesModal = (props) => {
  const { toggle, jobId, hideNotificationDot } = props;
  const [customerNotes, setCustomerNotes] = useState([]);
  const [isCustomerNoteFetching, setIsCustomerNoteFetching] = useState(false);

  const fetchCustomerNotes = useCallback(() => {
    if (jobId && isEmpty(customerNotes)) {
      setIsCustomerNoteFetching(true);
      getCustomerNotes(jobId, { request_type: 'json' }).then((response) => {
        setCustomerNotes(response?.data);
        if (hideNotificationDot) {
          hideNotificationDot();
        }
      }).catch(() => {}).finally(() => {
        setIsCustomerNoteFetching(false);
      });
    }
  }, [jobId, hideNotificationDot, customerNotes]);

  useEffect(() => {
    fetchCustomerNotes();
  }, [fetchCustomerNotes]);

  return (
    <Modal
      isOpen
      toggle={toggle}
      centered
      className="global-modal job-notes-modal"
    >
      <ModalHeader toggle={toggle}>
        Job Notes
        &nbsp;
      </ModalHeader>
      <ModalBody>
        <React.Fragment>
          <div className="divider" />
          {isCustomerNoteFetching ? <Loader /> : ''}
          <div className="new-note-data">
            {!isCustomerNoteFetching && isEmpty(customerNotes?.data) ? (
              <div className="no-data-found">
                No data found.
              </div>
            ) : customerNotes?.data?.map((customerNote) => (
              <div className="customer-note-wrapper new-note" key={customerNote?.job_status_note_id}>
                <div className="customer-note-top">
                  <div className="customer-note-time">
                    { moment.utc(customerNote?.created_at).local().format('MMM DD YYYY HH:mm: a')}
                    {' '}
                    (job#
                    {' '}
                    <a href="#">
                      {customerNote?.job_no}
                    </a>
                    )
                  </div>
                  {customerNote?.is_new ? (
                    <span className="green-text">NEW</span>
                  ) : ''}
                </div>
                <div className="customer-note-bottom">
                  <p>{customerNote?.note}</p>
                </div>
              </div>
            )) }
          </div>
        </React.Fragment>
        <div className="button-outer-wrap">
          <Button color="secondary" onClick={toggle}>Close</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomerNotesModal;
