import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import ButtonWithLoader from '../form-fields/loading-button';

const CustomerServiceAgreementModal = ({
  agreementHandler,
  isAgreementButtonLoading,
}) => (
  <Modal
    isOpen
    centered
    className="job-description-modal service-agreement-modal"
  >
    <ModalHeader>
      Lula, LLC Customer Service Agreement
      <p className="service-agreement-modal__sub-title">Last Modified: September 24, 2021</p>
    </ModalHeader>
    <ModalBody>
      <div className="divider" />
      <div className="service-agreement-modal__wrapper">
        <p>
          Please read this customer service agreement (“
          <strong>CSA</strong>
          ”) carefully before accessing or using Lula, LLC’s (“
          <strong>Lula</strong>
          ”,“
          <strong>we</strong>
          ”, “
          <strong>us</strong>
          ”, or “
          <strong>our</strong>
          ”) mobile applications or websites (the “
          <strong>Applications</strong>
          ”).
          <strong>
            By accessing, downloading, installing, or using the
            Applications and/or receiving the Services (defined below),
            you, as a party that may receive services from Lula Service
            Providers and/or may submit one or more Job Requests through
            our Applications, accept and agree to be bound and abide by
            this CSA, our privacy policy found at&nbsp;
            <a
              href="https://lula.life/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              www.lula.life/privacy-policy
            </a>
            &nbsp;(the “Privacy Policy”) and our terms and conditions
            found at&nbsp;
            <a
              href="https://lula.life/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              www.lula.life/terms-of-service
            </a>
            &nbsp;(the “Terms and Conditions”), as they may be amended by
            Lula from time to time.
          </strong>
          If you do not agree to this CSA, the Terms and Conditions, or
          the Privacy Policy, you may not access, use, or install the
          Applications or receive the Services (as defined below). In the
          event of a conflict between the terms of this CSA, the Terms and
          Conditions, and the Privacy Policy, the terms of this CSA shall
          control. This CSA shall control over all other terms,
          conditions, and agreements and together with the Terms and
          Conditions and Privacy Policy, are the final and exclusive
          agreement with respect to your use of the Applications for the
          Services (defined below). As used herein, the words
          &quot;including&quot; and &quot;include&quot; mean
          &quot;including, but not limited to.&quot;
        </p>
        <h5>Rights</h5>
        <p>
          You acknowledge and agree that the Applications are provided to
          you under license, and are not sold to you. You do not acquire
          any ownership interest in the Applications under this CSA, or
          any other rights thereto other than to use the Applications in
          accordance with the license granted, and subject to all terms,
          conditions, and restrictions, under this CSA and the Terms and
          Conditions. Lula and its licensors reserve and shall retain
          their entire right, title, and interest in and to the
          Applications, including all copyrights, trademarks, and other
          intellectual property rights therein or relating thereto, except
          as expressly granted to you in this CSA or the Terms and
          Conditions.
        </p>
        <h5>Services</h5>
        <p>
          The Applications constitute a technology platform that enables
          you to arrange and schedule property maintenance, and other
          residential and commercial property services (and corresponding
          goods) designated within the Applications, as updated and
          modified by Lula from time to time and any corresponding goods
          provided pursuant to such services (the “
          <strong>Services</strong>
          ”), by third party service providers (the “
          <strong>Service Providers</strong>
          ”) through the submission of one or more requests for such
          Services through the Applications (a “
          <strong>Job Request</strong>
          ”, with each such Job Request relating to a “
          <strong>Job</strong>
          ”). By submitting a Job Request, you are making a binding
          commitment to receive and pay for Services in accordance with
          this CSA and represent and warrant that you have full power and
          authority to enter into this CSA and submit Job Requests on
          behalf of yourself and your affiliates. Services shall only be
          provided to you by Service Providers (and not Lula) if your Job
          Request is accepted. Lula makes no warranty to you that your Job
          Request will be accepted and disclaims any agency relationship
          with any Service Provider including any partnership, joint
          venture, or other legal relationship of any kind. All Services
          are provided exclusively by the Service Provider and, with the
          sole exception of the limited warranty provided by Lula under
          this CSA, Lula disclaims any and all liability and warranties of
          any kind with respect to the delivery of the Services by any
          Service Provider.
        </p>
        <p>
          The selection of Services will be made at your discretion. The
          acceptance of Job Requests are at the sole discretion of the
          Service Providers. Lula is not rendering any professional
          opinions as to the sufficiency or appropriateness of any
          Services with respect to your property or recipient of the
          Services. There is no guarantee that your Job Requests will be
          accepted by a Service Provider.
        </p>
        <p>
          After you have received the Services, you will have the
          opportunity to rate your experience and leave additional
          feedback about your Service Provider. Lula retains sole
          discretion to modify, retain, or delete your feedback. You shall
          not provide feedback that contains lewd, lascivious, tortious,
          or unlawful statements with respect to any Service Provider or
          any other person.
        </p>
        <h5>Arrival Times</h5>
        <p>
          You must designate a time frame for the job within your Job
          Request. Once you have submitted a Job Request, a Service
          Provider that accepts the Job Request shall arrive at your Job
          location within the time frame requested (to the extent such
          requested time is reasonable) within the Job Request.
          Notwithstanding, you may schedule an arrival time for any Job
          Request more than 4 hours in advance, in which case, a Service
          Provider shall arrive at your Job site within 30 minutes of the
          time scheduled in your Job Request (so long as your Job Request
          is accepted). Acceptance of Job Requests are dependent on the
          geographical location of Service Providers and Lula makes no
          guarantee that your Job Request will be accepted by any Service
          Provider.
        </p>
        <p>
          For any Job Request that requires Services to be provided inside
          of a dwelling, you must have an adult (over the age of 18 years
          of age) present at the Job site.
        </p>
        <h5>Fees and Payment</h5>
        <p>
          You understand that your use of the Applications and the
          delivery of the Services may result in charges to you by Lula
          for the services or goods you receive from a Service Provider
          (&quot;
          <strong>Fees</strong>
          &quot;) in addition to certain transaction, service, and
          surcharge fees charged by Lula for facilitating the Services
          (the “
          <strong>Transaction Fees</strong>
          ”), which shall each be payable by you to Lula. By submitting a
          Job Request, you also agree to pay promptly all Fees and
          Transaction Fees associated with such Job Request within thirty
          (30) days of Lula’s invoice date. Fees and Transaction Fees will
          be inclusive of applicable taxes where required by law. Fees and
          Transaction Fees paid by you are final and non-refundable,
          unless otherwise determined by Lula.
        </p>
        <p>
          Once the Service Provider is at your Job site, you may request
          quotes or bids from a Service Provider for services that are
          additional to the services you requested in your Job Request (“
          <strong>Additional Services</strong>
          ”); provided, that if the Additional Services are of a type of
          service included in the Services (as may be updated by Lula in
          its sole discretion) or planned expansion of the Services, Lula
          reserves the right to approve or deny any such Additional
          Services and, if approved, your payment for and receipt of the
          Additional Services shall be conducted through the Applications
          and subject to this CSA. Any Additional Services provided by a
          Service Provider that are not the type of services included in
          the Services or that are not facilitated through the
          Applications (a) shall be provided solely by such Service
          Provider in their individual capacity and not as an agent,
          service provider, or affiliate of Lula; and (b) LULA EXPRESSLY
          DISCLAIMS, AND YOU HEREBY WAIVE AND RELEASE LULA, TO THE FULLEST
          EXTENT PERMITTED BY LAW, FROM ANY AND ALL WARRANTIES, LIABILITY,
          COSTS, DAMAGES, PENALTIES, ASSESSMENTS, TAXES, OR CLAIMS OF ANY
          KIND ARISING OR RESULTING FROM SUCH ADDITIONAL SERVICES.
        </p>
        <p>
          Lula reserves the right to establish, reduce, increase, and/or
          adjust the Fees and Transaction Fees for any or all Services at
          any time in Lula’s sole discretion. All Fees and Transaction
          Fees are based on Lula’s pricing models and the particular facts
          and circumstances of your Job Request and your Job site. You
          acknowledge and agree that Fees and Transaction Fees applicable
          in certain geographical areas may increase during times of high
          demand. Lula will use reasonable efforts to inform you of Fees
          and Transaction Fees that may apply within your Job Request,
          provided that you will be responsible for Fees and Transaction
          Fees incurred under your account regardless of your awareness of
          such Fees and Transaction Fees or the amounts thereof. Lula may
          from time to time provide certain users with promotional offers
          and discounts that may result in different amounts charged for
          the same or similar services or goods obtained through the
          Applications, and you agree that such promotional offers and
          discounts, unless also made available to you, shall have no
          bearing on your use of the Services or the Fees and Transaction
          Fees applied to you. You may elect to cancel your request for
          Services at any time prior to a Service Provider&apos;s arrival;
          provided, that if you cancel your request for Services at any
          time after it is accepted by a Service Provider through the
          Applications, or you fail to have personnel available for the
          Service Provider at their arrival to access portions of the Job
          site necessary for them to perform the Services, you shall pay
          to Lula a cancellation fee (at Lula’s then-applicable
          cancellation fee rate, not to be less than $50).
        </p>
        <h5>Payment Information; Third Parties</h5>
        <p>
          The Applications may be made available or accessed in connection
          with third party services and content (including payment
          processing) provided by third parties (including Stripe) that
          Lula does not control. You acknowledge that different terms of
          use and privacy policies may apply to your use of such third
          party services and that Lula does not endorse such third party
          services and content and in no event shall Lula be responsible
          or liable for any products or services of such third parties.
          Additionally, these third parties are not parties to this
          contract and are not responsible for the provision or support of
          the Applications or Services in any manner. Your access to the
          Applications and payment of the Fees and Transaction Fees using
          these third party’s devices and payment solutions is subject to
          the terms set forth in their terms of service and privacy
          policies. By using the Applications, you are agreeing to Lula’s
          use of these third parties (including Stripe) and their
          solutions (including payment solutions) and hereby release Lula,
          to the fullest extent permitted by law, from any and all
          liabilities, costs, expenses, claims, and obligations of any
          kind whatsoever with respect to these third party solutions.
        </p>
        <h5>Location Services; Personal Information</h5>
        <p>
          You hereby expressly authorize Lula to use, and consent to our
          use of, location-based services and expressly waive and release
          Lula, to the fullest extent permitted by law, from any and all
          liability, claims, causes of action, or damages arising from
          your use of the Applications, or in any way relating to the
          precise location and other location-based services. You consent
          to, recognize, and agree that your location services will be
          provided to us and our Service Providers. You also expressly
          consent to Lula collecting personal information from or about
          you (including financial data), your organization, your tenants,
          and your properties when you create an account for and use the
          Applications or receive the Services, including such information
          pertaining to the Applications performance, statistical
          information about residents, geolocation data, property type and
          maintenance schedules, service providers schedules, and other
          information about the delivery of the Services to you and your
          tenants and your use of the Applications (collectively, “Job
          Data”). By submitting information to Lula during the account
          creation process and/or by using the Applications, you consent
          to Lula’s collection and use of all Job Data for any purpose
          permitted by law, including all uses set forth in our Privacy
          Policy. All Job Data shall remain your property; provided, that,
          you hereby grant to Lula a perpetual, exclusive, irrevocable,
          fully-paid, royalty free, sublicensable and distributable
          license to use, access, store, modify, create derivative works
          based on and otherwise commercialize and use all Job Data for
          any purpose permitted by law, including for improvement of
          Lula’s and its customers and service partners’ technology,
          algorithms, and inventions, and distribution to third parties on
          a commercial basis.
        </p>
        <h5>Intellectual Property</h5>
        <p>
          All intellectual property rights, including copyrights, patents,
          patent disclosures and inventions (whether patentable or not),
          trademarks service marks, trade secrets, know-how and other
          confidential information, trade dress, trade names, logos,
          corporate names and domain names, together with all of the
          goodwill associated therewith, derivative works, and all other
          rights (collectively, &quot;
          <strong>Intellectual Property Rights</strong>
          &quot;) in and to all documents, work product, and other
          materials that are delivered to, made available to, or created
          by you as part of your use of the Applications or the delivery
          of the Services, including the Applications themselves or
          materials delivered to you pursuant to the Applications and all
          developments and derivative works thereof (regardless of the
          source of development), shall be owned solely by and remain the
          sole property of Lula.
        </p>
        <h5>Confidentiality</h5>
        <p>
          Lula may disclose or make available to you non-public
          proprietary and confidential information of Lula, its other
          Service Providers, and their affiliates (including personal
          information of such individuals, Lula’s Intellectual Property
          Rights, the identities and lists of its Service Providers and
          other customers, its inventions, algorithms, schematics,
          workflows, diagrams, developments, unpatented inventions, trade
          secrets, and other information that is not publicly available)
          (&quot;Confidential Information&quot;). Lula retains all right,
          title, and interest in and to the Confidential Information. You
          shall: (a) protect and safeguard the confidentiality of the
          Confidential Information with at least the same degree of care
          as you would protect your own Confidential Information, but in
          no event with less than a commercially reasonable degree of
          care; (b) not use the Confidential Information, or permit it to
          be accessed or used, for any purpose other than to exercise your
          rights or perform your obligations under this Agreement for your
          receipt of the Services; and (c) not disclose any such
          Confidential Information to any person or entity, except to the
          extent necessary to the Service Providers who need to know the
          Confidential Information to perform the Services. If you are
          required by applicable law or legal process to disclose any
          Confidential Information, you shall, prior to making such
          disclosure, use commercially reasonable efforts to notify Lula
          of such requirements to afford Lula the opportunity to seek, at
          Lula&apos;s sole cost and expense, a protective order or other
          remedy.
        </p>
        <h5>Limited Warranty; Disclaimer </h5>
        <p>
          Lula warrants to you that, subject to limitations, restrictions,
          or interferences imposed by inclemental weather, epidemics
          (including COVID-19, and any iteration thereof), acts of God,
          floods, earthquakes, explosions, war, hostilities, terrorism,
          civil unrest or riots, governmental order or actions, embargoes,
          national or regional emergencies, strikes and labor shortages,
          industrial disturbances, telecommunication breakdowns, power
          outages or shortages, inadequate transportation services,
          inability or delay in obtaining supplies, or other similar
          events beyond the control of Lula, the Services shall be
          provided to you using persons of commercially reasonable skill
          in a timely manner in accordance with generally recognized
          industry standards for similar services. If the Services fail to
          meet the service warranty in the immediately preceding sentence
          and you give Lula prompt written notice describing such failure
          in reasonable detail (which such notice must be delivered, in
          any event, no later than thirty (30) days following the delivery
          of such Services), Lula’s sole and exclusive liability and your
          sole and exclusive remedy for such breach shall be as follows:
          Lula shall, at its cost, use reasonable commercial efforts
          secure the services of second Service Provider to provide the
          same type of Services to you as the Services that failed in a
          manner that meets the foregoing service warranty (the “
          <strong>Replacement Services</strong>
          ”) within a reasonable time (but no more than 15 days) after
          your notice of such breach;
          <strong>
            <em>provided, that,</em>
          </strong>
          such Replacement Services shall only be provided to you 1 time
          for any Job Request that you submit through the Applications.
        </p>
        <p>
          You acknowledge and agree that we do not guarantee or warrant
          that files available for downloading from the internet or the
          Applications will be free of viruses or other destructive code.
          You are responsible for implementing sufficient procedures and
          checkpoints to satisfy your particular requirements for
          anti-virus protection and accuracy of data input and output, and
          for maintaining a means external to our Applications for any
          reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
          INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, MOBILE
          DEVICE, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF
          THE APPLICATIONS OR ANY ITEMS OBTAINED THROUGH THE APPLICATIONS
          OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THEM, OR ON ANY
          WEBSITE LINKED TO THEM.
        </p>
        <p>
          YOUR USE OF THE APPLICATIONS, THEIR CONTENT, AND OTHER ITEMS
          OBTAINED THROUGH THE APPLICATIONS (INCLUDING YOUR USERNAME,
          PASSWORD, AND OTHER PERSONAL INFORMATION) IS AT YOUR OWN RISK.
          THE APPLICATIONS, THEIR CONTENT, AND ANY OTHER ITEMS OBTAINED
          THROUGH THE APPLICATIONS ARE PROVIDED ON AN &quot;AS IS&quot;
          AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF
          ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER LULA NOR ANY PERSON
          ASSOCIATED WITH LULA MAKES ANY WARRANTY OR REPRESENTATION WITH
          RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
          ACCURACY, OR AVAILABILITY OF THE APPLICATIONS. WITHOUT LIMITING
          THE FOREGOING, NEITHER LULA NOR ANYONE ASSOCIATED WITH LULA
          REPRESENTS OR WARRANTS THAT THE APPLICATIONS, THEIR CONTENT, OR
          ANY ITEMS OBTAINED THROUGH THE APPLICATIONS WILL BE ACCURATE,
          RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT THE APPLICATIONS, OR THE SERVER THAT MAKES THEM
          AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
          THAT THE APPLICATIONS OR ANY ITEMS OBTAINED THROUGH THE
          APPLICATIONS WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p>
          EXCEPT AS PROVIDED ABOVE IN THIS SECTION WITH RESPECT TO
          REPLACEMENT SERVICES, LULA MAKES NO OTHER WARRANTIES TO YOU AND
          HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY (a) WARRANTY OF
          MERCHANTABILITY; (b) WARRANTY OF FITNESS FOR A PARTICULAR
          PURPOSE; (c) WARRANTY OF TITLE; OR (d) WARRANTY AGAINST
          INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY;
          WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF
          PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. THE FOREGOING DOES
          NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED
          UNDER APPLICABLE LAW.
        </p>
        <h5>Limitation of Liability</h5>
        <p>
          IN NO EVENT SHALL LULA, ITS LICENSORS, SERVICE PROVIDERS,
          EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES
          OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APPLICATIONS,
          ANY WEBSITES LINKED TO THEM, ANY CONTENT ON THE APPLICATIONS OR
          SUCH OTHER WEBSITES OR ANY ITEMS OBTAINED THROUGH THE
          APPLICATIONS OR SUCH OTHER WEBSITES, OR FOR ANY LOSS,
          CORRUPTION, OR THEFT OF FINANCIAL DATA ENTERED INTO THE
          APPLICATIONS OR ANY THIRD PARTY PAYMENT SOLUTION (INCLUDING
          SOLUTIONS MAINTAINED BY STRIPE), INCLUDING ANY DIRECT, INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
          LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
          GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
          NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
          FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH
          CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL LULA OR
          ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR
          SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO
          YOUR USE OF OR INABILITY TO USE THE APPLICATIONS OR THEIR
          CONTENT AND YOUR RECEIPT OF THE SERVICES FOR (a) PERSONAL
          INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS
          OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS
          INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER
          CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR
          PUNITIVE DAMAGES; OR (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE
          AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU TO LULA FOR THE
          JOB TO WHICH YOUR CLAIM PERTAINS. THE FOREGOING LIMITATIONS WILL
          APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER
          SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW
          CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
          LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
        </p>
        <h5>Indemnification</h5>
        <p>
          To the full extent permitted by law, you agree to defend,
          indemnify, and hold harmless Lula, its affiliates, licensors,
          and other Service Providers, and its and their respective
          officers, directors, employees, contractors, agents, licensors,
          suppliers, successors, and assigns from and against any claims
          (including any claims for injury to person or property),
          liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys&apos; fees)
          arising out of or relating to (a) your violation of this CSA;
          (b) your use of the Applications; and (c) your use of
          information and data obtained through the Applications.
        </p>
        <h5>Term, Suspension, and Termination. </h5>
        <p>
          The term of this CSA commences when you download, install, or
          create a user account with the Applications and will continue in
          effect until terminated by you or Lula as set forth in this
          Section. You may terminate this CSA by deleting the Applications
          and all copies thereof from your mobile devices. Lula may
          temporarily suspend your access to the Applications and Job
          Requests at any time without notice in its sole discretion and
          for any reason. Lula may terminate this CSA at any time without
          notice for any reason in its discretion, including (a) if it
          ceases to support the Applications, which Lula may do in its
          sole discretion; (b) if you violate any of the terms and
          conditions of this CSA; or (c) if you commit a crime or
          intentional tort in connection with your use or acceptance of
          the Services.
        </p>
        <p>
          Upon termination, all rights granted to you under this CSA will
          also terminate; and (a) you must cease all use of the
          Application and receipt of Services and delete all copies of the
          Application from your mobile device and account. Lula reserves
          the right to immediately terminate this CSA without liability to
          you, in its sole discretion. Suspension or termination of this
          CSA will not limit any of Lula&apos;s rights or remedies at law
          or in equity. Any violation of Lula’s Terms and Conditions or
          Privacy Policy by you are deemed a violation of this CSA.
        </p>
        <p>
          The sections of this CSA entitled “Fees and Payment”, “Location
          Services;Personal Information”, “Intellectual Property”,
          “Confidentiality”, “Indemnification”, “Limited Warranty;
          Disclaimer” (but only to the extent of disclaiming warranties),
          “Limitation of Liability”, “Indemnification”, “Term, Suspension,
          and Termination”, “Governing Law and Jurisdiction”, “Waiver and
          Severability”, “Entire Agreement”, “Cumulative Remedies”,
          “General”, and “Amendment”, as well as any provision of this SLA
          that, in order to give proper effect to its intent, should
          survive expiration or termination, will survive the expiration
          or earlier termination of this Agreement.
        </p>
        <h5>Governing Law and Jurisdiction</h5>
        <p>
          All matters relating to the Services and this CSA and any
          dispute or claim arising therefrom or related thereto (in each
          case, including non-contractual disputes or claims), shall be
          governed by and construed in accordance with the internal laws
          of the State of Kansas without giving effect to any choice or
          conflict of law provision or rule (whether of the State of
          Kansas or any other jurisdiction). Any legal suit, action, or
          proceeding arising out of, or related to, this CSA or the
          Services shall be instituted exclusively in the federal courts
          of the United States or the courts of the State of Kansas, in
          each case located in Kansas City, Kansas, although we retain the
          right to bring any suit, action, or proceeding against you for
          breach of this CSA in your country of residence or any other
          relevant country and also retain the right to submit all claims
          to binding arbitration located in Kansas City, Kansas. You waive
          any and all objections to the exercise of jurisdiction or venue
          over you by such courts or arbitration panels.
        </p>
        <p>
          TO THE FULL EXTENT PERMITTED BY LAW, ANY CAUSE OF ACTION OR
          CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THE SERVICES OR
          THIS CSA MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE
          OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED.
        </p>
        <h5>Waiver and Severability</h5>
        <p>
          No waiver of by Lula of any term or condition set forth in this
          CSA shall be deemed a further or continuing waiver of such term
          or condition or a waiver of any other term or condition, and any
          failure of Lula to assert a right or provision under this CSA
          shall not constitute a waiver of such right or provision. If any
          provision of this CSA is held by a court or other tribunal of
          competent jurisdiction to be invalid, illegal or unenforceable
          for any reason, such provision shall be eliminated or limited to
          the minimum extent such that the remaining provisions of this
          CSA will continue in full force and effect.
        </p>
        <h5>Entire Agreement</h5>
        <p>
          This CSA, the Terms of Use, and our Privacy Policy, constitute
          the sole and entire agreement between you and Lula with respect
          to the Services and supersede all prior and contemporaneous
          understandings, agreements, representations, and warranties,
          both written and oral, with respect to the Services. In the
          event that there is any inconsistency between statements in this
          CSA, the Terms of Use, and the Privacy Policy, then the
          statements in this CSA will control.
        </p>
        <h5>Cumulative Remedies</h5>
        <p>
          All rights and remedies provided hereunder for Lula are
          cumulative and not exclusive, and the exercise by Lula of any
          right or remedy does not preclude the exercise of any other
          rights or remedies that may now or subsequently be available to
          Lula at law, in equity, by statute, in any other agreement
          between the parties or otherwise.
        </p>
        <h5>General</h5>
        <p>
          You may not assign this CSA without Lula’s prior written
          approval. Lula may assign this CSA without your consent to: (i)
          a subsidiary or affiliate; (ii) an acquirer of Lula’s equity,
          business or assets; or (iii) a successor by merger. Any
          purported assignment in violation of this section shall be void.
          No joint venture, partnership, employment, or agency
          relationship exists between you, Lula or any Service Provider,
          other Customer, or any other person as a result of this CSA or
          use of the Services. Lula&apos;s failure to enforce any right or
          provision in this CSA shall not constitute a waiver of such
          right or provision unless acknowledged and agreed to by Lula in
          writing. Boldface headings in this CSA are for convenience only
          and shall not be considered in construing or interpreting any of
          the terms or provisions hereof.
        </p>
        <h5>Amendment</h5>
        <p>
          Lula reserves the right to amend this CSA, the Terms of Use, and
          the Privacy Policy from time to time. Your continued use of the
          Applications or provision of Services after such amendment
          constitutes your acceptance of the CSA, the Terms of Use, and
          the Privacy Policy, as they may be amended.
        </p>
      </div>
      <div className="service-agreement-modal__button">
        <ButtonWithLoader isLoading={isAgreementButtonLoading} color="secondary" onClick={() => agreementHandler()}>I Agree</ButtonWithLoader>
      </div>

    </ModalBody>
  </Modal>
);

export default CustomerServiceAgreementModal;
