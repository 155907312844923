import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CustomInput,
  Button,
} from 'reactstrap';
import { AuthContext } from 'context/auth-context/auth-context';
import {
  PropertyManagersHeaderIcons,
  CloseIcon,
  EditIcon,
  PlusIcon,
} from 'assets/images/svg-icons';
import HeaderComponent from 'components/shared/header-component';
import {
  listPropertyManager, getPropertyManagerDetails, editPropertyManager, deletePropertyManager,
} from 'services/propertyManager';
import ListingComponent from 'components/shared/listing-component';
import AddEditPropertyManagerModal from 'components/property-managers/add-edit-property-manager-modal';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import catchHandler from 'helpers/catch-handler';

const PropertyManagers = () => {
  const [showAddPropertyManagerModal, setAddPropertyMangerModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userDetails] = useContext(AuthContext);
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [editInfo, setEditInfo] = useState();
  const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
  const [isToggleLoading, setToggleLoading] = useState();
  const [isDeleteButtonLoading, setDeleteButtonLoading] = useState(false);

  useEffect(() => {
    if (userDetails?.property_manager_type === 'employee') {
      history.push('/');
    }
  }, [userDetails, history]);

  const fetchPropertyManagerList = (params) => {
    setLoading(true);
    listPropertyManager(params).then((res) => {
      setDataSource(res?.data?.data);
      setLoading(false);
      setMeta(res?.data?.meta);
    }).catch(() => {});
  };

  useEffect(() => {
    fetchPropertyManagerList();
  }, []);

  const setEditModalhandler = (item) => {
    getPropertyManagerDetails(item?.property_manager_id).then((res) => {
      setEditInfo(res?.data);
      setEditModal(true);
    }).catch(() => {});
  };

  const editPropertyManagerHandler = (id, params, setLoader, setError) => {
    if (setLoader) {
      setLoader(true);
    }
    editPropertyManager(id, params).then((res) => {
      if (setLoader) {
        setLoader(false);
      }
      setToggleLoading((prev) => (
        {
          ...prev,
          status: false,
        }
      ));
      setDataSource((prev) => (
        prev.map((item) => {
          if (item.property_manager_id === res?.data?.property_manager_id) {
            return res?.data;
          }
          return item;
        })
      ));
      setEditModal(false);
    }).catch((error) => {
      if (setLoader) {
        setLoader(false);
      }
      setToggleLoading((prev) => (
        {
          ...prev,
          status: false,
        }
      ));
      if (setError) {
        catchHandler(error, setError, true);
      }
    });
  };

  const activeStatushandler = (item, event) => {
    if (event.target.checked) {
      editPropertyManagerHandler(item.property_manager_id, { is_active: 1 });
    } else {
      editPropertyManagerHandler(item.property_manager_id, { is_active: 0 });
    }
    setToggleLoading({ id: item.property_manager_id, status: true });
  };

  const onDeleteHandler = (item) => {
    setDeleteModal({ status: true, id: item.property_manager_id });
  };

  const deleteModalHandler = () => {
    setDeleteButtonLoading(true);
    deletePropertyManager(deleteModal?.id).then(() => {
      setDeleteButtonLoading(false);
      setDeleteModal((prev) => (
        {
          ...prev,
          status: false,
        }
      ));
      fetchPropertyManagerList({ page: meta?.current_page });
    }).catch(() => {
      setDeleteButtonLoading(false);
    });
  };

  return (
    <React.Fragment>
      <HeaderComponent>
        <Button className="add-new-btn" onClick={() => setAddPropertyMangerModal(true)}>
          <PlusIcon />
          Add New
        </Button>
      </HeaderComponent>
      <div className="page-header page-header-with-filter">
        <div className="page-header-inner">
          <span>
            <PropertyManagersHeaderIcons />
          </span>
          <h3>Our Property Managers</h3>
        </div>
      </div>
      <div className="data-card p-0 custom-data-card">
        <div className="data-wrap">
          <ListingComponent
            isLoading={loading}
            mappings={[
              {
                key: 'profile_pic_url',
                label: '',
                render: (item) => (
                  <div className="profile-icon">
                    {
                      item?.profile_pic_url ? (
                        <img src={item.profile_pic_url} alt="" />

                      ) : (
                        <React.Fragment>
                          {item?.first_name?.charAt(0).toUpperCase()}
                          {' '}
                          {item?.last_name?.charAt(0).toUpperCase()}
                        </React.Fragment>
                      )
                    }
                  </div>
                ),
              },
              {
                key: 'name',
                label: 'NAME',
                render: (item) => (
                  <strong>
                    {item?.first_name}
                    {' '}
                    {item?.last_name}
                  </strong>
                ),
              },
              {
                key: 'email',
                label: 'EMAIL ADDRESS',
                className: 'd-none d-md-table-cell',
              },
              {
                key: 'phone_no',
                label: 'PHONE NUMBER',
                className: 'd-none d-xl-table-cell',
              },
              {
                key: 'is_active',
                label: 'ACTIVE',
                render: (item) => (
                  <div className="d-flex justify-content-center">
                    {
                      (isToggleLoading?.status && item?.property_manager_id === isToggleLoading?.id) ? <span className="spinner-border spinner-border-sm mr-2 d-block" /> : (
                        <CustomInput
                          type="switch"
                          id={item.property_manager_id}
                          name="customSwitch"
                          label=""
                          checked={item.is_active}
                          className="lula-switch"
                          onChange={(event) => activeStatushandler(item, event)}
                        />
                      )
                    }
                  </div>
                ),
              },
              {
                key: 'actions',
                label: 'Actions',
                className: 'text-center',
                render: (item) => (
                  <div className="global-btn-outer">
                    <Button className="delete-btn" onClick={() => onDeleteHandler(item)}>
                      <i>
                        <CloseIcon />
                      </i>
                      Delete
                    </Button>
                    <Button className="edit-btn" onClick={() => setEditModalhandler(item)}>
                      <i>
                        <EditIcon />
                      </i>
                      Edit
                    </Button>
                  </div>
                ),
              },
            ]}
            dataSource={dataSource}
            meta={meta}
            shouldPaginate
            className="global-table"
            onPageChange={(page, perPage) => {
              fetchPropertyManagerList({ page, per_page: perPage });
            }}
          />

        </div>
      </div>
      {
        showAddPropertyManagerModal && (
          <AddEditPropertyManagerModal
            isOpen={showAddPropertyManagerModal}
            toggle={() => setAddPropertyMangerModal(false)}
            fetchPropertyManagerList={fetchPropertyManagerList}
          />
        )
      }
      {
        editModal && (
          <AddEditPropertyManagerModal
            isOpen={editModal}
            toggle={() => setEditModal(false)}
            editInfo={editInfo}
            editPropertyManagerHandler={editPropertyManagerHandler}
          />
        )
      }
      {
        deleteModal?.status && (
          <ConfirmationModal
            modal={deleteModal?.status}
            toggle={() => setDeleteModal((prev) => (
              {
                ...prev,
                status: false,
              }
            ))}
            title="Delete Account"
            confirmMessage="Are you sure that you want to permenantly delete account ? "
            handler={deleteModalHandler}
            isLoading={isDeleteButtonLoading}
          />
        )
      }

    </React.Fragment>
  );
};
export default PropertyManagers;
