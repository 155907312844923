import React from 'react';

const PastDueIcon = (props) => (
  <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.303467" y="0.25" width="29.2882" height="29.2882" rx="10" fill="#FF5D5D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.611 6.59961V17.4688H13.2842V6.59961H16.611ZM16.611 20.2715V23.1882H13.2842V20.2715H16.611Z" fill="white" />
  </svg>

);
export default PastDueIcon;
