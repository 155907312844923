import {
} from 'assets/images/svg-icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap';
import JobRating from 'components/shared/job-rating';
import { getReview } from 'services/jobs';
import { generateRandomString } from 'helpers/utils';

const ReviewModal = ({
  showReview, toggle, jobId,
}) => {
  const [rating, setRating] = useState();
  useEffect(() => {
    getReview(jobId).then((res) => {
      setRating(res?.data);
    }).catch(() => {});
  }, [jobId]);

  return (
    <Modal
      isOpen={showReview}
      toggle={toggle}
      centered
      className="review-modal"
    >
      <ModalHeader toggle={toggle}>
        Review on Job&nbsp;
        <Link to="#">
          #
          {rating?.job_no}
        </Link>
      </ModalHeader>
      <ModalBody>
        <div className="review-modal-data">
          <div className="review-modal-data--top review-modal-data--top_user_data">
            <Row className="w-100 ">
              <Col xs="12" md="6">
                <h5>Tenant:</h5>
                <h4>{rating?.tenant_name}</h4>
              </Col>
              <Col xs="12" md="6">
                <p>
                  {(rating?.address?.split('\n') || []).map((addressInfo) => (
                    <React.Fragment key={generateRandomString()}>
                      {addressInfo}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </Col>
            </Row>
          </div>
          <div className="review-modal-data--bottom">
            <div className="review-content">
              <div className="review-content--name">Lula Pro:</div>
              <div className="review-content--data">{rating?.rating_text}</div>
              <div className="review-content--rating">
                <div className="star-rating">
                  <div className="stars mr-1">
                    <JobRating rating={rating?.rating} width="19" height="18" />
                  </div>
                  <div className="rating">
                    {rating?.rating}
                    /5
                  </div>
                </div>
                <div className="time-stamp">{rating?.date}</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ReviewModal;
