import React, { useState, useRef, useEffect } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { Controller, useFormContext } from 'react-hook-form';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import Input from 'reactstrap/lib/Input';
import Moment from 'moment';
import {
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
} from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import useOutsideClick from 'hooks/use-outside-click';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLast30Days: startOfDay(addDays(new Date(), -29)),
  endOfLast30Days: endOfDay(new Date()),

};

const sideBarOptions = () => {
  const customDateObjects = [
    {
      label: 'Last 30 Days',
      range: () => ({
        startDate: defineds.startOfLast30Days,
        endDate: defineds.endOfLast30Days,
        staticRange: true,
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        staticRange: true,
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
        staticRange: true,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        staticRange: true,
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
        staticRange: true,
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
        staticRange: true,
      }),
    },
  ];

  return customDateObjects;
};

const staticRanges = [
  ...createStaticRanges(sideBarOptions()),
];
const DateRangeDisplay = ({
  onChange,
  value,
  name,
  theme, // Pass the prop theme='light' for calendar in light theme
  // The default theme will be 'dark' if 'theme' prop is not passed into the component
}) => {
  const { errors } = useFormContext();
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [dateDisplay, setDateDisplay] = useState('');
  const [stepCount, setStepCount] = useState(0);
  const [selectedRange, setSelectedRange] = useState();
  const [focusedRange, setFocusedRange] = useState([0, 0]);

  const selectionRange = value?.selectedRange || {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (showRangePicker) {
      setShowRangePicker(false);
    }
  });

  const onFocusChange = (range) => {
    const [, rangeStep] = range;
    setFocusedRange(range);
    setStepCount(rangeStep);
    if (rangeStep === 0) {
      setShowRangePicker(false);
    }
  };

  useEffect(() => {
    if (Moment(value?.selectedRange?.endDate).format('YYYY-MM-DD') === Moment().format('YYYY-MM-DD')) {
      setDateDisplay(`Last ${Moment(value?.selectedRange?.endDate).diff(Moment(value?.selectedRange?.startDate), 'days') + 1} days`);
    } else {
      setDateDisplay('Custom');
    }
  }, [value?.selectedRange]);

  useEffect(() => {
    if (selectedRange) {
      onChange({ selectedRange, stepCount });
    }
    if (selectedRange?.staticRange && stepCount === 0) {
      setShowRangePicker(false);
    }
  }, [selectedRange, stepCount, onChange]);

  return (
    <div ref={ref} className={theme ? [theme] : 'dark'}>
      <Input
        name={`${name}DateDisplay`}
        placeholder=""
        onClick={() => setShowRangePicker(true)}
        defaultValue={dateDisplay}
        autoComplete="off"
        readOnly
        style={{ background: '#f4f6fa' }}
      />
      {showRangePicker && (
        <div className="date-range-picker tl-date-range">
          <DateRangePicker
            color="#ff0000"
            className={!!errors && !!errors[name] ? 'is-invalid' : ''}
            name={name}
            ranges={[selectionRange]}
            onChange={(range) => {
              setSelectedRange(range && range.selection);
            }}
            onRangeFocusChange={onFocusChange}
            focusedRange={focusedRange}
            months={2}
            direction="horizontal"
            showDateDisplay={false}
            moveRangeOnFirstSelection={false}
            showMonthAndYearPickers={false}
            monthDisplayFormat="MMMM yyyy"
            // weekdayDisplayFormat="EEEEE"
            staticRanges={staticRanges}
            inputRanges={[]}
          />
        </div>
      )}
    </div>
  );
};

const DateRangePickerComponent = ({ name: propName, theme }) => {
  const { control, errors } = useFormContext();
  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propName}
        render={(props) => (
          <DateRangeDisplay
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            theme={theme}
          />
        )}
      />
      {errors && errors[propName] && errors[propName].message && (
        <FormFeedback>{errors && errors[propName].message}</FormFeedback>
      )}
    </React.Fragment>
  );
};

export default DateRangePickerComponent;
