import HeaderComponent from 'components/shared/header-component';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomInput } from 'reactstrap';

import OpenJobsContainer from './components/open-jobs-container';
import OpenJobsFilter from './components/open-jobs-filter';

const OpenJobs = () => {
  const [showMyJobsStatus, setShowMyJobsStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [serviceFilters, setServiceFilters] = useState([]);
  const [pmFilter, setPMFilters] = useState([]);

  const startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.job_no) {
      setFilterParams({
        search_keyword: location?.state?.job_no,
      });
      window.history.replaceState({}, document.title);
    }
  }, [location?.state]);

  return (
    <React.Fragment>
      <HeaderComponent>
        <div className={`archived-btn ${showMyJobsStatus ? 'active' : ''}`}>
          My Jobs Only
          <CustomInput
            className="job_only_btn"
            type="switch"
            id="my_jobs_switch"
            name="my_jobs_switch"
            label=""
            checked={showMyJobsStatus}
            onChange={() => {
              setLoading(true);
              setShowMyJobsStatus(!showMyJobsStatus);
            }}
          />
        </div>
      </HeaderComponent>
      <OpenJobsFilter
        serviceFilters={serviceFilters}
        pmFilter={pmFilter}
        setFilterParams={setFilterParams}
        startDate={startDate}
        endDate={endDate}
        setLoading={setLoading}
      />
      <OpenJobsContainer
        showMyJobsStatus={showMyJobsStatus}
        loading={loading}
        setLoading={setLoading}
        setServiceFilters={setServiceFilters}
        setPMFilters={setPMFilters}
        startDate={startDate}
        endDate={endDate}
        filterParams={filterParams}
      />
    </React.Fragment>
  );
};
export default OpenJobs;
