import { request } from 'helpers/api';

export const getDashboardDetails = (params) => (
  request({
    method: 'GET',
    url: '/api/dashboard',
    params,
  })
);
export const deEscalationServiceInterest = () => (
  request({
    method: 'POST',
    url: '/api/notify/de-escalation-service-interest',
  })
);

export const answeringServiceInterest = () => (
  request({
    method: 'POST',
    url: '/api/notify/after-hours-maintenance-answering-service-interest',
  })
);

export const globalSearch = (params = {}) => (
  request({
    method: 'GET',
    url: '/api/search',
    params,
  })
);
