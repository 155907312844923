import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'reactstrap/lib/Table';
import PaginationComponent from 'components/shared/pagination';

import Loader from 'components/shared/loader';
import NoDataLoader from 'components/shared/no-data-loader';

/* Sample Structure
 <ListingComponent
    mappings={[
      {
        key: 'id',
        label: 'User Id',
        sortable: true,
      },
      {
        key: 'name',
        label: 'User Name',
      },
      {
        key: 'actions',
        label: 'Actions',
        render: (data) => (
          <React.Fragment>
            <button onClick={() => alert(data)} type="button">Click Me</button>
            <button onClick={() => alert(data)} type="button">Click Me</button>
          </React.Fragment>
        ),
      },
    ]}
    className="tableClassName"
    dataSource={dataSource}
    onSortChange={alert}
  /> */

const ListingComponent = (props) => {
  const {
    mappings,
    dataSource,
    isLoading,
    meta,
    shouldPaginate,
    className,
    defaultSort,
    onPageChange,
    onSortChange,
    primaryKey,
    renderExpanded,
    reset,
    getTableRowClassNames = null,
  } = props;

  const [sort, setSort] = useState(defaultSort);
  const [expanded, setExpanded] = useState({});

  const setExpand = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    if (reset) {
      setSort('');
    }
  }, [reset]);

  return (
    <React.Fragment>
      { isLoading && <Loader /> }
      {/* TODO */}
      { !isLoading && dataSource && dataSource.length === 0
      && (
        <NoDataLoader />
      )}
      { !isLoading && dataSource && dataSource.length > 0
        && (
        <div>
          <Table
            responsive
            className={`global-table ${className || ''}`}
          >
            <thead>
              <tr>
                { mappings && mappings.map((cell) => (
                  <React.Fragment key={cell.id ? cell.id : cell.key}>
                    {cell.sortable
                      ? (
                        <th
                          onClick={() => {
                            const newKey = () => {
                              if (sort === cell.sortKey) {
                                return `-${cell.sortKey}`;
                              }
                              if (sort === `-${cell.sortKey}`) {
                                return '';
                              }
                              return cell.sortKey;
                            };
                            setSort(newKey());

                            if (onSortChange) {
                              onSortChange(newKey());
                            }
                          }}
                          className={`${cell.className ?? ''} sortable ${sort === cell.sortKey ? 'active' : ''} ${sort === `-${cell.sortKey}` ? 'active reverse' : ''}`}
                        >
                          {cell.label}
                        </th>
                      )
                      : (
                        <th className={cell.className}>
                          {cell.label}
                        </th>
                      )}
                  </React.Fragment>

                ))}
              </tr>
            </thead>
            <tbody>

              { dataSource.length > 0 && dataSource.map((data) => (
                <React.Fragment key={data[primaryKey]}>
                  <tr className={`${expanded[data[primaryKey]] ? 'expanded' : ''} ${getTableRowClassNames ? getTableRowClassNames(data) : ''}`}>
                    { mappings && mappings.map((cell) => (
                      <td key={cell.id ? cell.id : cell.key} className={cell.className}>
                        {cell.type === 'expand'
                          ? (
                            <React.Fragment>
                              { primaryKey && data[primaryKey] && renderExpanded
                                && (
                                <div
                                  className={`row-detail ${expanded[data[primaryKey]] ? 'expanded' : ''}`}
                                  onClick={() => setExpand(data[primaryKey])}
                                />
                                )}
                            </React.Fragment>
                          )
                          : (
                            <React.Fragment>
                              { cell.render ? cell.render(data) : data[cell.key] }
                            </React.Fragment>
                          )}
                      </td>
                    ))}
                  </tr>
                  { data[primaryKey] && expanded[data[primaryKey]] && renderExpanded
                    && (
                    <tr className="expanded-row">
                      <td colSpan={mappings.length}>
                        {renderExpanded(data)}
                      </td>
                    </tr>
                    )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          {shouldPaginate && meta && meta.total > 0 ? (
            <PaginationComponent
              onPageChange={onPageChange}
              meta={meta}
            />
          ) : '' }
        </div>
        )}
    </React.Fragment>
  );
};

ListingComponent.defaultProps = {
  mappings: [],
  dataSource: [],
  isLoading: false,
  onPageChange: PropTypes.func,
  shouldPaginate: false,
  className: '',
  defaultSort: '',
  meta: null,
  primaryKey: null,
  getTableRowClassNames: null,
};

ListingComponent.propTypes = {
  mappings: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    render: PropTypes.func,
    sortable: PropTypes.bool,
  })),
  dataSource: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  meta: PropTypes.shape({
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    total_pages: PropTypes.number,
    total: PropTypes.number,
  }),
  shouldPaginate: PropTypes.bool,
  className: PropTypes.string,
  defaultSort: PropTypes.string,
  onPageChange: PropTypes.func,
  primaryKey: PropTypes.string,
  getTableRowClassNames: PropTypes.func,
};

export default ListingComponent;
