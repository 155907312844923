import { request } from 'helpers/api';

export const login = (params) => (
  request({
    method: 'POST',
    url: '/api/login',
    params,
  })
);

export const forgotPassword = (params) => (
  request({
    method: 'POST',
    url: '/api/password/forgot',
    params,
  })
);

export const logout = () => (
  request({
    method: 'DELETE',
    url: '/api/logout',
  })
);

export const resetPassword = (params) => (
  request({
    method: 'POST',
    url: '/api/password/reset',
    params,
  })
);

export const defaultCustomerResetPassword = (params) => (
  request({
    method: 'POST',
    url: '/api/password/force-reset',
    params,
  })
);

export const fetchLoggedInUserDetails = () => (
  request({
    method: 'GET',
    url: '/api/profile',
  })
);
